import {getBeUrl} from "../../../helpers/utilHelper";
import React from "react";
import downloadDocumentIcon from 'assets/images/download-doc-icon.svg';
import PropTypes from 'prop-types';

const DocumentCard = ({title, description, docUrl}) => {
	return (
		<div className='document-card'>
					<div className='document-subtitle'>Access Your Files</div>
					<div className='document-title'>{title}</div>

				<div className='button-wrapper'>
					<a href={getBeUrl(`${docUrl}`)} target="_blank" rel="noreferrer" className="btn download-link">
						<span>Download</span>
						<img className='ps-2' src={downloadDocumentIcon} />
					</a>
					<div className='divider'></div>
				</div>

	{/*<div className='document-description'>{description}</div>*/}
		</div>
	)
}

DocumentCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	docUrl: PropTypes.string,
};

export default DocumentCard;