/********** GET PROFILE **********/

export const GET_PROFILE = "GET_PROFILE"
export const GET_PROFILE_OK = "GET_PROFILE_OK"
export const GET_PROFILE_ERR = "GET_PROFILE_ERR"

/********** SAVE PROFILE **********/

export const AGREE_TO_PROFILE_TERMS = "AGREE_TO_PROFILE_TERMS"
export const SAVE_PROFILE_BILLING = "SAVE_PROFILE_BILLING"
export const SAVE_PROFILE_SHIPPING = "SAVE_PROFILE_SHIPPING"
export const SAVE_PROFILE_CONTACT = "SAVE_PROFILE_CONTACT"
export const SAVE_PROFILE_CREDENTIALS = "SAVE_PROFILE_CREDENTIALS"
export const SAVE_PROFILE_EXPERIENCE = "SAVE_PROFILE_EXPERIENCE"
export const SAVE_PROFILE_AREA = "SAVE_PROFILE_AREA"
export const SAVE_PROFILE_OK = "SAVE_PROFILE_OK"
export const SAVE_PROFILE_ERR = "SAVE_PROFILE_ERR"

export const DO_SAVE_PROFILE_CLEANUP = "DO_SAVE_PROFILE_CLEANUP"

export const RESET_SAVE_PROFILE_ACTION_FLAG = 'RESET_SAVE_PROFILE_ACTION_FLAG'