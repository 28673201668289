import React from "react";
import PropTypes from "prop-types";
import { useAuth } from "context/auth";
import downloadDoc from "assets/images/download-doc.png";
import notarizedIcon from "assets/images/notarized-badge.svg";
import signatureIcon from "assets/images/signature-icon.svg";
import documentIcon from "assets/images/blue-document-icon.svg";

const DealerChecklistMobile = ({ inkSignDocs }) => {
 const { user } = useAuth();

  return(<React.Fragment>
    <div className="divider-faded divider-sm mt-3" />
    <div className="d-flex align-items-center ms-3 my-2">
      <img src={documentIcon} />
      <h6 className="mb-0 ms-2">Dealer checklist:</h6>
    </div>
    {inkSignDocs?.filter(entry => entry.creatorId !== user.id).map((entry, index) => <div key={index} className='mobile-row-doc-wrapper'>
      <div className="mobile-row-doc-header">
        <img src={downloadDoc} alt="download-document" />
        <span className="mobile-row-doc-title">{entry.name}</span>
        {(entry.name === 'AOI' || entry.name === 'Affidavit of Identity' || !!entry.isNotarizationRequired) && <span className="notarized-badge
          d-flex align-items-center justify-content-center ms-3">
          to be notarized <img className="ms-2" src={notarizedIcon} alt='notarized-icon' />
        </span>}
      </div>
      <div className="mobile-row-doc-body">
        {!!entry.signingInstructions && <>
          <h6 className='instructions-title'>Signing instructions</h6>
          <div className='instructions-description'>{entry.signingInstructions}</div>
        </>}
      </div>
      <div className="mobile-row-doc-footer">
        <div className='doc-item-badge-wrapper'>
          {!!entry.numOfInitials && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
            {`${entry.numOfInitials} Initial${entry.numOfInitials > 1 ? "s" : ""}`} <i className="mdi mdi-caps-lock ms-1" />
          </div>}
          {!!entry.numOfSignatures && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
            {`${entry.numOfSignatures} Signature${entry.numOfSignatures > 1 ? "s" : ""}`} <img alt="signatures" src={signatureIcon} className="ms-1" />
          </div>}
          {!!entry.numOfPages && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
            {`${entry.numOfPages}`} <i className="mdi mdi-file-multiple-outline ms-1" />
          </div>}
        </div>
      </div>
    </div>)}
  </React.Fragment>)
}

DealerChecklistMobile.propTypes = {
  inkSignDocs: PropTypes.array,
};

export default DealerChecklistMobile;
