import React, { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";
import ContactDesktop from "./Desktop";
import ContactMobile from "./Mobile";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "store/actions";
import { showError } from "helpers/utilHelper";

const Contact = () => {
  // get order id from url
  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const { order, orderError } = useSelector(state => state.Order.Single);

  useEffect(() => {
    dispatch(getOrder(id));
  }, [id]);

  useEffect(() => {
    if (orderError) {
      showError("Unable to contact");
    }
  }, [orderError]);

  return (
    <React.Fragment>
      {order && (
        <>
          <BrowserView>
            <ContactDesktop />
          </BrowserView>
          <MobileView>
            <ContactMobile />
          </MobileView>
        </>
      )}
    </React.Fragment>
  );
};

export default Contact;