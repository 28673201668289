export default class Message {

  static CHANNEL_DEALER_CUSTOMER = 1;
  static CHANNEL_SUPPORT_DEALER = 2;
  static CHANNEL_SUPPORT_DEALER_CUSTOMER = 3;
  static CHANNEL_SCHEDULER_CUSTOMER = 4;
  static CHANNEL_SCHEDULER_NOTARY = 5;
  static CHANNEL_NOTARY_CUSTOMER = 6;

  static CHANNEL_MEMBER_TYPE_USER = 1;
  static CHANNEL_MEMBER_TYPE_SIGNER = 2;

  static CONTENT_TYPE_TEXT = 1
  static CONTENT_TYPE_IMAGE = 2

  static getChannelMap() {
    return {
      [this.CHANNEL_DEALER_CUSTOMER]: 'Dealer - Customer',
      [this.CHANNEL_SUPPORT_DEALER]: 'Support - Dealer',
      [this.CHANNEL_SUPPORT_DEALER_CUSTOMER]: 'Support - Dealer - Customer',
      [this.CHANNEL_SCHEDULER_CUSTOMER]: 'Scheduler - Customer',
      [this.CHANNEL_SCHEDULER_NOTARY]: 'Notary - Scheduler',
      [this.CHANNEL_NOTARY_CUSTOMER]: 'Notary - Customer',
    };
  }

  static getChannelName(id) {
    return this.getChannelMap()[id];
  }
}