/********** LIST **********/

export const GET_CREDENTIAL_LIST = "GET_CREDENTIAL_LIST"
export const GET_CREDENTIAL_LIST_OK = "GET_CREDENTIAL_LIST_OK"
export const GET_CREDENTIAL_LIST_ERR = "GET_CREDENTIAL_LIST_ERR"

export const GET_CREDENTIAL_LIST_ITEM = "GET_CREDENTIAL_LIST_ITEM"
export const GET_CREDENTIAL_LIST_ITEM_OK = "GET_CREDENTIAL_LIST_ITEM_OK"
export const GET_CREDENTIAL_LIST_ITEM_ERR = "GET_CREDENTIAL_LIST_ITEM_ERR"

export const DELETE_CREDENTIAL_LIST_ITEM = "DELETE_CREDENTIAL_LIST_ITEM"
export const DELETE_CREDENTIAL_LIST_ITEM_OK = "DELETE_CREDENTIAL_LIST_ITEM_OK"
export const DELETE_CREDENTIAL_LIST_ITEM_ERR = "DELETE_CREDENTIAL_LIST_ITEM_ERR"

export const DO_CREDENTIAL_LIST_CLEANUP = "DO_CREDENTIAL_LIST_CLEANUP"

/********** FORM **********/

export const UPLOAD_CREDENTIAL = "UPLOAD_CREDENTIAL"
export const UPLOAD_CREDENTIAL_OK = "UPLOAD_CREDENTIAL_OK"
export const UPLOAD_CREDENTIAL_ERR = "UPLOAD_CREDENTIAL_ERR"
export const DO_CREDENTIAL_FORM_CLEANUP = "DO_CREDENTIAL_FORM_CLEANUP"