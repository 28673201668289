import React from "react";
import PropTypes from "prop-types";
import { getBeUrl, getAoiUrl } from "helpers/utilHelper";
import downloadDoc from "assets/images/download-doc.png";
import notarizedIcon from "assets/images/notarized-badge.svg";

const TemplateSection = ({ order,
  downloadBtnClassName,
  notaryChecklistDoc,
  aoiDoc,
  isDeliveryOptionUploaded,
  isDeliveryOptionShipping }) => {

  return <><h6 className="mt-3 mb-3">Templates</h6>
    <div className='mobile-row-doc-wrapper'>
      <div className="mobile-row-doc-header">
        <img src={downloadDoc} alt="download-document" />
        <span className="mobile-row-doc-title">AOI</span>
        {aoiDoc && <span className="notarized-badge ms-3">
          to be notarized <img src={notarizedIcon} className='ms-2' alt='notarized-icon' />
        </span>}
      </div>
      <div className="mobile-row-doc-body">
        {aoiDoc && !!aoiDoc[0]?.signingInstructions && <><h6 className='instructions-title'>Signing instructions</h6>
          <div className='instructions-description'>{aoiDoc && aoiDoc[0]?.signingInstructions}</div>
        </>}
      </div>
      <div className="mobile-row-doc-footer">
        {(isDeliveryOptionUploaded || isDeliveryOptionShipping) && <a href={getAoiUrl(order.isAoiThumbprintRequired)} rel="noreferrer" className={downloadBtnClassName}>
          <i className="bx bx-download font-size-20" /> Download document
        </a>}
      </div>
    </div>
    <div className='mobile-row-doc-wrapper'>
      <div className="mobile-row-doc-header">
        <img src={downloadDoc} alt="download-document" />
        <span className="mobile-row-doc-title">Notary Checklist</span>
        {notaryChecklistDoc && !!notaryChecklistDoc[0]?.isNotarizationRequired && <span className="notarized-badge ms-3">
          to be notarized <img src={notarizedIcon} className='ms-2' alt='notarized-icon' />
        </span>}
      </div>
      <div className="mobile-row-doc-body">
        {notaryChecklistDoc && !!notaryChecklistDoc[0]?.signingInstructions && <><h6 className='instructions-title'>Signing instructions</h6>
          <div className='instructions-description'>{notaryChecklistDoc && notaryChecklistDoc[0]?.signingInstructions}</div>
        </>}
      </div>
      <div className="mobile-row-doc-footer">
        {(isDeliveryOptionUploaded || isDeliveryOptionShipping) && <a href={getBeUrl(`notary/std-doc/notary-checklist.pdf`)} rel="noreferrer" className={downloadBtnClassName}>
          <i className="bx bx-download font-size-20" /> Download document
        </a>}
      </div>
    </div>
    <div className="divider-faded divider-sm" />
  </>
}

TemplateSection.propTypes = {
  order: PropTypes.object,
  downloadBtnClassName: PropTypes.string,
  notaryChecklistDoc: PropTypes.array,
  aoiDoc: PropTypes.array,
  isDeliveryOptionUploaded: PropTypes.bool,
  isDeliveryOptionShipping: PropTypes.bool,
};

export default TemplateSection;