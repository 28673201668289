export default class DealerStore {

  static PAYMENT_METHOD_TYPE_CARD = 1;
  static PAYMENT_METHOD_TYPE_BANK_ACCOUNT = 2;
  static PAYMENT_METHOD_TYPE_CHECK = 3;

  static getPaymentMethodMap() {
    return {
      [this.PAYMENT_METHOD_TYPE_CARD]: 'Card',
      [this.PAYMENT_METHOD_TYPE_BANK_ACCOUNT]: 'Bank Account',
      [this.PAYMENT_METHOD_TYPE_CHECK]: 'Check',
    };
  }

  static getPaymentMethodName(id) {
    return this.getPaymentMethodMap()[id];
  }
}