import React, { useEffect } from "react"
import { showError } from "helpers/utilHelper";
import { useParams } from "react-router-dom";
import { createOrderMeeting } from "helpers/backendHelper";
import Preloader from "components/Shared/Preloader";

const Scheduler = () => {

  // get order id from url
  let { id } = useParams();
  id = parseInt(id);

  useEffect(() => {
    async function createMeeting() {
      try {
        const { link } = await createOrderMeeting(id, 'scheduler');
        if (!link) {
          throw new Error();
        } else {
          window.location.replace(link);
        }
      } catch (err) {
        showError("Unable to start meeting");
      }
    }
    createMeeting();
  }, [id]);

  return <React.Fragment>
    <Preloader />
  </React.Fragment>
}

export default Scheduler;
