import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, UncontrolledTooltip } from "reactstrap";
import NoImg from "components/Shared/NoImg";
import { Item } from "react-photoswipe-gallery";
import DocPreviewModal from "./PreviewModal";
import { uploadOrderDocPage, clearOrderDocPage } from "helpers/backendHelper";
import { getBeUrl, showError } from "helpers/utilHelper";
import Capture from "./Capture";
import AddImg from "components/Shared/AddImg";
import classnames from "classnames";
import OrderDoc from "model/orderDoc";
import { UPLOADED_FILE_TYPE_NOT_ALLOWED, ValidationException } from "helpers/errorHelper";
import { timestamp } from "helpers/dateHelper";

const DocPageMobile = ({ docId, docName, pageNum, isPageUploaded, isNewPage, finishedHandler, disabled, pages, setHasInProgressUploads }) => {

  const cameraFileInput = useRef(null);
  const uploadFileInput = useRef(null);

  const [takeCapture, setTakeCapture] = useState(false);

  const imageUrl = getBeUrl(`order-doc/${docId}/ink-sign/page/${pageNum}.png?${timestamp()}`);

  const pageData = !!pages && pages[pageNum] ? pages[pageNum] : {};

  const canRemovePage = pageData.status != OrderDoc.PAGE_STATUS_ACCEPTED;

  const [isUploading, setIsUploading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  const saveCapture = async image => {
    const formData = new FormData();
    formData.append("pageImg", image);
    setIsUploading(true);
    setHasInProgressUploads(true);
    try {
      await uploadOrderDocPage(formData, docId, pageNum);
      finishedHandler();
    } catch (err) {
      let errMessage = 'Unable to upload page';
      if (err instanceof ValidationException && err.fields?.pageImg == UPLOADED_FILE_TYPE_NOT_ALLOWED) {
        errMessage = 'Unknown format, please upload jpeg or png';
      }
      showError(errMessage);
    } finally {
      setIsUploading(false);
      setHasInProgressUploads(false);
    }
  };

  const clearPage = async () => {
    setIsClearing(true);
    try {
      await clearOrderDocPage(docId, pageNum);
      finishedHandler();
    } catch (err) {
      let errMessage = 'Unable to upload page';
      if (err instanceof ValidationException && err.fields?.pageImg == UPLOADED_FILE_TYPE_NOT_ALLOWED) {
        errMessage = 'Unknown format, please upload jpeg or png';
      }
      showError(errMessage);
    } finally {
      setIsClearing(false);
    }
  };

  const imageCaptured = e => {
    console.log(e.target.files);
    saveCapture(e.target.files[0]);
  }

  return (
    <div className="doc-page doc-page-mobile">
      {!isPageUploaded && (
        <>
          <div className={classnames("doc-page-img", { "pe-none": disabled })} onClick={() => cameraFileInput.current.click()}>
            {!isUploading && (isNewPage ? <AddImg /> : <NoImg />)}
            {isUploading && <i className="mdi mdi-spin mdi-loading"></i>}
          </div>
          {/*{takeCapture && (
            <Capture
              onSaveCapture={saveCapture}
              title={docName}
              pageNum={pageNum}
              back={() => setTakeCapture(false)}
            />
          )}*/}
          <Button
            className="w-100"
            color={isNewPage ? "primary" : "success"}
            onClick={() => cameraFileInput.current.click()}
            disabled={disabled}
          >
            {/* isNewPage flags that we have a brand new page to be inserted; if isNewPage is false, it means we are editing a page that already exists */}
            {isNewPage ? 'Take Page Picture' : 'Take Picture'} <i className="mdi mdi-camera"></i>
          </Button>
          <Button
            className="w-100 mt-2"
            color={isNewPage ? "primary" : "success"}
            onClick={() => uploadFileInput.current.click()}
            disabled={disabled}
          >
            {/* isNewPage flags that we have a brand new page to be inserted; if isNewPage is false, it means we are editing a page that already exists */}
            {isNewPage ? 'Upload Page Picture' : 'Upload Picture'} <i className="mdi mdi-upload"></i>
          </Button>
          <input id={'fileInput1-' + docId + '-' + pageNum} type="file" accept="image/*" capture="environment" onChange={imageCaptured} className="d-none" ref={cameraFileInput} />
          <input id={'fileInput2-' + docId + '-' + pageNum} type="file" accept="image/*" onChange={imageCaptured} className="d-none" ref={uploadFileInput} />
        </>
      )}
      {isPageUploaded && (
        <>
          <div className={classnames("doc-page-img", { 'is-rejected': pageData.status == OrderDoc.PAGE_STATUS_REJECTED })}>
            <Item content={<DocPreviewModal image={imageUrl} />}>
              {({ ref, open }) => (
                <img alt="ink-signed page" src={imageUrl} className="ink-sign-page-img" ref={ref} onClick={open} />
              )}
            </Item>
            {pageData.status == OrderDoc.PAGE_STATUS_REJECTED && <React.Fragment>
              <div className="badge badge-lg rounded-pill bg-danger rejected-badge" id={'doc-page-rejected-' + docId + '-' + pageNum} title="">
                <i className="bx bxs-error me-1"></i>Rejected
              </div>
              {!!pageData.rejectionReason && <UncontrolledTooltip placement="top" target={'doc-page-rejected-' + docId + '-' + pageNum}>{pageData.rejectionReason}</UncontrolledTooltip>}
            </React.Fragment>}
            {pageData.status == OrderDoc.PAGE_STATUS_ACCEPTED && <React.Fragment>
              <div className="badge badge-lg rounded-pill bg-success review-badge">
                <i className="bx bxs-check-circle me-1"></i>Accepted
              </div>
            </React.Fragment>}
          </div>

          {canRemovePage && <Button
            className="w-100"
            color="danger"
            onClick={clearPage}
            disabled={disabled || isClearing}
          >
            Delete Photo <i className="mdi mdi-close-circle-outline" />
          </Button>}
        </>
      )}
    </div>
  )
};

DocPageMobile.propTypes = {
  docId: PropTypes.number.isRequired,
  docName: PropTypes.string.isRequired,
  pageNum: PropTypes.number.isRequired,
  isPageUploaded: PropTypes.bool.isRequired,
  isNewPage: PropTypes.bool,
  finishedHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  pages: PropTypes.object,
  setHasInProgressUploads: PropTypes.func,
};

export default DocPageMobile;