import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  GET_IN_PROGRESS_ORDER_LIST,
  GET_ORDER_LIST,

  GET_ORDER,

  GET_ORDER_MESSAGES,
  GET_LAST_ORDER_MESSAGE,
  GET_CHAT_MEMBERS,

  GET_ORDER_INK_SIGN_DOCS,

} from "./actionTypes";

import {
  getOrderListOk,
  getOrderListErr,

  getOrderOk,
  getOrderErr,

  getOrderMessagesOk,
  getOrderMessagesErr,

  getLastOrderMessageOk,
  getLastOrderMessageErr,

  getChatMembersOk,
  getChatMembersErr,

  getOrderInkSignDocsOk,
  getOrderInkSignDocsErr,

} from "./actions";

import {
  getOrders,
  getOrder,
  getOrderMessages,
  getAllOrderMessages,
  getChatMembers,
  getInkSignOrderDocs,
} from "helpers/backendHelper";

import OrderNotary from "model/orderNotary";

const getFilters = status => {
  switch (status) {
    case OrderNotary.VIRTUAL_STATUS_PROCESSING:
      return { excStatus: OrderNotary.STATUS_ORDER_COMPLETE };
    case OrderNotary.VIRTUAL_STATUS_COMPLETE:
      return { status: OrderNotary.STATUS_ORDER_COMPLETE };
  }
};

/********** LIST **********/

function* onGetOrders({ payload: { params } }) {
  try {
    const filterToggle = yield select(state => state.Order.List.filterToggle);
    const response = yield call(getOrders, { ...params, filters: getFilters(filterToggle) });
    yield put(getOrderListOk(response));
  } catch (error) {
    yield put(getOrderListErr(error));
  }
}

function* onGetInProgressOrders() {
  try {
    const response = yield call(
      getOrders,
      { pageSize: 5, page: 1, filters: getFilters(OrderNotary.VIRTUAL_STATUS_PROCESSING) }
    );
    yield put(getOrderListOk(response));
  } catch (error) {
    yield put(getOrderListErr(error));
  }
}

/********** SINGLE **********/

function* onGetOrder({ payload: { id } }) {
  try {
    const response = yield call(getOrder, id);
    yield put(getOrderOk(response));
  } catch (error) {
    yield put(getOrderErr(error));
  }
}

/********** MESSAGES **********/

function* onGetOrderMessages({ payload: { orderId, channelId, params } }) {
  try {
    const response = yield call(getOrderMessages, orderId, channelId, params);
    yield put(getOrderMessagesOk(response, channelId));
  } catch (error) {
    yield put(getOrderMessagesErr(error));
  }
}

function* onGetLastMessage({ payload: { orderId } }) {
  try {
    const response = yield call(getAllOrderMessages, orderId, { page: 1, pageSize: 1, sortBy: "id", sortDir: "desc" });
    const message = response.messages[0];
    yield put(getLastOrderMessageOk(message));
  } catch (error) {
    yield put(getLastOrderMessageErr(error));
  }
}

function* onGetChatMembers({ payload: { orderId, channelId } }) {
  try {
    const response = yield call(getChatMembers, orderId, channelId);
    yield put(getChatMembersOk(response, channelId));
  } catch (error) {
    yield put(getChatMembersErr(error));
  }
}

/********** INK-SIGN DOCS **********/

function* onGetOrderInkSignDocs({ payload: { id } }) {
  try {
    const response = yield call(getInkSignOrderDocs, id);
    yield put(getOrderInkSignDocsOk(response));
  } catch (error) {
    yield put(getOrderInkSignDocsErr(error));
  }
}


function* orderSaga() {
  yield takeEvery(GET_ORDER_LIST, onGetOrders);
  yield takeEvery(GET_IN_PROGRESS_ORDER_LIST, onGetInProgressOrders);
  yield takeEvery(GET_ORDER, onGetOrder);

  yield takeEvery(GET_ORDER_MESSAGES, onGetOrderMessages);
  yield takeEvery(GET_LAST_ORDER_MESSAGE, onGetLastMessage);
  yield takeEvery(GET_CHAT_MEMBERS, onGetChatMembers);

  yield takeEvery(GET_ORDER_INK_SIGN_DOCS, onGetOrderInkSignDocs);
}

export default orderSaga;