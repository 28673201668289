import React, { useState } from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { route, routes } from "helpers/routeHelper";
import Message from "model/message";
import { getBeUrl, showError } from "helpers/utilHelper";

const OrderDetailedButtons = ({ order }) => {

  const printAllDocs = () => {
    // Show download loader
    setIsPrintInProgress(true)
    // When download is finished, the file will be opened externally
    // Document will lose focus, so we can hide the loader
    $(document).one('blur', function (event) {
      setIsPrintInProgress(false)
    })
    $('#print_all_docs_iframe').one('load', function (event) {
      // If iframe is loaded, this means an error occured
      // Otherwise, the file is downloaded and the iframe remains empty (not loaded)
      showError('Unable download docs for print')
      // On error, hide the loader
      setIsPrintInProgress(false)
    })
    $('#print_all_docs_iframe').attr('src', getBeUrl(`notary/order/${order.orderNotaryId}/docs/print-all`))
  }

  const navigate = useNavigate();

  const [isPrintInProgress, setIsPrintInProgress] = useState(false);

  return (<React.Fragment>
    <Button color="primary" className="mav-card-action-btn mt-4 justify-content-center d-flex btn-print" onClick={printAllDocs}>
      <div className="d-flex align-items-center">
        <i className="bx bx-download font-size-18 me-2" />
        Print all documents
        {isPrintInProgress && <i className="mdi mdi-spin mdi-loading ms-2" />}
      </div>
    </Button>
    <Button
      color="success"
      className="mav-card-action-btn d-flex justify-content-center"
      onClick={() => navigate(route(routes.view_order_messages, [order.id, Message.CHANNEL_SCHEDULER_NOTARY]))}
    >
      <i className="bx bx-message font-size-20 me-2" />
      Messages
    </Button>
    <Button color="primary" className="mav-card-action-btn d-flex align-items-center justify-content-center" onClick={() => navigate(route(routes.view_order_flow, order.id))}>Start</Button>
    <iframe id="print_all_docs_iframe" className="d-none" />
  </React.Fragment>)
}

OrderDetailedButtons.propTypes = {
  order: PropTypes.object,
};

export default OrderDetailedButtons;