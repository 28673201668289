import React from "react";
import PropTypes from "prop-types";

const DocPreviewModal = ({ image }) => {
  return (
    <div className="ink-sign-gallery-item">
      <div className="ink-sign-gallery-item-preview">
        <img src={image} className="ink-sign-gallery-item-img" />
      </div>
    </div>
  )
};

DocPreviewModal.propTypes = {
  image: PropTypes.string.isRequired,
}

export default DocPreviewModal;

