import React from "react";
import PropTypes from "prop-types";
import { sanitizeHtml } from "helpers/utilHelper";
import infoIcon from "assets/images/blue-info-icon.svg";
import alertIcon from "assets/images/blue-alert-icon.svg";

const InfoMessage = ({ description, alert, className }) => {

  return(<div className={`notary-order-info mb-3 w-100 ${className}`}>
    {!!alert ? <img src={alertIcon} className="me-2 font-size-15" /> : <img src={infoIcon} className="me-2 font-size-15" />}
    <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }} />
  </div>)
}

InfoMessage.propTypes = {
  description: PropTypes.string,
  alert: PropTypes.bool,
  className: PropTypes.string,
}

export default InfoMessage;