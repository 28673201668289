import React, { useCallback, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import NotaryBidCardDetailed from "./Partial/NotaryBidCard/Detailed";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import { getNotaryBid } from "store/actions";
import socketEvent from "constants/socketEvent";
import { useSocketOn } from "hooks/socket";

const NotaryBidView = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hook that checks permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const { notaryBid, isLoadInProgress, notaryBidError } = useSelector(state => state.NotaryBid.Single);

  /********** OTHER **********/

  const refreshNotaryBid = () => dispatch(getNotaryBid(id));

  /********** SOCKET **********/

  const onNotaryBidChanged = useCallback(data => {
    if (data.id == notaryBid.id) {
      refreshNotaryBid();
    }
  }, [refreshNotaryBid]);

  // listen for changes on messages
  useSocketOn(socketEvent.notaryBidChanged, onNotaryBidChanged);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshNotaryBid();
  }, [id]);

  return <React.Fragment>
    {iAmGranted(perms.view_notary_bids) && <div className="page-content">
      {notaryBid && <React.Fragment>
        <MetaTitle>Lead #{id}</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(notaryBid)} />
          <Row>
            <Col xs={12}>
              <NotaryBidCardDetailed />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !notaryBid && <Preloader className="inner" />}
      {notaryBidError && <Error error={notaryBidError} title404="Lead not found" />}
    </div>}
    {iAmNotGranted(perms.view_notary_bids) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = notaryBid => [{
  title: "MY LEADS",
}, {
  title: `#${notaryBid.id}`,
}];

export default NotaryBidView;
