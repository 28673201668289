import React from 'react';
import { Alert, Button, Modal, ModalBody, Table } from 'reactstrap';
import PropTypes from "prop-types";

const TrackingModal = ({ isOpen, toggle, modalData, closeModal, modalDataError, trackingNumber }) => (
  <Modal className="tracking-modal" isOpen={isOpen} toggle={toggle} centered fade={false}>
    <div className="tracking-modal-header mb-3 d-flex">
      <h4 className="tracking-modal-title mb-0">Delivery information</h4>
      <button type="button" className="btn-close ms-auto p-2" aria-label="Close" onClick={toggle} />
    </div>
    <ModalBody>
      {modalData && <Table className="mb-0">
        <thead>
        <tr>
          <th>No.</th>
          <th className="text-center">Date</th>
          <th className="text-center">Event</th>
          <th className="text-center">Address</th>
        </tr>
        </thead>
        <tbody>
        {modalData?.map((info, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td className="text-center">{info.date}</td>
            <td className="text-center">
              <strong>{info.description || "--"}</strong>
            </td>
            <td className="text-end">
              {info.address.join(" ") || "--"}
            </td>
          </tr>
        ))}
        </tbody>
      </Table>}
      {modalDataError && <Alert color="danger" className="fade show text-center">
        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load data
      </Alert>}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="font-weight-semibold">
          Tracking number: <span className="package-number ms-2">{trackingNumber || '--'}</span>
        </div>
        <Button type="button" color="secondary" onClick={closeModal}>Close</Button>
      </div>
    </ModalBody>
  </Modal>
);

TrackingModal.propTypes = {
  isOpen: PropTypes.bool,
  modalDataError: PropTypes.bool,
  toggle: PropTypes.func,
  closeModal: PropTypes.func,
  modalData: PropTypes.object,
  trackingNumber: PropTypes.string,
}

export default TrackingModal;