import React, { useState } from "react";
import { NavItem, NavLink, TabContent, TabPane, Card, CardBody, Row, Container } from "reactstrap";
import Col from "components/Shared/Col";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import classnames from "classnames";
import TCSection from "./Partial/Section/TC";
import NDASection from "./Partial/Section/NDA";
import BackButton from "components/Shared/BackButton";

const STEP_TC = 0;
const STEP_NDA = 1;

const tabs = {
  [STEP_TC]: TCSection,
  [STEP_NDA]: NDASection,
};

const TermsView = () => {
  // hook that checks permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  const [activeTab, setActiveTab] = useState(STEP_TC);

  // the component to be rendered for the active tab
  const ActiveTabComponent = tabs[activeTab];

  return <React.Fragment>
    {iAmGranted(perms.view_terms) && <div className="page-content">
      <MetaTitle>Terms</MetaTitle>
      <Container id="terms-tabs" fluid>
        <BackButton />
        <Row>
          <Col xl="12" xxxl="10" xxxxl="8">
            <Card>
              <CardBody>
                <h5 className="fw-bold mb-2">NDA and T&C</h5>
                <div className="vertical-wizard wizard clearfix vertical">
                  <div className="steps clearfix pb-4">
                    <ul>
                      <NavItem className={classnames({ current: activeTab === STEP_TC })}>
                        <NavLink className={classnames({ active: activeTab === STEP_TC })} onClick={() => setActiveTab(STEP_TC)}>
                          Terms of Service
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === STEP_NDA })}>
                        <NavLink className={classnames({ active: activeTab === STEP_NDA })} onClick={() => setActiveTab(STEP_NDA)}>
                          Notary NDA
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix p-0 ps-xl-5">
                    <TabContent activeTab={activeTab} className="body">
                      <TabPane tabId={activeTab}>
                        <ActiveTabComponent />
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>}
    {iAmNotGranted(perms.view_terms) && <AccessDenied />}
  </React.Fragment>
}

export default TermsView;
