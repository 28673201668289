import React from "react";
import PropTypes from "prop-types"
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { uploadCredential } from "store/actions";
import Credential from "model/credential";

const FormCredential = ({ children }) => {

  const dispatch = useDispatch();

  /********** FORM CONFIG **********/

  // default values of the form fields
  const formInitialValues = {
    type: '',
    commissionState: '',
    commissionNo: '',
    file: '',
    expiry: '',
    countiesCovered: '',
    commissionState2: '',
    driverLicenseNo: '',
  };

  // we are using Formik higher-order component here in order to be able to access the Formik context in child components
  // this helps us split the code in smaller components (one for form definition - this one, and one for the content)
  // decoupling the form content from the form definition allows us to use the same components in two different places: the profile setup and edit
  // in case of differences between setup and edit, we can use a `isSetupRoute` prop to flag from where we render the component
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        const formData = new FormData();
        // rebuild form data to send file
        Object.keys(values).forEach(key => {
          formData.append(key, values[key]);
        });
        // send form data
        dispatch(uploadCredential(formData));
      }}
    >
      {children}
    </Formik>)
}

const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

const validationSchema = Yup.object({
  type: Yup.number().required("Field is required"),
  file: Yup.mixed()
    .required("Field is required")
    .test("fileType", "File must be a pdf or an image", value => !value || allowedFileTypes.includes(value.type)),
  // validation for commission certificate
  commissionNo: Yup.string().when("type", {
    is: Credential.TYPE_COMMISSION_CERTIFICATE,
    then: Yup.string().required("Field is required"),
  }),
  // validation for all credential types, except driver license
  expiry: Yup.number().when("type", {
    is: type => type && type !== Credential.TYPE_DRIVER_LICENSE_NUMBER,
    then: Yup.number().required("Field is required"),
  }),
  // validation for all credential types
  expirationTs: Yup.string().when(["expiry", "type"], {
    is: (expiry, type) => expiry === true || type === Credential.TYPE_DRIVER_LICENSE_NUMBER,
    then: Yup.string().trim().required("Field is required"),
  }),
  // validation for driver license
  driverLicenseNo: Yup.string().when("type", {
    is: Credential.TYPE_DRIVER_LICENSE_NUMBER,
    then: Yup.string().required("Field is required"),
  }),
});

FormCredential.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default FormCredential;