import React, { useState, useEffect } from "react";
import FormBilling from "../Form/Billing";
import ViewBilling from "pages/Profile/View/Billing";
import FormContentBilling from "../Form/Content/Billing";
import FormEditCard from "../Form/Edit/Card";
import { useProfile } from "context/profile";

const SectionBilling = () => {

  const { isLoadInProgress, refreshProfile } = useProfile();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadInProgress) {
      setIsRefreshing(false);
    }
  }, [isLoadInProgress]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshProfile();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormBilling>
      <FormEditCard title={"Billing/Payment Information"} finishedHandler={toggleEditMode}>
        <FormContentBilling />
      </FormEditCard>
    </FormBilling>}
    {!isEditModeActive && <ViewBilling toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

export default SectionBilling;