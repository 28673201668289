import {
  GET_PROFILE,
  GET_PROFILE_OK,
  GET_PROFILE_ERR,

  AGREE_TO_PROFILE_TERMS,

  SAVE_PROFILE_BILLING,
  SAVE_PROFILE_SHIPPING,
  SAVE_PROFILE_CONTACT,
  SAVE_PROFILE_CREDENTIALS,
  SAVE_PROFILE_EXPERIENCE,
  SAVE_PROFILE_AREA,
  SAVE_PROFILE_OK,
  SAVE_PROFILE_ERR,
  DO_SAVE_PROFILE_CLEANUP,
  RESET_SAVE_PROFILE_ACTION_FLAG,
} from './actionTypes';

/********** GET PROFILE **********/

export const getProfile = () => ({
  type: GET_PROFILE,
});

export const getProfileOk = response => ({
  type: GET_PROFILE_OK,
  payload: { response },
});

export const getProfileErr = error => ({
  type: GET_PROFILE_ERR,
  payload: { error },
});

/********** SAVE PROFILE **********/

export const agreeToProfileTerms = () => ({
  type: AGREE_TO_PROFILE_TERMS,
});

export const saveProfileBilling = data => ({
  type: SAVE_PROFILE_BILLING,
  payload: { data },
});

export const saveProfileShipping = data => ({
  type: SAVE_PROFILE_SHIPPING,
  payload: { data },
});

export const saveProfileContact = data => ({
  type: SAVE_PROFILE_CONTACT,
  payload: { data },
});

export const saveProfileCredentials = data => ({
  type: SAVE_PROFILE_CREDENTIALS,
  payload: { data },
});

export const saveProfileExperience = data => ({
  type: SAVE_PROFILE_EXPERIENCE,
  payload: { data },
});

export const saveProfileArea = data => ({
  type: SAVE_PROFILE_AREA,
  payload: { data },
});

export const saveProfileOk = response => ({
  type: SAVE_PROFILE_OK,
  payload: { response },
});

export const saveProfileErr = error => ({
  type: SAVE_PROFILE_ERR,
  payload: { error },
});

export const doSaveProfileCleanup = () => ({
  type: DO_SAVE_PROFILE_CLEANUP,
});

export const resetSaveProfileActionFlag = flag => ({
  type: RESET_SAVE_PROFILE_ACTION_FLAG,
  payload: { flag },
});
