import React from "react";
import PropTypes from "prop-types";
import OrderNotary from "model/orderNotary";
import Order from "model/order";
import { CardHeader, CardTitle } from "reactstrap";
import { formatPhone } from "helpers/utilHelper";
import phoneIcon from "assets/images/phone-icon.svg";
import addressIcon from "assets/images/address-icon.svg";
import emailIcon from "assets/images/order/email-icon.png";


const OrderCardHeaderDesktop = ({ order }) => {

  const virtualStatus = OrderNotary.getVirtualStatus(order.orderNotaryStatus);

  const isOrderCancelled = order?.status == Order.STATUS_CANCELLED;

  const getStatusName = () => isOrderCancelled ? 'Canceled' : OrderNotary.getVirtualStatusName(virtualStatus);
  const getStatusColor = () => isOrderCancelled ? 'danger' : OrderNotary.getVirtualStatusColor(virtualStatus);

  return (<div className={`mav-card-header ${!!order.orderSigner2FullName && 'additional-signer'}`}>
    {/* Customer Header */}
    <CardHeader className="header-customer">
      <div className="w-100">
        <div className="d-flex justify-content-between align-center">
          <div className="mav-card-subtitle">Customer:</div>
          <div>
            <span className={`badge badge-sm rounded-pill px-2 py-1 bg-${getStatusColor()}`}>
              {getStatusName()}
            </span>
          </div>
        </div>

        <CardTitle className="mav-card-title font-weight-700 mb-3">{order.orderSignerFullName}</CardTitle>
      </div>

      <div className="contact-info">
        <div className="d-flex align-items-center mb-1">
          <img src={phoneIcon} alt="phone-icon" />
          <div className="mb-1 font-size-10 ms-2">{formatPhone(order.orderSignerPhone)}</div>
        </div>

        <div className="d-flex align-items-center mb-1">
          <img className='header-section-icon' alt="icon" src={emailIcon} />
          <div className="mb-1 font-size-10 ms-2">{order.orderSignerEmail}</div>
        </div>

        <div className="d-flex align-items-center">
          <img src={addressIcon} alt="address-icon" />
          <div className="mb-1 font-size-10 ms-2">{order.orderSignerAddress}, {order.orderSignerState}, {order.orderSignerCity}, {order.orderSignerZip}{order.orderSignerAddress2 && `, ${order.orderSignerAddress2}`}</div>
        </div>
      </div>
    </CardHeader>

    {/* Additional Signer Header */}
    {!!order.orderSigner2FullName && <CardHeader className="header-additional-signer">
      <div className="w-100">
        <div className="d-flex justify-content-between align-center">
          <div className="mav-card-subtitle font-weight-700">Additional signer:</div>
        </div>

        <CardTitle className="mav-card-title font-weight-700 mb-3">{order.orderSigner2FullName}</CardTitle>

        <div className="contact-info">
          <div className="d-flex align-items-center mb-1">
            <img src={phoneIcon} alt="phone-icon" />
            <div className="mb-1 font-size-10 ms-2">{formatPhone(order.orderSigner2Phone)}</div>
          </div>

          <div className="d-flex align-items-center mb-1">
            <img className='header-section-icon' alt="icon" src={emailIcon} />
            <div className="mb-1 font-size-10 ms-2">{order.orderSigner2Email}</div>
          </div>

          <div className="d-flex align-items-center">
            <img src={addressIcon} alt="address-icon" />
            <div className="mb-1 font-size-10 ms-2">{order.orderSigner2Address}, {order.orderSigner2State}, {order.orderSigner2City}, {order.orderSigner2Zip}{order.orderSigner2Address2 && `, ${order.orderSigner2Address2}`}</div>
          </div>
        </div>
      </div>
    </CardHeader>}
  </div>)
};

OrderCardHeaderDesktop.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderCardHeaderDesktop;