import React from "react";

const NDASection = () => {
  return <React.Fragment>
    <div className="terms-title mb-3">NONDISCLOSURE AGREEMENT</div>

    <div className="terms-section">
      <p>
        {`This Nondisclosure Agreement (the “Agreement”) is entered into as of [date] (the “Effective Date”),
        between Maverick Signings Inc. (the “Company”) and [name of notary public] (the
        “Notary”) (collectively, the “Parties” and each a “Party”).`}
      </p>
      <p>
        {`WHEREAS, the Company has confidential information relating to its business that it desires to
        disclose to Notary for the purposes of Notary’s witnessing, notarization, and performance of
        document signings (the “Signing”) dated [notary start date] and Signings thereafter performed for
        the Company.`}
      </p>
      <p>
        {`NOW THEREFORE, in consideration of the covenants and agreements contained herein and for
        other good and valuable consideration, the receipt and sufficiency of which are hereby
        acknowledged, the Parties agree as follows:`}
      </p>
    </div>

    <div className="terms-title">CONFIDENTIAL INFORMATION</div>
    <div className="terms-section">
      <p>
        {`Notary understands and agrees that ti may have access to trade secrets or confidential or proprietary
        information whether in write, digital, oral or other form that is unique, confidential or proprietary
        to the Company, including, but not limited to the information related to the technology, business,
        personnel, customers, or activities or Company or its affiliates (“Confidential Information”).
        “Confidential Information” including, but is not limited to any information furnished or disclosed,
        in whatever form or medium, by the Company to the Notary relating to the business of the
        Company, and includes, without limitation, consumer’s personal information, contract terms,
        financial information, business procedures, processes, techniques, methods, ideas, discoveries,
        inventions, developments, records, product designs, source codes, product planning, trade secrets,
        customer lists, and material samples, all of which is deemed confidential and proprietary.`}
      </p>
    </div>

    <div className="terms-title">USE OF CONFIDENTIAL INFORMATION AND CONFIDENTIALITY OBLIGATIONS</div>
    <div className="terms-section">
      <p>
        {`The Notary agrees that it will hold the Company’s Confidential Information in strict confidence,
        that it will protect such Confidential Information to the same degree that it protects Confidential
        Information pertaining to its own business of similar nature, but with no less than reasonable care,
        that it will use such Confidential INformation only for the purposes permitted hereunder, that it
        will not copy such Confidential Information except as reasonably required for the purposes
        permitted by this Agreement and that it will limit access to such such Confidential Information to
        the receiving Party’s employees and contractors with a need to know who are subject to
        confidentiality obligations no less restrictive than those provided herein. All Confidential
        Information remains the disclosing Party’s property.`}
      </p>
    </div>

    <div className="terms-title">COMPELLED DISCLOSURE</div>
    <div className="terms-section">
      <p>
        {`Should a Notary become legally compelled to disclose any portion of the Confidential Information under
        applicable law or in connection with a lawsuit, regulatory inquiry or similar proceeding, Notary shall give
        Company prompt notice of that fact, including in its notice the legal basis for the required disclosure
        and the nature of the Confidential Information that must be disclosed. The Notary shall cooperate fully
        with the Company in obtaining a protective order or other appropriate protection relating to the disclosure
        and subsequent use of such Confidential Information. The Notary shall disclose only that portion of the Confidential
        Information that is legally required to be disclosed.`}
      </p>
    </div>

    <div className="terms-title">RETURN OF CONFIDENTIAL INFORMATION</div>
    <div className="terms-section">
      <p>
        {`The Company may elect at any time to terminate further access to its Confidential Information. Upon written request
        by Company, the Notary shall return to the Company all Confidential Information in any form that such Confidential
        Information is currently existing and, at the sole discretion of Company, promptly destroy any and all material or
        information derived from the Confidential Information, including any copies.`}
      </p>
    </div>

    <div className="terms-title">NO OWNERSHIP OR OTHER RIGHTS GRANTED</div>
    <div className="terms-section">
      <p>
        {`Nothing contained in this Agreement shall be construed as granting or conferring any rights, by license or otherwise,
        express or implied, in or to any intellectual property rights, including, without limitation, any rights to register or
        apply for any patents, copyrights, trademarks, know-how, or other proprietary rights of either Party acquired before or
        after the date of this Agreement.`}
      </p>
    </div>

    <div className="terms-title">TERM</div>
    <div className="terms-section">
      <p>
        {`This Agreement and the obligations of confidentiality and nondisclosure of Confidential INformation (except for trade
          secrets) shall remain in full force and effect for five (5) years after the termination of the business relationship
          provided under this Agreement; or such longer time as the Parties may agree in writing. The Notary’s duty to hold trade
          secrets confidential is in perpetuity for as long as such information constitute trade secrets fo the disclosing Party
          under applicable law. Within thirty (30) days after termination or expiration of this Agreement or upon the COmpany’s
          request, and it Company’s sole discretion, the Notary shall either return to the disclosing PArty originals and all copies
          of any Confidential Information and all information, records and materials developed therefrom by the Company, or destroy
          the same, other than such Confidential INformation as to which this Agreement or any applicable Agreement expressly provides
          a continuing right to retain at the time of the request. `}
      </p>
    </div>

    <div className="terms-title">REMEDIES FOR BREACH OF AGREEMENT</div>
    <div className="terms-section">
      <p>
        {`The Confidential Information protected by this Agreement is of a special character, such that money damages would not be
        solely sufficient to award or compensate for any unauthorized use or disclosure of the Confidential Information. The Parties
        agree that injunctive and other equitable relief would also be appropriate to prevent any such actual or threatened unauthorized
        use or disclosure. The remedies stated above may be pursued in addition to any other remedies available at law or in equity, and
        the Notary agrees to waive any requirement for the securing or posting of any bond in connection with such remedies. In the event
        of litigation to enforce any provision of this Agreement, the prevailing Party shall be entitled to recover all costs, including
        its reasonable attorney fees and costs, incurred in connection with the litigation.`}
      </p>
    </div>

    <div className="terms-title">NO REPRESENTATIONS</div>
    <div className="terms-section">
      <p>
        {`The Company does not make any representations or warranties as to the accuracy, completeness, or fitness for a particular purpose
        of any information disclosed to the Notary under this Agreement. Neither the Company nor its representatives shall have any liability
        or responsibility to the Notary (except as provided in this Agreement) or to any other person or entity resulting from the use of any
        information so furnished or otherwise provided.`}
      </p>
    </div>

    <div className="terms-title">GOVERNING LAW</div>
    <div className="terms-section">
      <p>
        {`This Agreement shall be governed by and construed in accordance with the  laws of the State of California, without regard to its conflict
        of laws principles. Both parties hereby irrevocably consent to the exclusive jurisdiction and venue of the federal and state courts located
        in Orange COunty, California, in connection with any matter arising out of this Agreement or the transactions contemplated hereby.`}
      </p>
    </div>

    <div className="terms-title">NO WAIVER</div>
    <div className="terms-section">
      <p>
        {`No waiver of any term, provision, or condition of this Agreement shall be effective unless it is in writing and signed by the party granting
        the waiver. Whether by conduct or otherwise, in any once or more instances, no waiver shall be deemed to be or be construed as a further or
        continuing waiver of any such term, provision, or condition or as a waiver of any other term, provision, or condition of this Agreement. `}
      </p>
    </div>

    <div className="terms-title">SEVERABILITY</div>
    <div className="terms-section">
      <p>
        {`If any provision of this Agreement is determined by any court of competent jurisdiction to be invalid or unenforceable, such provision
        shall be interpreted to the maximum extent to which it is valid and enforceable, all as determined by such court in such action, and the
        remaining provisions of this Agreement shall, nevertheless, continue in full force and effect without being impaired or invalidated in any way.`}
      </p>
    </div>

    <div className="terms-title">ENTIRE AGREEMENT</div>
    <div className="terms-section">
      <p>
        {`This Agreement constitutes the Parties’ entire Agreement with respect to the subject matter hereof and supersedes any and all prior statements
        or agreements, both written and oral. This Agreement may not be amended except by a writing signed by the Parties.`}
      </p>
    </div>

  </React.Fragment>
}

export default NDASection;