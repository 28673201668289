import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import { useProfile } from "context/profile";
import { getSsoAppUrl, showError, showSuccess } from "helpers/utilHelper";
import useFirstRender from "hooks/firstRender";
import { agreeToProfileTerms, doSaveProfileCleanup, resetSaveProfileActionFlag } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import useProfileTermsCompleteCheck from "hooks/profile/profileTermsCompleteCheck";

const ProfileSetupTerms = () => {

  // check if the user has already agreed to terms
  // and if that is the case, navigate to the next step
  useProfileTermsCompleteCheck();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notary, refreshProfile } = useProfile();
  const { isNotFirstRender } = useFirstRender();

  /********** STATE **********/

  // flag signaling that a profile context refresh is in progress
  const [isProfRefInProgress, setIsProfRefInProgress] = useState(false);

  // isSaveInProgress - TRUE if a save request is in progress, FALSE otherwise
  // saved - TRUE if a save was attempted and was successfull, FALSE if it failed, NULL if no save was attempted yet
  const { isSaveInProgress, saved } = useSelector(state => state.Profile.Form);

  /********** EFFECTS **********/

  useEffect(() => {
    return () => {
      // clear redux state on component unmount
      // so the other wizard steps start clean
      dispatch(doSaveProfileCleanup());
    }
  }, []);

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess('Agreement saved');
      // since we have added new information to the profile
      // we have to reload the profile context
      // else the new info will not be available in the next steps
      doProfileRefresh();
    } else if (saved === false) {
      showError('Unable to save agreement');
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag('saved'));
    }
  }, [saved]);

  // runs when the profile info (from context) changes
  // this happens twice:
  // 1. on the first render (null -> value)
  // 2. when the user clicks NEXT and the profile is updated (old value -> new value)
  // we are interested in #2
  useEffect(() => {
    if (isNotFirstRender) {
      // profile info has been reloaded so it is safe to navigate to the next step
      navigate(route(routes.setup_profile_billing));
    }
  }, [notary]);

  /********** OTHER **********/

  /**
   * Changes the in-progress flag and triggers a context refresh
   */
  const doProfileRefresh = () => {
    setIsProfRefInProgress(true);
    refreshProfile();
  }

  /**
   * Calls the backend to update the profile info with the terms agreement
   */
  const doAgreeToTerms = () => dispatch(agreeToProfileTerms());

  /**
   * Returns TRUE if a remote call is in progress
   * We use it to determine whether the submit button should show a loading icon
   * @returns bool
   */
  const isSubmitBusy = () => isSaveInProgress || isProfRefInProgress;

  return <React.Fragment>
    <div className="page-content">
      {!!notary && <React.Fragment>
        <MetaTitle>Profile - Terms</MetaTitle>
        <Container fluid className="profile-wizard">
          <Breadcrumbs breadcrumbItems={breadcrumbs()} />
          <Row>
            <Col xl="12" xxxl="10" xxxxl="8">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h5 className="text-decoration-underline font-weight-semibold">Nondisclosure Agreement:</h5>
                      <ul className="terms-text">
                        <li>You agree to keep confidential the information you witness, notarize, and otherwise perform on behalf of Maverick
                        (&ldquo;the Signing&rdquo;) and promise to maintain the confidentiality of the signing thereafter. &ldquo;Confidential Information&rdquo;
                        means any information furnished or disclosed, in whatever form or medium, by Maverick to you relating to the, signer&apos;s
                        private personal information, contract terms, financial information; and Maverick&apos;s business procedures, processes,
                        techniques, methods, ideas, discoveries, inventions, developments, records, product designs, source codes, product
                        planning, trade secrets, customer lists, and material samples, all of which is deemed confidential and proprietary.
                        You agree to refrain from disclosing the Confidential Information to third parties unless Maverick has given its prior
                        written authorization. Maverick may elect to terminate your access to Confidential Information at any time. You agree
                        to return to Maverick all Confidential Information and promptly destroy all Confidential Information upon written
                        request. You agree to take all reasonable steps to preserve the confidential and proprietary nature of the Confidential
                        Information. &ldquo;Reasonable Steps&rdquo; include maintaining appropriate administrative, technical, and physical safeguards for
                        handling Confidential Information, and to reasonably cooperate with Maverick&apos;s periodic assessment of your safeguards
                        as required by the Gramm-Leach-Bliley Act and the Fair and Accurate Credit Transactions Act. In the case of a security
                        breach, you will notify Maverick immediately of the breach. You will indemnify and hold Maverick harmless against
                        claims and liability arising from unauthorized use or disclosure of Confidential Information. California law shall
                        govern in case of liability arising from your performance to keep confidential the Confidential Information and the
                        prevailing Party shall be entitled to recover all costs, including reasonable attorney fees and costs incurred in
                        connection with the litigation.</li>
                      </ul>
                      <div className="font-weight-semibold">T&C - <a href="https://mavsign.com/terms-and-conditions" target="_blank" rel="noreferrer">Terms and conditions - Mavsign</a></div>
                      <div className="text-end">
                        <a href={getSsoAppUrl('logout')} className="btn btn-primary btn-faded">
                          <i className="mdi mdi-chevron-left me-1" />
                          Cancel
                        </a>
                        <Button type="button" color="primary" className="ms-2" onClick={doAgreeToTerms} disabled={isSubmitBusy()}>
                          {isSubmitBusy() && <i className="mdi mdi-spin mdi-loading me-1" />}
                          {!isSubmitBusy() && <i className="mdi mdi-check me-1" />}
                          Agree
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
    </div>
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: 'PROFILE',
}, {
  title: 'Signing Rules & Guidelines',
}];

export default ProfileSetupTerms;