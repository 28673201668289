import MetaTitle from "components/Shared/MetaTitle";
import { Col, Container, Row } from "reactstrap";
import React from "react";
import DownloadDocuments from "./Section/DownloadDocuments";
import BackButton from "components/Shared/BackButton";

const Documents = () => {
  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>Documents</MetaTitle>
      <Container fluid className='documents'>
        <BackButton />
        <Row>
          <Col>
            <DownloadDocuments />
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default Documents;