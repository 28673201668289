import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Button, Alert } from "reactstrap";
import { formatPhone } from "helpers/utilHelper";
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import OrderCardHeader from "pages/Order/Partial/OrderCard/Mobile/Header";
import Message from "model/message";
import OrderServicesSection from "components/Shared/OrderServicesSection";
import UserAvatar from "components/Shared/UserAvatar";
import { getInitialsFromName } from "helpers/utilHelper";
import DealershipSection from "pages/Order/Partial/OrderCard/OrderInformations/Dealership";

const OrderCardMobile = ({ order }) => {

  // router hook that helps redirect
  const navigate = useNavigate();

  const notaryIsInactive = order.orderNotaryIsInactive;

  return (<React.Fragment>
    <Card className="mav-card order-card grid-view mobile-card">
      {!!notaryIsInactive && <div className="opaque-overlay"></div>}
      <OrderCardHeader order={order} />
      <CardBody className="mav-card-body px-3 py-2">
        {!!notaryIsInactive && <Alert color="warning" className="fade show mb-3 text-center bring-forward">
          You are no longer assigned to this order.
        </Alert>}
        <Row className="px-0 py-2">
          <Col>
            <DealershipSection order={order} />
          </Col>
        </Row>
        <Row className="px-0 py-2 mb-3">
          <Col xs={6} >
            <div className="user-info-box text-center">
              <UserAvatar id={order.userId} image={order.dealerImage} initials={getInitialsFromName(order.dealerFullName)} size="lg" />
              <div className="user-info-box-role mt-2">{order.dealerRoleName}</div>
              <div className="user-info-box-name mb-2">{order.dealerFullName}</div>
              <div className="user-info-box-phone">{formatPhone(order.dealerPhone) || '--'}</div>
            </div>
          </Col>

          <Col xs={6}>
            <div className="user-info-box text-center">
              <UserAvatar id={order.schedulerId} image={order.schedulerImage} initials={getInitialsFromName(order.schedulerFullName)} size="lg" />
              <div className="user-info-box-role mt-2">Scheduler</div>
              <div className="user-info-box-name mb-2">{order.schedulerFullName}</div>
              <div className="user-info-box-phone">{formatPhone(order.schedulerPhone) || "--"}</div>
            </div>
          </Col>
        </Row>
        <OrderServicesSection order={order} />
        {!notaryIsInactive &&
          <Button color="success" className="my-2 w-100"
            onClick={() => navigate(route(routes.view_order_messages, [order.id, Message.CHANNEL_SCHEDULER_NOTARY]))}>
            Messages
          </Button>}
        <Button color="primary" className="mt-2 mb-2 w-100 bring-forward"
          onClick={() => navigate(route(routes.view_order, order.id))}>
          View Details
        </Button>
      </CardBody>
    </Card>
  </React.Fragment>
  )
}

OrderCardMobile.propTypes = {
  order: PropTypes.object,
};

export default OrderCardMobile;