import React, { useEffect } from "react";
import {Card, CardBody, Alert, CardTitle, CardFooter} from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useDispatch, useSelector } from "react-redux";
import OrderNotary from "model/orderNotary";
import {
  doInProgressOrdersCleanup,
  getInProgressOrders,
} from "../../../../store/dashboard/actions";
import {formats, formatTimestamp} from "../../../../helpers/dateHelper";


const WIPOrdersMobile = () => {

  /********** STATE **********/

  const { orders, ordersError, isLoadInProgress } = useSelector(state => state.Dashboard.ProgressOrders);
  const ordersLimit  = 10;

  const dispatch = useDispatch();

  /********** HANDLER **********/
  const handleMeetingTime = (timestamp, status, id) => {
     if(timestamp) return formatTimestamp(timestamp, formats.HOUR);

     if(!timestamp) {
       switch (status) {
         case OrderNotary.STATUS_ASSIGNED:
         case OrderNotary.STATUS_VIEWED:
           return '-'
         case OrderNotary.STATUS_DOCS_RECEIVED:
           return (<Link to={route(routes.view_order_flow, id)}>
             <span className="text-primary btn btn-light w-100 font-weight-500">Schedule</span>
           </Link>)
       }
     }
  }

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getInProgressOrders(ordersLimit));
    return () => {
      // state cleanup on component unmount
      dispatch(doInProgressOrdersCleanup());
    }
  }, []);

  return <>
    <Card className="wip-orders-card">
      <CardTitle>In Progress Orders</CardTitle>
      <CardBody className="pt-1">
        {orders.length > 0 && orders.map((entry, index) => <div key={index} className="mobile-card mb-4">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex">
              <div>Signing ID: </div>
              <div className="ms-2"><strong>{index + 1}</strong></div>
            </div>
            <div>
              <span className={`badge badge-lg rounded-pill bg-${OrderNotary.getStatusColor(entry.status)}`}>
                {OrderNotary.getStatusName(entry.status)}</span>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <div>
              <div className="card-label">Customer: </div>
              <div>{entry.customerName}</div>
            </div>
            <div>
              <div className="card-label">Dealer: </div>
              <div>{entry.dealerName}</div>
            </div>
          </div>

          <div className="divider-faded divider-sm" />

          <div className="text-center">
            <div className="font-size-10 mb-2">Meeting time</div>
            <div className="font-weight-500">{handleMeetingTime(entry.meetingTs, entry.status, entry.id)}</div>
          </div>
          <Link to={route(routes.view_order, entry.id)}><span className="text-primary btn btn-light w-100 font-weight-500 mt-3">View Details</span></Link>
        </div>)}
        {orders.length === 0 && !ordersError && !isLoadInProgress && <p>No orders found</p>}
        {!!ordersError && <Alert color="danger" className="fade show text-center mb-0"><i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load orders</Alert>}
        {isLoadInProgress && <SpinnerChase className="sm" />}
      </CardBody>
      <CardFooter className="bg-transparent d-flex justify-content-end pt-0">
        <a className="btn btn-primary w-100 font-weight-500" href={route(routes.list_orders)}>
          View All <i className="bx bx-right-arrow-alt" />
        </a>
      </CardFooter>
    </Card>
  </>
}

export default WIPOrdersMobile;