import {
  GET_AUTH_USER,
  GET_AUTH_USER_OK,
  GET_AUTH_USER_ERR,
  LOGOUT_USER_OK,
} from './actionTypes';

/********** LOGIN **********/

export const getAuthUser = () => ({
  type: GET_AUTH_USER,
});

export const getAuthUserOk = response => ({
  type: GET_AUTH_USER_OK,
  payload: { response },
});

export const getAuthUserErr = error => ({
  type: GET_AUTH_USER_ERR,
  payload: { error },
});

export const logoutUserOk = () => ({
  type: LOGOUT_USER_OK,
});