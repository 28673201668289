import React from "react";

const TCSection = () => {
  return <React.Fragment>
    <div className="terms-title mb-3">TERMS AND CONDITIONS OF SERVICE</div>

    <div className="terms-title">Recitals</div>

    <div className="terms-section">
      <p>
        {`Maverick Signings offers a suite of signing and identification verification platforms that are used separately or in conjunction
        with each other when dealing with customers that are out of dealership signing automotive contracts and documents. Although the
        usage of a Notary and or any combinations of protection we offer is a very high level of fraud mitigation, the sophistication of
        fraudulent identification cards and synthetic identity improve each year. Therefore, Maverick Signings is intended to be used in
        conjunction with Dealer’s identity theft prevention program as required by the Federal Trade Commission’s Red Flag rules under the
        Fair Credit Reporting Act (FCRA) and Fair and Accurate Credit Transactions Act (FACTA) because it cannot guarantee any or all of
        its products used on their own, or together, to prevent identity fraud from occurring. `}
      </p>
    </div>

    <div className="terms-title">Services Provided</div>
    <div className="terms-section">
      <p>
        {`Maverick will provide a licensed notary (“Notary”) to obtain signatures on Dealer’s prepared documents by Customer. Maverick
        will assign a Notary within 24 hours of Maverick receiving Dealer’s written request for service, excluding national holidays.
        Any signings received after 12:00 pm PST on Saturday or at any time on Sundays, or on national holidays will be assigned on the
        next business day. Dealer will be notified when Notary has confirmed the date, time and location of the scheduled appointment with
        Dealer’s customer. If a scheduled appointment date, time or location was not provided by Dealer, Notary will set a time, date and
        location with Dealer’s customer at a mutually agreeable time.  Notary will travel to the signing location to witness Dealer’s
        customer’s signing of Dealer’s documents.  Neither Maverick nor the Notary review Dealer’s paperwork to determine accuracy,
        sufficiency or completeness. Notary will have Dealer’s customer sign or initial all required lines clearly marked by Dealer either
        by Dealer highlighting or placing signature marker stickers wherever Dealer requires customer’s signatures, initials or acknowledgements
        in accordance with Dealer’s checklist.  Notary will provide 1 notarized Affidavit of Identity to Dealer, and upon completion of signing
        Notary will collect all documents provided by Dealer, the notarized forms, funds, (a digital photograph provided by customer if that
        option was elected by Dealer), and all other pertinent items provided by Dealer, and place them in the pre-addressed, pre-paid return
        envelope provided by Dealer when the original documents were sent to Notary. All overnight packages will be dropped off for delivery
        to Dealer the same day, if the signing takes place prior to 3:00 P.M. in the time zone where the signing takes place, and a suitable
        drop off location is available. Notaries will make best efforts to ship overnight packages the same day back to Dealer when the signing
        takes place after 3:00 P.M.  All shipping costs are the responsibility of the Dealer in addition to all other fees paid to Maverick.`}
      </p>
      <p>
        {`Maverick takes and has no responsibility for any statement made in Dealer’s documents.  Maverick and its Notaries rely upon Dealer
        to provide all necessary and completed documents for signature by Dealer’s customer.`}
      </p>
      <p>
        {`In the event that Dealer elects the option to use Maverick’s VerifyID software to assist in the verification of Dealer’s customer’s
        identity, then Maverick will also obtain a digital selfie photograph of the Dealer’s customer and customer’s picture ID. `}
      </p>
      <p>
        {`Maverick provides no other services, unless other tasks are requested by Dealer and Maverick has agreed to provide those additional
        tasks in writing in advance of the actual signing.`}
      </p>
    </div>

    <div className="terms-title">{`Dealer’s Requirements`}</div>
    <div className="terms-section">
      <p>
        {`Dealer will complete an on-line order form through Maverick’s website or Maverick App and provide a signing location for Dealer’s
        customer.  If Dealer wants a digital photograph for use with Maverick’s VerifyID software application, then Dealer must request that
        option in the on-line order form at extra cost.  Dealer will provide a detailed checklist to Notary along with all required documents
        to be signed and ship any and all documents requested to be signed by Dealer’s customer along with a pre-addressed, pre-paid return
        envelope directly to Notary. Blank documents cannot be notarized. All required lines to be initialed or signed by Dealer’s customer
        must be clearly marked by Dealer either by Dealer highlighting or placing signature marker stickers wherever Dealer requires customer’s
        signatures, initials or notary acknowledgements in accordance with Dealer’s checklist. Dealer shall be responsible that all highlighted
        or marked points on Dealer’s documents match Dealer’s checklist both for autofill and manual in fill. Dealer’s checklist must reflect
        the same information as marked on Dealer’s documents, or missed signatures, initials or notarizations may result. Maverick will not take
        responsibility for Dealer’s incomplete, inconsistent or inaccurate documents or Dealer checklist.`}
      </p>
      <p>
        {`Neither Maverick nor the Notary will review Dealer’s paperwork to determine accuracy, sufficiency or completeness. Maverick takes and
        has no responsibility for any statement made in Dealer’s documents.  Instead, Maverick and its Notaries rely upon Dealer to provide all
        necessary and completed documents ready for signature by Dealer’s customer. Dealer’s failure to provide a written checklist to the notary,
        or clearly indicate all lines to be signed, initialed or notarized on both Dealer’s checklist and Dealer’s documents may result in errors
        which are neither Maverick’s nor the notary’s fault or responsibility.`}
      </p>
      <p>
        {`Dealer has three (3) days from the date Dealer’s documents are deposited for return to Dealer or until the vehicle is delivered,
        whichever occurs first, to inform Maverick in writing of any problems or concerns with the documents returned by Maverick or its notary.`}
      </p>
      <p>
        {`If Dealer fails to timely inform Maverick of any concerns or problems with Dealer’s documents, customer’s identity, or customer’s
        signatures returned by Maverick within this time period, or Dealer delivers the vehicle to its customer before Dealer has fully inspected
        Dealer’s documentation and/or before Dealer has independently verified and/or is satisfied with customer’s identity from the documentation
        returned, Maverick is not responsible or liable for any issue or claim relating to the services provided, the completeness of the documents
        or any loss incurred from the delivery of the vehicle by Dealer and the transaction will automatically be considered final, complete and
        accepted.`}
      </p>
    </div>

    <div className="terms-title">Missing signatures</div>
    <div className="terms-section">
      <p>
        {`If documents are returned to Dealer with missing signatures, please contact your Maverick Scheduler immediately or email Info@mavsign.com.
        Dealer must call Maverick at 877-628-7446 and ask for a scheduling manager or email Maverick at info@mavsign.com prior to delivering the
        vehicle.  Type "Missing Signatures" in the email subject line followed by the customer’s name and/or signing ID. Include a copy of the page(s)
        with the missing signature(s). Any claims of missing signatures, initials, and/or notarizations will go through an expedited review process
        as long as the below steps are followed and Dealer does not deliver vehicle.  A scheduling manager will follow up with Dealer same day so long
        as Dealer’s checklist was completely filled out and all of Dealer’s documents were clearly marked to match the Dealer’s checklist, Maverick
        will send the notary back out to the customer to obtain any missing signature(s), and return the required documents to Dealer within 24-48 hours,
        depending on customer availability and week day scheduling, at Maverick's cost. `}
      </p>
      <p>
        {`Maverick does not take responsibility for missing signatures, initials, or notarizations, unless the Dealer checklist was properly filled out
        and all of Dealer’s documents were clearly marked to match the Dealer checklist.  If errors result from missing or incomplete Dealer documents
        or Dealer checklist, any fees and shipping costs associated with the notary having to return to the customer will be the sole responsibility
        of the Dealer. If Dealer elects to send out documents to customer for correction without allowing Maverick the required review process time,
        or delivers the vehicle before Maverick is provided time to review and obtain corrected documents, the cost will not be reimbursed by Maverick.`}
      </p>
    </div>

    <div className="terms-title">What to do in the event of a missing signature, initial or notarization</div>
    <div className="terms-section">
      <p>
        {`(1) Contact your Maverick Scheduler and provide a claim within 3 business days from return of documents to Dealer; (2) Provide a copy of the
        documents with the missing signatures, initials or notarizations to your order scheduler; (3) Provide a list of any missing signatures, initials,
        and/or notarizations, please have any documents requiring notarization filled out completely; (4) Do not deliver the vehicle until all required
        signatures, initials and notarizations have been obtained and returned to Dealer.`}
      </p>
    </div>

    <div className="terms-title">Change in Signing Location</div>
    <div className="terms-section">
      <p>
        {`Dealer is responsible for providing the signing location on the order when placed with Maverick.  Dealer must specifically advise Maverick in
        writing before or at the time Dealer’s documents are delivered whether Dealer refuses to any change in signing location made by Customer. Unless
        Dealer expressly refuses to a change in signing location by Customer in Dealer’s documents, then Notary will process the signing at the location
        change chosen by the Customer.`}
      </p>
    </div>

    <div className="terms-title">Verification Criteria</div>
    <div className="terms-section">
      <p>
        {`In order to assist in the verification of Dealer’s customer’s identity, Dealer is responsible for informing and requiring Dealer’s customers
        to provide one (1) form of government issued picture identification at the time of signing Dealer’s documents. The government issued picture
        identification must contain a photo of the customer. All documents must be current and unexpired and all documents must be originals. Temporary
        and/or paper licenses (or other limited variations of driver’s licenses) will not be accepted, unless Dealer authorizes Maverick or its notary
        to accept it. These documents are required solely for customer identification purposes. Prior to submitting an order, Dealer shall also obtain
        from Dealer’s customer copies of customer’s identification for examination, including picture identification, to compare with Dealer documents
        when Dealer’s documents are returned to Dealer. Maverick highly recommends Dealer examine the signatures and customer identity documentation
        provided by its customer and make its own verification of identity and determination of authenticity prior to proceeding further in the sales
        process or delivering any vehicle to customer.`}
      </p>
      <p>
        {`Maverick or its Notary will alert Dealer if they suspect Dealer’s customer has failed to provide an acceptable form of identification, has
        provided a form of identification which is suspect, or fails or refuses to provide sufficient proof of identity.  In such an instance, it is
        then Dealer’s decision to accept customer’s identification provided or require other specific identification from Dealer’s customer as may be
        requested by Dealer.  In that event, Notary will comply with Dealer’s requirements and obtain the specific identification documentation
        specified by Dealer from Dealer’s customer.  In the event Dealer’s customer cannot or will not provide such documents as required or requested
        by Dealer, Notary will terminate the signing process and Maverick will cause the paperwork to be returned to Dealer unsigned and uncompleted.
        If Dealer elects the option to use Maverick’s VerifyID software to assist in the verification of the Dealer’s customer’s identity, a link to
        complete Verify ID will be sent to Dealer’s Customer. Once completed the digital photograph provided by customer, and front and back photo of
        customer’s ID will be made available on Maverick’s Website and App for Dealer to review and compare to the ID provided to Dealer by Customer.
        It is strongly recommended that Dealer compare the Verify ID captured pictures and ID with the ID the Dealer collected from their customer.`}
      </p>
      <p>
        {`However, neither Maverick nor the Notary are responsible for detecting acts of fraud on the part of the Dealer or the Dealer’s customer,
        including the use of fake or fraudulent picture, non-picture identification or signature irregularities. Neither Maverick nor its notaries
        make any representation of their ability to identify forged signatures nor do they make any representation or guarantee that the signatures
        provided are authentic. Maverick makes no warranty or claim that the VerifyID software application is foolproof, or is a guaranty of actual
        identity or prevents fraud.  It is a software program that makes comparisons of digital images provided to Maverick by Dealer and the customer
        to assist in the verification of the customer’s identity.  It cannot provide an independent or absolute determination that the biometric
        identifiers provided for comparison prove the customer’s identity or that the biometric identifiers provided are not fraudulent.  VerifyID
        provides an additional level of inspection to assist in the verification of a customer’s identity, but it is not a substitute for accurate
        physical forms of government issued picture identification. Maverick strongly recommends Dealer to require both as an additional safeguard of
        customer identity. `}
      </p>
    </div>

    <div className="terms-title">Dealer Delivery of Vehicle</div>
    <div className="terms-section">
      <p>
        {`Maverick has no responsibility for Dealer’s decision to deliver any vehicle. Maverick strongly recommends against delivery of any vehicle
        where paperwork is incomplete, customer’s identity or verification of customer’s identity is unclear or suspect customer has changed signing
        location or until Dealer has reviewed all returned documents and accepted them as delivered.  Maverick HIGHLY recommends that vehicle(s) NOT
        be delivered until all documents have been signed and returned to Dealer for Dealer’s review and acceptance, customer has not changed signing
        location, Dealer has approved customer’s proof of identity, and three (3) days have expired to allow time for Dealer to inspect and approve
        the returned documents.  Maverick is not responsible in any way for Dealer’s decision to deliver a vehicle prior to return of Dealer’s documentation,
        or upon Dealer’s receipt of Dealer’s documents without Dealer’s inspection, review or acceptance, or Dealer’s failure to independently verify
        customer’s identity from the documents returned to Dealer or customer’s change in signing location.`}
      </p>
    </div>

    <div className="terms-title">Notary Criteria</div>
    <div className="terms-section">
      <p>
        {`The notaries assigned by Maverick will have passed a background check verifying the notary’s social security identification and the notary’s
        criminal history will be requested through a check for federal criminal history on the Nationwide Criminal Database and National Sex Offender
        Registry, the USA Patriot Act, including Terrorist Watch List, and by State Motor Vehicle Report. `}
      </p>
      <p>
        {`Notaries will carry all necessary insurance, bonds, and current commission as required by each respective individual state requirements.`}
      </p>
    </div>

    <div className="terms-title">Performance</div>
    <div className="terms-section">
      <p>
        {`Maverick agrees to perform its services in a good and professional manner. Maverick will notify Dealer immediately if any Notary suspects
        fraud or identity theft or if Dealer’s customer refuses to provide the identification required or requested. `}
      </p>
      <p>
        {`While Maverick shall exercise due diligence and prudence in carrying through the document signing process, using the identification criteria
        and procedures stated above, neither Maverick nor its notaries have any duty to review Dealer’s documents for accuracy or truth of representation
        or assess any transaction for possible fraud or other crime by Dealer’s customer.  Maverick’s notaries are not fraud investigators or professional
        law enforcement officers.`}
      </p>
      <p>
        {`EXCEPT AS STATED, MAVERICK EXPRESSLY DISCLAIMS ANY REPRESENTATION, WARRANTY OR SERVICE LEVEL, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
        BUT NOT LIMITED TO, ANY WARRANTIES, DUTIES OR CONDITIONS OF OR RELATED TO MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.`}
      </p>
    </div>

    <div className="terms-title">Fee Schedule</div>
    <div className="terms-section">
      <p>
        {`Maverick will provide a written schedule of fees and all services will be charged by Maverick and paid for by Dealer in accordance with the most
        current fee schedule provided by Maverick.`}
      </p>
    </div>

    <div className="terms-title">Payment</div>
    <div className="terms-section">
      <p>
        {`Dealers agree to pay for all services ordered or provided as set forth in Maverick’s most current fee schedule at the time the services are provided
        regardless of funding outcome, customer willingness, or other unforeseen events that are not Maverick’s fault.  Applicable fees will be payable to Maverick
        upon email receipt of invoice and are due and payable and will be paid Net 30 from the day the invoice is received by Dealer. Dealers may withhold any fees
        it disputes in good faith, but shall be required to pay all undisputed fees. Dealer will provide and maintain an invoice billing email address and will notify
        Maverick of any changes in address so that there is no delay in payment.  All billing inquiries or changes to billing address should be sent to billing@mavsign.com.
        All payments due to Maverick shall be paid in US currency. `}
      </p>
    </div>

    <div className="terms-title">Payment Penalties</div>
    <div className="terms-section">
      <p>
        {`A late payment penalty of 5% per month will be assessed on outstanding balances of 15 days or more past due and will be charged if the Dealer does not comply with
        the rates, amounts or dates of pay as set forth in these terms and conditions.`}
      </p>
    </div>

    <div className="terms-title">Shipping Costs for Documents</div>
    <div className="terms-section">
      <p>
        {`All shipping costs are the responsibility of the assigning Dealer.`}
      </p>
    </div>

    <div className="terms-title">Independent Contractor</div>
    <div className="terms-section">
      <p>
        {`It is expressly agreed that Maverick is acting as an independent contractor and not as an employee or agent of Dealer in providing its services to Dealer.
        Dealer and Maverick also agree that these services in no way create any partnership or joint venture between them, and Maverick is merely facilitating the
        signing of Dealer’s documents for Dealers and their customers’ convenience.`}
      </p>
    </div>

    <div className="terms-title">{`Dealer’s Responsibilities`}</div>
    <div className="terms-section">
      <p>
        {`Dealer is responsible for independently verifying the veracity of customer’s identity consistent with automotive management practices. Maverick Services is
        one part of Dealer’s responsibility to detect red flags and is not a substitute for Dealer’s responsibility to maintain an Identity Theft Prevention Program
        (ITPP) under the Fair Credit Reporting Act. Please refer to Dealer’s ITPP or the FTC’s Red Flag Rules for vehicle dealership compliance.  Dealer is responsible
        for responding in accordance with its ITPP after it has identified and detected a red flag.`}
      </p>
      <p>
        {`Dealer’s ITPP must identify, detect, and respond to red flags outside the scope of the Services Provided. Red flag indicates the possible existence of identity
        theft. Where Dealer is responsible for, but not limited to: performing their own fraud alert in a consumer report; taking notice of security freezes in a consumer
        report; taking notice of address discrepancies in a consumer report; taking notice of unusual credit activity in a consumer report; inspecting
        customer documents for alteration or forgery; taking notice of customer’s inconsistent photographic or physical descriptions; taking notice of
        inconsistent information provided by a customer against a consumer report; taking notice of inconsistent signatures on customer’s personally
        identifiable information; taking notice of social security numbers that are inconsistent with a consumer report; taking notice of inconsistent
        social security number ranges with customer’s date of birth; taking notice of invalid phone numbers, emails, or addresses; taking notice of
        customer’s personal identifying information that is the same, or unusually similar to an existing customer’s personal identifying information;
        in addition to using the Services Provided; are considered the circumstances surrounding a customer transaction that Dealer should consider as
        red flags.`}
      </p>
    </div>

    <div className="terms-title">{`Data Security and Compliance with the Gramm-Leach-Bliley Act's Safeguards Rule`}</div>
    <div className="terms-section">
      <p>
        {`The “Safeguards Rule” of the Gramm-Leach-Bliley Act (“GLBA”) requires Dealer, as a financial institution, to (1) take reasonable steps to select
        and retain service providers that are capable of maintaining appropriate safeguards for customer information, (2) require Service Provider by
        contract to implement and maintain such safeguards, and (3) periodically assess Service Provider’s continued adequacy of such safeguards based
        on the risks presented.`}
      </p>
    </div>

    <div className="terms-title">Commitment to Safeguards</div>
    <div className="terms-section">
      <p>
        {`The Service Provider acknowledges that certain Dealers may be obligated under the GLBA to implement and maintain reasonable security procedures
        and practices to protect customer information. This commitment to safeguards is only applicable to those Dealers that are obligated to hold
        Service Providers to such reasonable security standards as defined under the GLBA. In light of the foregoing, Service Provider agrees to
        implement and maintain reasonable security measures that are sufficient to meet the Dealer's obligations under the Safeguards Rule.`}
      </p>
    </div>

    <div className="terms-title">Scope of Safeguards</div>
    <div className="terms-section">
      <p>
        {`The Service Provider shall take reasonable steps to protect customer information from unauthorized access, disclosure, alteration,
        or destruction. The Dealer recognizes that the reasonable steps standard allows for flexibility and should be tailored to the Service
        Provider’s size, scope of services, and the nature of the data involved.`}
      </p>
    </div>

    <div className="terms-title">Assessment and Audits</div>
    <div className="terms-section">
      <p>
        {`Dealer may assess the adequacy of the Service Provider’s safeguards periodically provided that Dealer agrees that such assessments
        will not be unduly intrusive or disruptive to the Service Provider's normal operations, will take place during normal business hours,
        will relate solely to the scope of the reasonable security measures required under the Safeguards Rule and relating only to information
        shared with Service Provider by Dealer that was not previously known or held by Service Provider, and Dealer shall provide at least ten
        (10) days’ notice prior to conducting any such audit of Service Provider’s reasonable security measures.`}
      </p>
    </div>

    <div className="terms-title">Mutual Cooperation</div>
    <div className="terms-section">
      <p>
        {`Both parties agree to cooperate fully in good faith to resolve any issues or concerns related to data security and compliance with
        the Safeguards Rule, including the timely sharing of relevant information and documentation.`}
      </p>
    </div>

    <div className="terms-title">Liability</div>
    <div className="terms-section">
      <p>
        {`In no event shall Maverick’s maximum liability for any claim asserted against it and arising out of any particular circumstance
        exceed the amount actually paid to Maverick for the services provided to Dealer from the circumstance in which the claim arose.`}
      </p>
    </div>

    <div className="terms-title">Confidentiality</div>
    <div className="terms-section">
      <p>
        {`Both Dealer and Maverick acknowledge that the documents and paperwork provided to Maverick and returned by Maverick to the Dealer
        contain Dealer’s customer’s private personal and financial information, may include biometric identification from Dealer’s customer,
        or other confidential and proprietary information regarding Dealer’s business.  Maverick shall not retain any copies of the Dealership
        documents and shall not disclose the Dealer’s documents or any of the information obtained by Maverick or its notaries during the course
        of the signing process to any third parties except upon written consent of Dealer or as otherwise permitted by Dealer’s customer as it
        relates to the customer’s personal information and biometric identification.  Dealer warrants to Maverick that it shall also maintain the
        customer’s privacy in the customer’s personal financial information and shall comply with any and all applicable privacy laws and policies
        regarding the collection and storage of private personal information and all biometric identifiers Dealer receives from Maverick or its
        Notary during the signing process. `}
      </p>
    </div>

    <div className="terms-title">Extent of Electronically Stored Private Information</div>
    <div className="terms-section">
      <p>
        {`The duration of electronically stored private information of Dealer’s customer is 90 days from this agreement’s effective date.
        Electronically stored information includes information related to Dealer’s customer’s signing that may, or may not, be related to Dealer’s
        customer’s private personal information or biometric identifiers that Maverick receives through Maverick’s website, Maverick App, from
        Dealer’s customer, or Notary during the signing process.  Dealer will no longer have access to Dealer customer’s electronically stored private
        personal information after 90 days through Maverick’s website or Maverick App. After 90 days Maverick will archive customer’s electronically
        stored private personal information and Dealer may request customer’s private personal information or signing information by contacting
        Dealer’s Maverick Scheduler or email Info@mavsign.com within 365 days of this agreement’s effective date.  Type "Signing Information Request"
        in the email subject line followed by the customer’s name and/or signing ID and include a reason for making the information request. After 120
        days Maverick will delete, purge, or otherwise no longer retain images captured with VerifyID. After 365 days Maverick will delete, purge, or
        otherwise no longer retain all of customers’ private personal information and other electronically stored information.  Maverick will not
        provide Dealer prior notice to deleting customer’s electronically stored information, nor will Maverick provide Dealer notice of the length of
        time remaining for Dealer to access customer’s electronically stored private personal information through Maverick’s archive, Maverick’s
        website or Maverick App. `}
      </p>
      <p>
        {`Dealer is responsible for independently maintaining an information security program consistent with automotive management practices.
        Maverick Services is one part of Dealer’s responsibility to protect the private personal information of Dealer’s customer and is not a
        substitute for Dealer’s responsibility to maintain a data security plan under the Gramm-Leach-Bliley Act, the Fair Credit Reporting Act,
        and the Federal Trade Commission Act. Please refer to Dealer’s information security program or the FTC’s Safeguard Rules for vehicle
        dealership compliance.`}
      </p>
    </div>

    <div className="terms-title">Extent of Terms and Conditions</div>
    <div className="terms-section">
      <p>
        {`In the event a written service agreement already exists between Maverick and the Dealer, then these Terms and Conditions are intended
        to complement and amplify the terms of that agreement and to the extent that the terms or provisions are inconsistent between these two
        documents then the terms and conditions provided for here are intended to supersede the provisions of any written service agreement.
        Otherwise, there are no other agreements, representations or warranties, express or implied, made by Maverick altering, modifying or adding
        to the foregoing terms and conditions that are not expressly set forth in these Terms and Conditions.  `}
      </p>
    </div>

    <div className="terms-title">Governing Law</div>
    <div className="terms-section">
      <p>
        {`The performance of the services requested by Dealer and all suits or special proceedings brought arising out of or related to the
        services provided by Maverick shall be construed in accordance with and governed by the laws of the State of California.  The sole
        and exclusive venue for any dispute arising out of or related to the services provided by Maverick shall be in the state and federal
        courts located in and for Orange County, California and Dealer hereby irrevocably consents to the jurisdiction of said courts.
        The prevailing party in any court proceeding or arbitration hereunder shall be entitled to recover their reasonable attorney’s fees.`}
      </p>
    </div>
  </React.Fragment>
}

export default TCSection;