import React from "react";
import User from 'model/user';
import { Store } from 'react-notifications-component';
import moment from "moment-timezone";
import config from 'config';
import UserRole from 'model/userRole';
import randomBytes from 'randombytes';
import DOMPurify from 'dompurify';
import regx from 'constants/regx';
import { UncontrolledTooltip } from "reactstrap";

export const sleep = time => new Promise((resolve) => setTimeout(resolve, time));

export const getSharedPaginationOptions = () => ({
  custom: true,
  hidePageListOnlyOnePage: true,
  paginationSize: 10,
  sizePerPageList: [
    { text: '1', value: 1 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '200', value: 200 },
  ],
});

export const getSharedTableOptions = () => ({
  keyField: "id",
  bordered: false,
  striped: false,
  defaultSortDirection: "asc",
  selectRow: {
    mode: 'radio',
    hideSelectColumn: true,
  },
  classes: "table align-middle table-nowrap",
  headerWrapperClasses: "thead-light",
  responsive: true,
  remote: true,
});

export const valueIsDefined = value => value !== undefined && value !== null;

export const valueIsEmpty = value => {
  if (typeof value === 'string') {
    value = value.trim();
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  // allow 0 and FALSE
  return value !== 0 && value !== false && !value;
}

export const mapNonEmpty = obj => Object.entries(obj).reduce((a, [k, v]) => (!valueIsEmpty(v) ? (a[k] = v, a) : a), {});

export const hasNonEmpty = obj => Object.keys(mapNonEmpty(obj)).length > 0;

// axios serializes only plain objects
// so any nested objects will end up as json
// but we need the filters as a query string array
// so here we do the transformation
export const flattenFilters = params => {
  // clone the object to avoid changing the original
  const newParams = { ...params };
  if (newParams.filters) {
    // loop through all filters
    for (const [key, value] of Object.entries(newParams.filters)) {
      // if the filter value is not empty
      if (!valueIsEmpty(value)) {
        // add the filter under the root object
        // change the key so it will be decoded properly on the backend
        newParams[`filters[${key}]`] = value;
      }
    }
    // remove the filters key since it is no longer needed
    delete newParams.filters;
  }
  return newParams;
}

// the Select control expects a list of {value, label} objects as options
// most of the time api response is different than what the Select control expects
// so here we do the transformation
export const toSelectOptions = (list, valueProp = 'id', labelProp = 'name') => {
  return list.map(item => ({
    label: item[labelProp],
    value: item[valueProp],
  }));
}

export const getYesNoOptions = (includeAll = false) => {
  const options = [{
    label: "Yes",
    value: 1,
  }, {
    label: "No",
    value: 0
  }];
  if (includeAll) {
    options.unshift({
      label: "All",
      value: '',
    });
  }
  return options;
}

export const distanceServedOptions = [
  {
    value: 10,
    label: "10 miles radius"
  },
  {
    value: 20,
    label: "20 miles radius"
  },
  {
    value: 30,
    label: "30 miles radius"
  },
  {
    value: 40,
    label: "40 miles radius"
  },
  {
    value: 50,
    label: "50 miles radius"
  },
];

export const isObject = obj => typeof obj === 'object' && obj !== null;

export const showNotification = (type, message, options) => {
  const defaultOptions = {
    title: '',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 6000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
    width: 400,
  }
  return Store.addNotification({
    ...defaultOptions,
    ...options,
    type: type,
    message: message,
  });
}

export const showBriefNotification = (type, message, options) => {
  const defaultOptions = {
    title: '',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
    width: 400,
  }
  return Store.addNotification({
    ...defaultOptions,
    ...options,
    type: type,
    message: message,
  });
}

export const showSuccess = (message, options) => showNotification('success', message, options);

export const showBriefSuccess = (message, options) => showBriefNotification('success', message, options);

export const showMessage = (message, options) => showNotification('info', message, options);

export const showWarning = (message, options) => showNotification('warning', message, options);

export const showError = (message, options) => showNotification('danger', message, options);

export const showBriefError = (message, options) => showBriefNotification('danger', message, options);

export const removeNotification = id => Store.removeNotification(id);

export const trim = (str, char) => {
  char = char.replace(/[-/^$*+?.()|[]{}]/g, '$&');
  return str.replace(new RegExp(
    "^[" + char + "]+|[" + char + "]+$", "g"
  ), "");
}

export const ltrim = (s, c) => {
  c = c.replace(/[-/\^$*+?.()|[]{}]/g, '\$&');
  return s.replace(new RegExp(
    "^[" + c + "]+", "g"
  ), "");
}

export const rtrim = (s, c) => {
  c = c.replace(/[-/\^$*+?.()|[]{}]/g, '\$&');
  return s.replace(new RegExp(
    "[" + c + "]+$", "g"
  ), "");
}

export const buildUrl = (base, fragment) => rtrim(base, '/') + (fragment ? '/' + ltrim(fragment, '/') : '');

export const getBeUrl = fragment => buildUrl(config.API_BE_URL, fragment);

export const getSsoAppUrl = fragment => buildUrl(config.SSO_URL, fragment);

export const getAdminAppUrl = fragment => buildUrl(config.ADMIN_URL, fragment);

export const getDealerAppUrl = fragment => buildUrl(config.DEALER_URL, fragment);

export const nullsToEmptyStrings = obj => {
  if (!obj) {
    return;
  }
  if (Array.isArray(obj)) {
    return obj.map(v => v === null ? '' : v);
  } else {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, v === null ? '' : v]));
  }
}

export const isNotAllOrNone = value => value != '' && value != -1;

export const toUserModel = user => Object.assign(new User(), user);

export const getTimezoneOptions = () => moment.tz.zonesForCountry('US').map(zone => ({
  label: `${zone} (${moment.tz(zone).zoneAbbr()})`,
  value: zone,
}));

export const getUserOwner = user => {
  const owner = {
    name: null,
    id: null,
    route: null,
    type: null,
  };
  if (UserRole.isAdminType(user.userRoleId)) {
    owner.name = config.APP_TITLE;
    owner.type = 'mav';
  } else if (UserRole.isDealerGroupManager(user.userRoleId)) {
    owner.name = user.dealerGroupName;
    owner.id = user.dealerGroupId;
    owner.route = 'view_dealer_group';
    owner.type = 'dealerGroup';
  } else if (UserRole.isDealerRegionalManager(user.userRoleId)) {
    owner.name = user.dealerRegionName;
    owner.id = user.dealerRegionId;
    owner.route = 'view_dealer_region';
    owner.type = 'dealerRegion';
    owner.parentName = user.dealerGroupName;
    owner.parentId = user.dealerGroupId;
    owner.parentRoute = 'view_dealer_group';
  } else if (UserRole.isDealerType(user.userRoleId)) {
    owner.name = user.dealerStoreName;
    owner.id = user.dealerStoreId;
    owner.route = 'view_dealer_store';
    owner.type = 'dealerStore';
  }
  return owner;
}

export const withAuth = fragment => buildUrl(config.AUTH_PATH_PREFIX, fragment);

export const randomString = len => new Promise((resolve, reject) => {
  randomBytes(len, function (ex, buffer) {
    if (ex) {
      reject(null);
    }
    resolve(buffer.toString('hex'));
  });
});

export const randomStringSync = len => randomBytes(len).toString('hex');

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const dictionaryToSelectOptions = dict => Object.entries(dict).map(entry => ({ label: entry[1], value: entry[0] }));

export const formatPhone = phone => {
  if (!phone) {
    return;
  }
  const clean = phone.replace(/\D/g, '');
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) {
    return clean;
  }
  let result = '';
  if (!!match[1]) {
    result += match[1];
  }
  if (!!match[2]) {
    result += '-' + match[2];
  }
  if (!!match[3]) {
    result += '-' + match[3];
  }
  return result;
}

export const openInNewWindow = url => {
  // Safari is blocking any call to open a new window which is made inside an async call, treating it as a pop-up.
  // setTimeout code runs on the main thread, instead of the asynchronous one
  setTimeout(() => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.click();
  })
}

export const sanitizeHtml = html => DOMPurify.sanitize(html);

export const stripTags = value => value.replace(/(<([^>]+)>)/gi, "");

export const getInitialsFromName = name => name.match(/\b(\w)/g).join('');

export const filterObjectKeys = (obj, keys) => {
  if (!obj) {
    return null;
  }
  return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));
}

export const extractDtFiltersFromUrl = () => {
  const query = new URLSearchParams(window.location.search);
  const filters = {};
  for (const [key, value] of query.entries()) {
    const match = key.match(/filters\[(.+)\]/);
    if (!!match && !!match[1]) {
      filters[match[1]] = value;
    }
  }
  return filters;
}

export const removeDtFiltersFromUrl = () => {
  const query = new URLSearchParams(window.location.search);
  const filters = {};
  for (const [key, value] of query.entries()) {
    const match = key.match(/filters\[(.+)\]/);
    if (!match) {
      filters[key] = value;
    }
  }
  return new URLSearchParams(filters).toString();
}

export const getMiles = meters => (meters * 0.000621371192).toFixed(2);

export const getAddressComponents = place => {
  const addressComponents = {
    address: "",
    city: "",
    state: "",
    zip: "",
  };

  // get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components) {

    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        addressComponents.address = `${component.long_name} ${addressComponents.address}`;
        break;
      }

      case "route": {
        addressComponents.address += component.long_name;
        break;
      }

      case "postal_code": {
        addressComponents.zip = component.long_name
        break;
      }

      case "locality":
        addressComponents.city = component.long_name;
        break;

      case "administrative_area_level_1": {
        addressComponents.state = component.short_name
        break;
      }
    }
  }

  return addressComponents;
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const bytesToSize = bytes => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export const decimalToDMS = (decimal, isLatitude) => {
  const degrees = Math.floor(Math.abs(decimal));
  const decimalMinutes = (Math.abs(decimal) - degrees) * 60;
  const minutes = Math.floor(decimalMinutes);
  const seconds = (decimalMinutes - minutes) * 60;

  let direction = "";
  if (isLatitude) {
    direction = decimal >= 0 ? 'N' : 'S';
  } else {
    direction = decimal >= 0 ? 'E' : 'W';
  }

  return `${degrees}° ${minutes}' ${seconds.toFixed(2)}" ${direction}`;
}

export const newLineTextFormat = (lines) => {
  // Add each line of text on new line
  // and add bullet symbol at the beginning
  let formattedText = lines?.map(function (line) {
    return '\u2022' + ' ' + line;
  });
  return formattedText;
}

export const phoneHasNoOfDigits = (value) => {
  return !!value && value.replace(regx.phoneChars, '').length == config.PHONE_NR_OF_DIGITS;
}

export const getDtFooterRowCount = (paginationProps, numOfVisibleRows, totalNumOfRows) => {
  const startNum = ((paginationProps.page - 1) * paginationProps.sizePerPage) + 1;
  const endNum = ((paginationProps.page - 1) * paginationProps.sizePerPage) + numOfVisibleRows;
  return <>
    Showing <span id="dt-rowcount">{numOfVisibleRows}</span> of {totalNumOfRows} items
    <UncontrolledTooltip placement="top" target="dt-rowcount">{startNum}-{endNum}</UncontrolledTooltip>
  </>;
}

export const getAoiUrl = (isThumbprintRequired) => {
  if(isThumbprintRequired) return getBeUrl(`notary/std-doc/aoi.pdf`);

  return getBeUrl(`notary/std-doc/aoi_no_fingerprint.pdf`);
}

export const getBounceReason = (reason) => {
  switch (reason) {
    case User.EMAIL_ALERT_GENERAL:
      return <span>It seems the email address associated with your account does not exist. Please update it in your profile.</span>;
    case User.EMAIL_ALERT_MAIL_BOX_FULL:
      return <span>It seems your inbox is full and cannot receive any more emails. Please clear it out.</span>;
    case User.EMAIL_ALERT_ON_ACCOUNT_SUPPRESION_LIST:
      return <span>It seems the Mavsign email address is blocked/marked as spam by you. Please adjust that in your email platform.</span>;
    default:
      return <span>There seems to be an unknown issue with your email. Please check your email settings or contact support.</span>;
  }
};

export const checkReasonType = (user) => {
  // check if the user email has bounceSubtype or complaintSubtype
  // based on this, display the according email bounce reason message from above
  const subtype = user.bounceSubtype || user.complaintSubtype;
  return getBounceReason(subtype);
}