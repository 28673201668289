import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import DocumentCard from "../Components/document-card";

const DownloadDocuments = () => {

  const documentsData = [{
    id: 1,
    title: 'Download AOI',
    url: 'notary/std-doc/aoi.pdf',
    description: 'Test description Download AOI'
  }, {
    id: 2,
    title: 'Download AOI - No Fingerprint',
    url: 'notary/std-doc/aoi_no_fingerprint.pdf',
    description: 'Test description Download AOI'
  },
  {
    id: 3,
    title: 'Download Notary checklist',
    url: 'notary/std-doc/notary-checklist.pdf',
    description: 'Test description Download Notary checklist'
  },
  {
    id: 4,
    title: 'Download Dealer checklist',
    url: 'notary/std-doc/dealer-checklist.pdf',
    description: 'Test description Download Dealer checklist'
  }];

  return (<Card>
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Download Templates</CardTitle>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Row className='justify-content-center'>
        {documentsData.map(item => <Col key={item.id} xs={12} xl={3} className="mb-4 mb-xl-0"><DocumentCard title={item.title} docUrl={item.url} description={item.description} /></Col>)}
      </Row>
    </CardBody>
  </Card>)
}

export default DownloadDocuments;