import React from "react";
import PropTypes from "prop-types";

const Instructions = ({dealerInstructions}) => {
  return <React.Fragment>
    {dealerInstructions && (
      <>
        <h6>{"Dealer's instructions:"}</h6>
        <ul>
          <li>
            {dealerInstructions}
          </li>
        </ul>

        <div className="divider-faded divider-sm" />
      </>
    )}
    <h6>Instructions</h6>
    <ul>
      <li>
        {"Prepare the 2 copies of the Affidavit of ID according to these documents."}
      </li>
      <li>
        {"Read over and sign the Dealer Offsite Checklist, if included with docs."}
      </li>
      <li>
        {"Briefly review the documents for any issues."}
      </li>
    </ul>

    <div className="divider-faded divider-sm" />

    <h6>Download and Print Documents</h6>
    <ul>
      <li>Download the documents below to print.</li>
      <li>Please make sure you take the documents with you after printing.</li>
    </ul>
    <div className="divider-faded divider-sm" />
  </React.Fragment>
}

Instructions.propTypes = {
  dealerInstructions: PropTypes.string,
};

export default Instructions;