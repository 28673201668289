import React from 'react';
import { useAuth } from './auth';
import { getAdminAppUrl, getDealerAppUrl } from 'helpers/utilHelper';
import Message from 'model/message';

export const perms = {
  view_notaries: 'view_notaries',
  edit_notaries: 'edit_notaries',
  view_notary_bids: 'view_notary_bids',
  view_orders: 'view_orders',
  view_message_channel: 'view_message_channel',
  view_statements: 'view_statements',
  list_notifications: 'list_notification',
  view_notification: 'view_notification',
  view_terms: 'view_terms',
  view_messages: 'view_messages',
};

const AccessContext = React.createContext();

const AccessProvider = props => {

  const { user: authUser } = useAuth();

  const iAmAdminType = () => authUser && authUser.isAdminType();
  const iAmDealerType = () => authUser && authUser.isDealerType();
  const iAmNotaryType = () => authUser && authUser.isNotaryType();

  const iAmGranted = (attribute, subject) => {
    switch (attribute) {
      case perms.view_notaries:
      case perms.edit_notaries:
      case perms.view_notary_bids:
      case perms.view_orders:
      case perms.view_statements:
      case perms.view_terms:
      case perms.view_messages:
        return true;
      case perms.view_message_channel:
        switch (parseInt(subject)) {
          case Message.CHANNEL_NOTARY_CUSTOMER:
          case Message.CHANNEL_SCHEDULER_NOTARY:
            return true;
        }
        return false;
      default:
        return false;
    }
  }

  const iAmNotGranted = (attribute, subject) => !iAmGranted(attribute, subject);

  const iAmGrantedAny = (attributes, subject) => attributes.some(attr => iAmGranted(attr, subject));

  const getUserAppUrl = fragment => {
    if (iAmAdminType()) {
      return getAdminAppUrl(fragment);
    } else if (iAmDealerType()) {
      return getDealerAppUrl(fragment);
    }
  }

  const contextValue = {
    iAmGranted,
    iAmNotGranted,
    iAmGrantedAny,
    iAmNotaryType,
    getUserAppUrl,
  };

  return <AccessContext.Provider value={contextValue} {...props} />
}

// helper hook that makes context data available
export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;