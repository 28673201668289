import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import { getBeUrl, getAoiUrl } from "helpers/utilHelper";
import downloadDoc from "assets/images/download-doc.png";
import notarizedIcon from "assets/images/notarized-badge.svg";

const TemplateSection = ({ order,
  downloadBtnClassName,
  notaryChecklistDoc,
  aoiDoc,
  isDeliveryOptionUploaded,
  isDeliveryOptionShipping }) => {

  return <>
    <div className="divider-faded divider-sm" />
    <h6 className="mt-3 mb-3">Templates</h6>
    <div>
      <a href={getAoiUrl(order.isAoiThumbprintRequired)} rel="noreferrer"
        className={downloadBtnClassName}>
        <div className="d-flex align-items-center">
          <Col sm='2'><img src={downloadDoc} alt="download-document" /></Col>
          <Col sm='9'>
            <div className='d-flex align-items-center'>
              <span className="text-start fw-medium font-size-14">AOI</span>
              {aoiDoc && <span className="notarized-badge ms-3">
                to be notarized <img src={notarizedIcon} className='ms-2' alt='notarized-icon' />
              </span>}
            </div>
            <div className='document-download-info'>
              {aoiDoc && !!aoiDoc[0]?.signingInstructions && <><h6 className='instructions-title'>Signing
                instructions</h6>
                <div className='instructions-description'>{aoiDoc && aoiDoc[0]?.signingInstructions}</div>
              </>}
              <div className="divider-faded divider-sm my-2" />
            </div>
          </Col>
          {(isDeliveryOptionUploaded || isDeliveryOptionShipping) && <Col sm='1'>
            <i className="bx bx-download font-size-20" />
          </Col>}
        </div>
      </a>
    </div>
    <div>
      <a href={getBeUrl(`notary/std-doc/notary-checklist.pdf`)} rel="noreferrer"
        className={downloadBtnClassName}>
        <div className="d-flex align-items-center">
          <Col sm='2'><img src={downloadDoc} alt="download-document" /></Col>
          <Col sm='9'>
            <div className='d-flex align-items-center'>
              <span className="text-start fw-medium font-size-14">Notary Checklist</span>
              {notaryChecklistDoc && !!notaryChecklistDoc[0]?.isNotarizationRequired &&
                <span className="notarized-badge ms-3"></span>}
              <div className='document-download-info'>
                {aoiDoc && !!aoiDoc[0]?.signingInstructions && <><h6 className='instructions-title'>Signing
                  instructions</h6>
                  <div className='instructions-description'>{aoiDoc && aoiDoc[0]?.signingInstructions}</div>
                </>}
                <div className="divider-faded divider-sm my-2" />
              </div>
            </div>
          </Col>
          {(isDeliveryOptionUploaded || isDeliveryOptionShipping) && <Col sm='1'>
            <i className="bx bx-download font-size-20" />
          </Col>}
        </div>
      </a>
    </div>
    <div className="divider-faded divider-sm" />
  </>
}


TemplateSection.propTypes = {
  order: PropTypes.object,
  downloadBtnClassName: PropTypes.string,
  notaryChecklistDoc: PropTypes.array,
  aoiDoc: PropTypes.array,
  isDeliveryOptionUploaded: PropTypes.bool,
  isDeliveryOptionShipping: PropTypes.bool,
};

export default TemplateSection;