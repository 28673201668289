import React from "react";
import { Row } from "reactstrap";
import Order from "model/order";
import { formatTimestamp, formats } from "helpers/dateHelper";
import addressIcon from "assets/images/blue-address-icon.svg";
import trackingIcon from "assets/images/blue-tracking-number-icon.svg";
import shipperIcon from "assets/images/blue-shipper-box-icon.svg";
import documentsIcon from "assets/images/blue-documents-icon.svg";
import contactIcon from "assets/images/contact-icon.svg";
import classnames from "classnames";
import { route, routes } from "helpers/routeHelper";
import Message from "model/message";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const StepDocsDeliveredDesktop = ({ id, notary, order, isDeliveryOptionUpload, isNotCurrentStep, handleSetShippingModal }) => {

  return (
    <React.Fragment>
      <Row>
        <div className="order-info text-center">
          <div className="order-info-title mb-3">
            <img className="me-2" src={addressIcon} />
            <span>{"Notary's Address"}</span>
          </div>
          <div className="order-info-subtitle mb-1">{notary.shippingAddress || '--'}</div>
          <div className="order-info-subtitle mb-1">City: {notary.shippingCity || '--'}</div>
          <div className="order-info-subtitle mb-1">Zip code: {notary.shippingZip || '--'}</div>
          <div className="divider-faded my-3" />
        </div>

        {!isDeliveryOptionUpload && <>
          <div className="order-info text-center">
            <div className="order-info-title mb-3">
              <img className="me-2" src={trackingIcon} />
              <span>Tracking number</span>
            </div>
            <div onClick={handleSetShippingModal}
              className={classnames("cursor-pointer font-size-11 ms-2 mt-2", { "tracking-number": !!order.shippingPackageAwb })}>
              <span className='package-number'>{order.shippingPackageAwb || '--'}</span>
            </div>
            <div className="divider-faded my-3" />
          </div>

          <div className="order-info text-center">
            <div className="order-info-title mb-3">
              <img className="me-2" src={shipperIcon} />
              <span>Shipper</span>
            </div>
            <div className="order-info-subtitle mb-1">{Order.getShippingCompanyName(order.shippingCompany) || '--'}</div>
            <div className="divider-faded my-3" />
          </div>

          <div className="order-info text-center">
            <div className="order-info-title mb-3">
              <img className="me-2" src={documentsIcon} />
              <span>Expected Documents Receival Date</span>
            </div>
            <div className="order-info-subtitle mb-1">{formatTimestamp((order.docsSentToNotaryTs + 24 * 60 * 60), formats.FULL_MONTH_DATE) || '--'}</div>
            <div className="divider-faded my-3" />
          </div>
        </>}
      </Row>

      {!isDeliveryOptionUpload && <><Link
        to={route(routes.view_order_messages, [id, Message.CHANNEL_SCHEDULER_NOTARY])}
        className={classnames("bordred-btn mb-2 font-size-13 text-dark fw-medium d-flex align-items-center justify-content-center", { "pe-none": isNotCurrentStep })}
      >
        <img src={contactIcon} alt="contact-icon" className="rounded-circle me-3" />
        I Did Not Receive The Documents Yet
      </Link>
      </>}
    </React.Fragment>
  )
};

StepDocsDeliveredDesktop.propTypes = {
  id: PropTypes.number,
  notary: PropTypes.object,
  order: PropTypes.object,
  isDeliveryOptionUpload: PropTypes.bool,
  isNotCurrentStep: PropTypes.bool,
  handleSetShippingModal: PropTypes.func,
};

export default StepDocsDeliveredDesktop;