import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  GET_NOTARY_BID,
  GET_NOTARY_BID_LIST
} from "./actionTypes";

import {
  getNotaryBidListOk,
  getNotaryBidListErr,

  getNotaryBidOk,
  getNotaryBidErr,
} from "./actions";

import {
  getMyNotaryBids,
  getNotaryBid
} from "helpers/backendHelper";

import NotaryBid from "model/notaryBid";

const getFilters = status => {
  switch (status) {
    case NotaryBid.VIRTUAL_STATUS_ACTIVE:
      return { isActive: 1 };
    case NotaryBid.VIRTUAL_STATUS_ALL:
      return {};
  }
};

/********** LIST **********/

function* onGetNotaryBidList({ payload: { params } }) {
  try {
    const filterToggle = yield select(state => state.NotaryBid.List.filterToggle);
    const response = yield call(getMyNotaryBids, { ...params, filters: getFilters(filterToggle) });
    yield put(getNotaryBidListOk(response));
  } catch (error) {
    yield put(getNotaryBidListErr(error));
  }
}

/********** SINGLE **********/

function* onGetNotaryBid({ payload: { id } }) {
  try {
    const response = yield call(getNotaryBid, id);
    yield put(getNotaryBidOk(response));
  } catch (error) {
    yield put(getNotaryBidErr(error));
  }
}

function* notaryBidSaga() {
  yield takeEvery(GET_NOTARY_BID_LIST, onGetNotaryBidList);
  yield takeEvery(GET_NOTARY_BID, onGetNotaryBid);
}

export default notaryBidSaga;


