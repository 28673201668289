import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useProfile } from 'context/profile';

const useProfileBillingNotComplete = () => {

  const navigate = useNavigate();

  const { notary } = useProfile();

  useEffect(() => {
    // if the user did not complete the billing step
    if (!notary.billingAddress) {
      // redirect back to the billing step
      navigate(route(routes.setup_profile_billing));
      return;
    }
  }, []);
}

export default useProfileBillingNotComplete;