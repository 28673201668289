import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from "prop-types";

const YoutubeVideoModal = ({isOpen, closeModal}) => {
  return(<Modal
    isOpen={isOpen}
    centered
    className="modal-size my-10"
    size="xl"
    >
      <div className="modal-header modal-header-dashboard">
        <button type="button" onClick={closeModal} className="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body text-center modal-body-dashboard">
        <p>Welcome to Mavsign 2.0! 🎉 </p>
        <span>We are happy to announce the release of our new platform Mavsign 2.0</span>
        <br />
        <span>Watch the video below to discover the key features.</span>
        <div>
          <iframe className="player" width="100%" height="450" src="https://www.youtube.com/embed/PbHmV0z6I64?si=G2WJR2D1dm_xWgzs&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      </div>
    </Modal>
  )
}

YoutubeVideoModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default YoutubeVideoModal;