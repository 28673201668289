import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import CardHeaderDesktop from "pages/Order/Partial/OrderCard/Desktop/Header";
import CardHeaderMobile from "pages/Order/Partial/OrderCard/Mobile/Header";
import AccountingCard from "components/Shared/AccountingCard";
import { useParams } from "react-router-dom";
import { doOrderInkSignDocsCleanup, getOrderInkSignDocs } from "store/order/actions";
import { BrowserView, MobileView } from "react-device-detect";
import OrderServicesSection from "components/Shared/OrderServicesSection";
import { getOrderFees } from "helpers/backendHelper";
import { showError } from "helpers/utilHelper";
import DealerChecklistDesktop from "./Desktop/DealerChecklist";
import DealerChecklistMobile from "./Mobile/DealerChecklist";
import OrderDetailedButtons from "./OrderDetailedButtons";
import OrderInfoSection from "./Desktop/OrderInfoSection";
import DealershipSection from "./OrderInformations/Dealership";
import VehicleInformationSection from "./OrderInformations/VehicleInformation";
import UserAvatar from "components/Shared/UserAvatar";
import { getInitialsFromName } from "helpers/utilHelper";
import { formatPhone } from "helpers/utilHelper";

const OrderCardDetailed = () => {

  // router hook that helps redirect
  const dispatch = useDispatch();

  let { id } = useParams();
  id = parseInt(id);

  /********** STATE **********/

  const { order } = useSelector(state => state.Order.Single);
  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);
  const [fees, setFees] = useState();

  /********** OTHER **********/
  const getOrderFee = () => {
    getOrderFees(id)
      .then((response) => {
        setFees(response.fees)
      })
      .catch(() => {
        showError("Unable to load fees data");
      })
  }

  /********** EFFECTS **********/

  useEffect(() => {
    dispatch(getOrderInkSignDocs(id));
    return () => {
      dispatch(doOrderInkSignDocsCleanup());
    }
  }, []);

  // runs once on component mount
  useEffect(() => {
    getOrderFee();
  }, [id])

  /******** HELPERS ********/

  return (
    <React.Fragment>
      <Card className="mav-card order-details-card">
        {order && <React.Fragment>
          {/* Desktop header */}
          <BrowserView>
            <CardHeaderDesktop order={order} />
          </BrowserView>
          {/* Mobile header */}
          <MobileView>
            <CardHeaderMobile order={order} />
          </MobileView>
          <CardBody className="mav-card-body">
            {!!order.orderNotaryIsInactive && <Alert color="warning" className="fade show mb-3">
              You are no longer assigned to this order.
            </Alert>}
            <OrderServicesSection order={order} />
            <Col className="mt-2">
              {/* Desktop Content*/}
              <BrowserView>
                <OrderInfoSection order={order} />
                <div className="divider-faded divider-sm" />
                {/* Dealer Checklist - Desktop */}
                <DealerChecklistDesktop inkSignDocs={inkSignDocs} order={order} />
                {!order.orderNotaryIsInactive &&
                  /* Action buttons - Print all / Messages / Start */
                  < OrderDetailedButtons order={order} />
                }
              </BrowserView>

              {/* Mobile Content*/}
              <MobileView>
                <VehicleInformationSection order={order} />
                <DealershipSection order={order} />

                <div className="user-info-box text-center">
                  <UserAvatar id={order.schedulerId} image={order.schedulerImage} initials={getInitialsFromName(order.schedulerFullName)} size="lg" />
                  <div className="user-info-box-role mt-2">Scheduler</div>
                  <div className="user-info-box-name mb-1">{order.schedulerFullName}</div>
                  <div className="user-info-box-phone">{formatPhone(order.schedulerPhone) || "--"}</div>
                </div>

                {/* Dealer Checklist - Mobile */}
                <DealerChecklistMobile inkSignDocs={inkSignDocs} order={order} />
              </MobileView>
            </Col>
          </CardBody>

          {!order.orderNotaryIsInactive && <MobileView>
            {/* Action buttons - Print all / Messages / Start */}
            <OrderDetailedButtons order={order} />
          </MobileView>}

        </React.Fragment >}
      </Card >
      {fees && !!fees?.length && <AccountingCard fees={fees} />}
    </React.Fragment >
  )
}

export default OrderCardDetailed;
