import React, { useEffect } from "react";
import { Alert } from "reactstrap";
import { Table, Thead, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import SpinnerChase from "components/Shared/SpinnerChase";
import CredentialDoc from "./Doc";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "context/profile";
import { doCredentialListCleanup, getCredentialList } from "store/actions";

const CredentialDocs = () => {

  const dispatch = useDispatch();
  const { notary } = useProfile();

  const { credentialIds, credentialsError, isLoadInProgress } = useSelector(state => state.Credential.List);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshCredentials();
    return () => {
      dispatch(doCredentialListCleanup());
    }
  }, []);

  /********** OTHER **********/

  /**
   * Fetches credential doc list from API
   */
  const refreshCredentials = () => dispatch(getCredentialList(notary.id));

  return (<div className="table-rep-plugin">
    <div
      className="table-responsive mb-0"
      data-pattern="priority-columns"
    >
      <Table className="table zebra-striped-table new-uploaded-docs mb-0 bb-0">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th data-priority="1">Credential Type</Th>
            <Th data-priority="2">Document name</Th>
            <Th data-priority="3">Status name</Th>
            <Th data-priority="4">Expiry Date</Th>
            <Th data-priority="5">Uploaded Date</Th>
          </Tr>
        </Thead>
        <tbody>
          {isLoadInProgress && <Tr>
            <Td colSpan="8">
              <SpinnerChase className="sm mt-2 mb-2" />
            </Td>
          </Tr>}
          {credentialsError && <Tr>
            <Td colSpan="8">
              <Alert color="danger" className="fade show text-center mb-0">
                <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load documents
              </Alert>
            </Td>
          </Tr>}
          {!isLoadInProgress && !credentialsError && !credentialIds.length && <Tr>
            <Td colSpan="8" className="text-center">No documents uploaded</Td>
          </Tr>}
          {!isLoadInProgress && !credentialsError && credentialIds.map((docId, index) => <CredentialDoc
            key={docId}
            id={docId}
            num={index + 1}
            refreshListHandler={refreshCredentials} />)}
        </tbody>
      </Table>
    </div>
  </div>)
}

export default CredentialDocs;