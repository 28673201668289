import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, Button, CardTitle, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { useProfile } from "context/profile";
import { perms, useAccess } from "context/access";

const ViewBilling = ({ isRefreshing, toggleEditMode }) => {

  const { notary } = useProfile();

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Billing/Payment Information</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            {iAmGranted(perms.edit_notaries) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
              <i className="mdi mdi-pencil me-1" />Edit
            </Button>}
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
        <tr>
          <td className="section-row-label">Billing/Payment Address</td>
          <td className="section-row-value ps-4">{notary.billingAddress || "---"}</td>
        </tr>
        <tr>
          <td className="section-row-label">5 digit Zip Code</td>
          <td className="section-row-value ps-4">{notary.billingZip || "---"}</td>
        </tr>
        <tr>
          <td className="section-row-label">City</td>
          <td className="section-row-value ps-4">{notary.billingCity || "---"}</td>
        </tr>
        <tr>
          <td className="section-row-label">State</td>
          <td className="section-row-value ps-4">{notary.billingState || "---"}</td>
        </tr>
        </tbody>
      </Table>
    </CardBody>
    {isRefreshing && <CardPreloader />}
  </Card>)
}

ViewBilling.propTypes = {
  isRefreshing: PropTypes.bool,
  toggleEditMode: PropTypes.func,
};

export default ViewBilling;