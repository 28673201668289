import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import { map } from "lodash";
import logo from "assets/images/mavsign-logo-blue.svg";
import DealerStore from "model/dealerStore";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { getBeUrl } from "helpers/utilHelper";
import downloadDocumentIcon from 'assets/images/download-doc-icon.svg';
import AdditionalFee from "model/additionalFee";
import { route, routes } from "helpers/routeHelper";
import { Link } from "react-router-dom";

const SectionInfo = ({ statement }) => {

  const { lines, amount, paymentMethod, paymentRefId, notaryName, notaryAddress, notaryEmail, notaryCity, notaryState, notaryZip, additionalFeeId } = statement;
  const notaryType = [AdditionalFee.ID_MAVSIGN_NOTARY, AdditionalFee.ID_EXPRESS_NOTARY, AdditionalFee.ID_SUPER_EXPRESS_NOTARY];

  const displayPrice = (feeCount, price) => {
    if(feeCount > 1) return `${feeCount} x $${price}`;
    return `$${price}`;
  }


  return <Row>
    <Col lg="12">
      <Card>
        <CardBody>
          <div className="invoice-title">
            <div className="mb-2">
              <img src={logo} alt="logo" height="30" />
            </div>
          </div>
          <hr />
          <Row className="mb-5">
            <Col xs="6">
              <p><strong>Notary Information</strong></p>
              <address>
                <strong>Name</strong>
                <div>{notaryName}</div>
              </address>
              <address>
                <strong>Address</strong>
                <div>{notaryAddress}</div>
                <div>{`${notaryCity}, ${notaryState} ${notaryZip}`}</div>
              </address>
              <address>
                <strong>Email</strong>
                <div>{notaryEmail || "--"}</div>
              </address>
            </Col>
          </Row>

          <Row className="mb-5">
            <div className="py-2 mt-3">
              <h3 className="font-size-15 fw-bold">Transaction Detail</h3>
            </div>
            <div className="table-responsive">
              <Table className="table-nowrap">
                <thead>
                  <tr>
                    <th style={{ width: "70px" }}>No.</th>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Notary Service</th>
                    <th>Customer Name</th>
                    <th className="text-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {map(
                    lines,
                    (line, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td><Link to={route(routes.view_order, line.orderId)}>{line.orderId}</Link></td>
                        <td>{formatTimestamp(line.date, formats.LONG_DATE)}</td>
                        <td>{notaryType.includes(line.additionalFeeId) ? 'Service fee' : line.description}</td>
                        <td>{line.customerName}</td>
                        <td className="text-end">{displayPrice(line.additionalFeeCount, line.additionalFeePrice)}</td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td colSpan="5" className="border-0 text-end">
                      Sub Total Price
                    </td>
                    <td className="border-0 text-end">
                      <h6 className="m-0">
                        {`$${amount}`}
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5" className="border-0 text-end">
                      <strong>Total Price</strong>
                    </td>
                    <td className="border-0 text-end">
                      <h4 className="m-0">
                        {`$${amount}`}
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>

          <div className="py-2">
            <h3 className="font-size-15 fw-bold mb-3">Payment</h3>
            <address>
              <strong>Payment Method</strong>
              <div>{DealerStore.getPaymentMethodName(paymentMethod) || "--"}</div>
            </address>
            <address>
              <strong>Reference</strong>
              <div>{paymentRefId || "--"}</div>
            </address>
          </div>

          <a href={getBeUrl(`statement/${statement.id}/download`)} target="_blank" rel="noreferrer" className="btn btn-primary download-link float-end">
            <span>Download</span>
            <img className='ps-2' src={downloadDocumentIcon} />
          </a>
        </CardBody>
      </Card>
    </Col>
  </Row>
}

SectionInfo.propTypes = {
  statement: PropTypes.object,
};

export default SectionInfo;