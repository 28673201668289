import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Button, Alert } from "reactstrap"
import { formatPhone } from "helpers/utilHelper";
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import OrderCardHeader from "pages/Order/Partial/OrderCard/Desktop/Header";
import Message from "model/message";
import OrderServicesSection from "components/Shared/OrderServicesSection";

const OrderCardDesktop = ({ order }) => {

  // router hook that helps redirect
  const navigate = useNavigate();

  const notaryIsInactive = order.orderNotaryIsInactive;

  return (<React.Fragment>
    <Card className="mav-card order-card grid-view expand-v">
      {!!notaryIsInactive && <div className="opaque-overlay"></div>}
      <OrderCardHeader order={order} />
      <CardBody className="mav-card-body px-3 py-2">
        <Row className="px-0 py-2 h-100">
          <Col className="d-flex flex-column justify-content-between">
            <div>
              <OrderServicesSection order={order} />
              <div className="mt-3">
                <span className="me-2">Signing ID:</span>
                <strong>{order.id}</strong>
              </div>
              <div className="divider-faded my-2" />
              <div>
                <span className="me-2">Services:</span>
                <strong>{(!!order.isRushRequired && "Rush") || "--"}</strong>
              </div>
            </div>

            <div>
              {!notaryIsInactive &&
                <Button
                  color="success"
                  className="my-2 w-100 d-flex justify-content-center"
                  onClick={() => navigate(route(routes.view_order_messages, [order.id, Message.CHANNEL_SCHEDULER_NOTARY]))}
                >
                  <i className="bx bx-message font-size-20 me-2" />
                  Messages
                </Button>
              }
              {!!notaryIsInactive && <Alert color="warning" className="fade show mb-3 text-center mt-3 no-opacity bring-forward">
                You are no longer assigned to this order.
              </Alert>}
              <div className="divider-faded my-2" />
              <div className="d-flex align-items-center mb-1">
                <i className="mdi mdi-calendar-account-outline font-size-20" />
                <span className="ms-2 me-2">Scheduler:</span>
                <strong>{order.schedulerFullName}</strong>
              </div>
              <div>
                <span className="me-2">Phone:</span>
                <strong>{formatPhone(order.schedulerPhone) || "--"}</strong>
              </div>
              <div className="divider-faded my-2" />
              <div className="d-flex align-items-center mb-1">
                <i className="dripicons-store font-size-17 mt-1" />
                <span className="ms-2 me-2">Dealership:</span>
                <strong>{order.dealerStoreName}</strong>
              </div>
              <div className="mb-1">
                <span className="me-2">Contact:</span>
                <strong>{order.dealerFullName}</strong>
              </div>
              <div>
                <span className="me-2">Phone:</span>
                <strong>{formatPhone(order.dealerPhone)}</strong>
              </div>
              <Button color="primary" className="w-100 my-2 bring-forward" onClick={() => navigate(route(routes.view_order, order.id))}>View Details</Button>
            </div>
          </Col>
        </Row>

      </CardBody>
    </Card>
  </React.Fragment>)
}

OrderCardDesktop.propTypes = {
  order: PropTypes.object,
};

export default OrderCardDesktop;