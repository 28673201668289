import React from "react";
import PropTypes from 'prop-types';
import {Row, Col } from "reactstrap";
import { sanitizeHtml } from "helpers/utilHelper";
import databaseFileIcon from "assets/images/alert-informations-section/database-file-icon.svg";
import { BrowserView, MobileView } from "react-device-detect";

const AlertInformationsSection = ({title, alertsList, notaryMustUploadDocs}) => {

  return(<div className="alert-informations mb-3">
    <div className="">
      <h1 className="title">{title}:</h1>
      <div className="d-felx">
        <Row className="justify-content-center align-items-center">
          {alertsList.map((col, index) => <Col key={index} lg="4" className="custom-col text-center mt-4">
            <div className="image-wrapper">
              <BrowserView><img className="mb-2" src={col.icon.desktop} /></BrowserView>
              <MobileView><img className="mb-2" src={`${col.icon.mobile ? col.icon.mobile : col.icon.desktop}`} /></MobileView>
            </div>
            <div className="description-wrapper">
              <div className="alert-count">{index + 1}</div>
              <div className="alert-description" dangerouslySetInnerHTML={{ __html: sanitizeHtml(col.description) }} />
            </div>
          </Col>)}
          {notaryMustUploadDocs && <Col lg="4" className="custom-col text-center mt-4">
            <div className="image-wrapper">
              <img className="mb-2" src={databaseFileIcon} />
            </div>
            <div className="description-wrapper">
              <div className="alert-count">{alertsList.length + 1}</div>
              <div className="alert-description">
                <strong className="danger">*Important*</strong> All signed dealer documents must be uploaded  in the <strong>Add Additional Documents</strong> section. A fee will be applied to your payment!
              </div>
            </div>
          </Col>}
        </Row>
      </div>
    </div>
  </div>)
}

AlertInformationsSection.propTypes = {
  title: PropTypes.string,
  alertsList: PropTypes.array,
  notaryMustUploadDocs: PropTypes.bool,
};

export default AlertInformationsSection;