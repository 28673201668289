import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Label, FormFeedback, Form } from "reactstrap";
import Select from "react-select";
import FormNewUpload from "../New/Upload";
import { useFormikContext } from "formik";
import { dictionaryToSelectOptions, showError, showSuccess } from "helpers/utilHelper";
import { doCredentialFormCleanup, getCredentialList } from "store/actions";
import { ValidationException } from "helpers/errorHelper";
import Credential from "model/credential";
import { useProfile } from "context/profile";

const FormContentCredential = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // consume formik instance from context
  const formik = useFormikContext();

  // get notary info
  const { notary } = useProfile();

  // get redux state from store
  const { uploaded, uploadError } = useSelector(state => state.Credential.Form);

  // we use this as a way to force react to unmount and recreate components
  // this seems to be the only way to clear the select control
  const [nonce, setNonce] = useState(0);

  /********** EFFECTS **********/

  // runs whenever the 'uploaded' flag changes
  // which happens after a upload-doc attempt
  useEffect(() => {
    if (uploaded === true) {
      showSuccess("Document has been uploaded");

      dispatch(doCredentialFormCleanup());
      dispatch(getCredentialList(notary.id));

      // reset upload form fields
      // this is needed because the select control cannot be reset easily
      setNonce(n => n + 1);

      clearForm();
    } else if (uploaded === false) {
      showError("Unable to upload document");
      // see if the save failed due to validation
      if (uploadError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(uploadError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [uploaded]);

  /********** EVENT HANDLERS **********/

  // reset all other upload form fields when credential type changes
  const handleTypeChange = selected => {
    // handle select clear action
    if (!selected) {
      clearForm();
      return;
    }
    // handle change
    if (selected.value != formik.values.type)
      formik.resetForm({
        values: {
          type: +selected.value,
        },
      });
  }

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  };

  /********** OTHER **********/

  const clearForm = () => formik.resetForm({
    values: {
      type: "",
    },
  });

  return <Form>
    <Row className="mb-4">
      <Col xs={12} md={6}>
        <Label className="col-form-label">Credential Type*</Label>
        <Select
          classNamePrefix="select2-selection"
          name="type"
          key={"SelectType" + nonce}
          isClearable
          options={typeOptions}
          onChange={handleTypeChange}
          onFocus={onFieldFocused}
          value={typeOptions.find(option => option.value === formik.values.type)}
          className={!!formik.errors.type && "is-invalid"} />
        {!!formik.errors.type && <FormFeedback type="invalid">{formik.errors.type}</FormFeedback>}
      </Col>
    </Row>

    {formik.values.type !== "" && <FormNewUpload />}

  </Form>
}

const typeOptions = dictionaryToSelectOptions(Credential.getTypeMap()).map(option => ({ ...option, label: option.label + (Credential.isRequired(option.value) ? "*" : "") }));

export default FormContentCredential;