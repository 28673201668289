import React from "react";
import PropTypes from "prop-types";
import { Button, UncontrolledTooltip } from "reactstrap";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import DocPageDesktop from "pages/Order/Partial/Doc/PageDesktop";
import DocPageMobile from "pages/Order/Partial/Doc/PageMobile";
import { Gallery } from "react-photoswipe-gallery";
import { BrowserView, MobileView } from "react-device-detect";
import { deleteOrderDoc } from "helpers/backendHelper";
import OrderDoc from "model/orderDoc";
import UploadPdf from "pages/Order/Partial/Doc/UploadPdf";

const SectionAdditionalDocs = ({ docs, refreshHandler, disabled, setHasInProgressUploads }) => {

  const deleteAdditionalDoc = id => async () => {
    try {
      await deleteOrderDoc(id);
      showSuccess("Document deleted");
      refreshHandler();
    } catch (err) {
      showError("Unable to delete document");
    }
  }

  return (
    <div className="doc-pages">
      {docs.map(doc => (
        <div key={doc.id} className="mobile-uploaded-doc w-100 mb-3">
          <div key={doc.id} className="doc-item justify-content-between">
            <div className="d-inline-flex align-items-center">
              <div className="doc-item-title">
                {doc.name}
              </div>
              {doc.status == OrderDoc.STATUS_REJECTED && <React.Fragment>
                <div className="badge badge-lg rounded-pill bg-danger doc-item-badge mt-0" id={'doc-rejected-' + doc.id}>Rejected</div>
                {!!doc.rejectionReason && <UncontrolledTooltip placement="top" target={'doc-rejected-' + doc.id}>{doc.rejectionReason}</UncontrolledTooltip>}
              </React.Fragment>}
              {doc.status == OrderDoc.STATUS_COMPLETE && <div className="badge badge-lg rounded-pill bg-success doc-item-badge mt-0" id={'doc-accepted-' + doc.id}>Accepted</div>}
              {doc.isCapturedAsPdf && <>
                <a className="btn btn-secondary square-icon-btn" href={getBeUrl(`/order-doc/${doc.id}/pdf`)} target="_blank" rel="noreferrer">
                  <i className="mdi mdi-file-pdf-outline font-size-22" title="Document was captured as pdf" />
                </a>
              </>}
            </div>
            <Button color="danger" className="square-icon-btn" onClick={deleteAdditionalDoc(doc.id)} disabled={disabled} title="Delete document">
              <i className="mdi mdi-trash-can-outline" />
            </Button>
          </div>

          {!doc.isCapturedAsPdf && <MobileView>
            <p className="or-upload-pdf my-3">Add a page or a PDF</p>
          </MobileView>}

          {!doc.isCapturedAsPdf && <div className="doc-pages">
            <Gallery id="additional-docs" options={{ mainClass: "ink-sign-gallery" }}>
              {/* render additional docs */}
              {!!doc.pages && Object.keys(doc.pages).map((page, index) => (
                <React.Fragment key={index}>
                  <BrowserView className="mb-2">
                    <DocPageDesktop
                      docId={doc.id}
                      isPageUploaded={!!doc.pages?.[index + 1]}
                      pageNum={index + 1}
                      finishedHandler={refreshHandler}
                      disabled={disabled}
                      pages={doc.pages}
                      setHasInProgressUploads={setHasInProgressUploads}
                    />
                  </BrowserView>

                  <MobileView className="doc-page-mobile-wrapper">
                    <DocPageMobile
                      docId={doc.id}
                      docName={doc.name}
                      isPageUploaded={!!doc.pages?.[index + 1]}
                      pageNum={index + 1}
                      finishedHandler={refreshHandler}
                      disabled={disabled}
                      pages={doc.pages}
                      setHasInProgressUploads={setHasInProgressUploads}
                    />
                  </MobileView>
                </React.Fragment>
              ))}

              {/* add new doc */}
              <BrowserView className="d-flex">
                <DocPageDesktop
                  docId={doc.id}
                  isNewPage
                  isPageUploaded={false}
                  pageNum={doc.pages ? Object.keys(doc.pages).length + 1 : 1}
                  finishedHandler={refreshHandler}
                  disabled={disabled}
                  pages={doc.pages}
                  setHasInProgressUploads={setHasInProgressUploads}
                />
                <p className="or-upload-pdf">OR</p>
                <UploadPdf doc={doc} finishedHandler={refreshHandler} disabled={disabled} />
              </BrowserView>

              <MobileView>
                <div className="docs-upload-wrapper">
                  <DocPageMobile
                    docId={doc.id}
                    docName={doc.name}
                    isNewPage
                    isPageUploaded={false}
                    pageNum={doc.pages ? Object.keys(doc.pages).length + 1 : 1}
                    finishedHandler={refreshHandler}
                    disabled={disabled}
                    pages={doc.pages}
                    setHasInProgressUploads={setHasInProgressUploads}
                  />
                  <UploadPdf doc={doc} finishedHandler={refreshHandler} disabled={disabled} />
                </div>
              </MobileView>
            </Gallery>
          </div>}
        </div>))}
    </div>
  )
}

SectionAdditionalDocs.propTypes = {
  docs: PropTypes.array,
  refreshHandler: PropTypes.func,
  disabled: PropTypes.bool,
  setHasInProgressUploads: PropTypes.func,
}

export default SectionAdditionalDocs;