import React, { useState } from "react";
import PropTypes from "prop-types";
import { InfoWindow, Map, Marker } from "google-maps-react";
import { useGoogleApi } from "context/google";

// set a default center, otherwise the map is centered in the ocean
const defaultCenter = {
  lat: 33.59773919487583,
  lng: -117.6563644,
}

const GoogleMapsLocation = props => {

  const { position, address } = props;

  const { google } = useGoogleApi();

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const onMarkerClicked = (props, marker) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowInfoWindow(true);
  }

  const onMapClicked = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }
  }

  return (
    <div
      id="gmaps-markers"
      className="gmaps"
      style={{ position: "relative" }}
    >
      <Map
        google={google}
        style={{ width: "100%", height: "100%" }}
        zoom={14}
        onClick={onMapClicked}
        center={position || defaultCenter}
      >
        <Marker
          title={"Current location"}
          name={address}
          onClick={onMarkerClicked}
          position={position}
        />
        <InfoWindow visible={showInfoWindow} marker={activeMarker}>
          <div>
            <h5 className="mt-2">{selectedPlace.name}</h5>
          </div>
        </InfoWindow>
      </Map>
    </div>
  )
}

GoogleMapsLocation.propTypes = {
  position: PropTypes.object,
  address: PropTypes.string,
}

export default GoogleMapsLocation;