import React from "react"
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import OrderCardDetailed from "../Partial/OrderCard/Detailed";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { perms, useAccess } from "context/access";

const OrderView = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hook that checks permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const { order, isLoadInProgress, orderError } = useSelector(state => state.Order.Single);

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>Order #{id}</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
          <Row>
            <Col xs={12}>
              <OrderCardDetailed />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: order.orderSignerFullName,
}, {
  title: `#${order.id}`,
}];

export default OrderView;
