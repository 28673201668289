import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Label, Input } from "reactstrap";
import { showError, showSuccess } from "helpers/utilHelper";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "context/profile";
import { resetSaveProfileActionFlag } from "store/actions";
import GoogleMapsLocation from "components/Shared/GoogleMapsLocation";

const FormContentArea = props => {

  const dispatch = useDispatch();
  const { notary, refreshProfile } = useProfile();

  // saved - TRUE if a save was attempted and was successful, FALSE if it failed, NULL if no save was attempted yet
  const { saved } = useSelector(state => state.Profile.Form);

  /********** EFFECTS **********/

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess("Delivery info saved");
      refreshProfile();
    } else if (saved === false) {
      showError("Unable to save delivery info");
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag("saved"));
    }
  }, [saved]);

  return <Form>
    <Row className="mb-4 align-items-center">
      <Label className="col-sm-4 col-form-label word-wrap-normal">Google Maps Latitude</Label>
      <Col sm={8}>
        <Input type="text" className="form-control" name="shippingLatitude" value={notary.shippingLatitude} disabled />
      </Col>
    </Row>
    <Row className="mb-4 align-items-center">
      <Label className="col-sm-4 col-form-label word-wrap-normal">Google Maps Longitude</Label>
      <Col sm={8}>
        <Input type="text" className="form-control" name="shippingLongitude" value={notary.shippingLongitude} disabled />
      </Col>
    </Row>
    {props.showMap && <Row>
      <Col>
        <GoogleMapsLocation
          google={props.google}
          position={{ lat: notary.shippingLatitude, lng: notary.shippingLongitude }}
          address={notary.shippingAddress}
        />
      </Col>
    </Row>}
  </Form>
}

FormContentArea.propTypes = {
  google: PropTypes.object,
  showMap: PropTypes.bool,
}

export default FormContentArea;