import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import classnames from "classnames";
import docsDeliveredIcon from "assets/images/order/docs-delivered-icon.svg";
import docsDeliveredIconWhite from "assets/images/order/docs-delivered-icon-white.svg";
import appointmentSetIcon from "assets/images/order/appointment-set-icon.svg";
import appointmentSetIconWhite from "assets/images/order/appointment-set-icon-white.svg";
import beforeSigningIcon from "assets/images/order/before-signing-icon.svg";
import beforeSigningIconWhite from "assets/images/order/before-signing-icon-white.svg";
import duringSigningIcon from "assets/images/order/during-signing-icon.svg";
import duringSigningIconWhite from "assets/images/order/during-signing-icon-white.svg";
import returnToDealershipIcon from "assets/images/order/return-to-dealership-icon.svg";
import returnToDealershipIconWhite from "assets/images/order/return-to-dealership-icon-white.svg";
import StepSetAppointment from "./Step/SetAppointment";
import StepBeforeSigning from "./Step/BeforeSigning";
import StepDuringSigning from "./Step/DuringSigning";
import StepDocsDelivered from "./Step/DocsDelivered";
import StepReturnToDealership from "./Step/ReturnToDealership";
import InfoMessage from "components/Shared/InfoMessage";
import AccountingCard from "components/Shared/AccountingCard";
import OrderNotary from "model/orderNotary";
import { useParams } from "react-router-dom";
import { BrowserView } from "react-device-detect";
import { min } from "lodash";
import { useSelector } from "react-redux";
import { getOrderFees } from "helpers/backendHelper";
import { showError } from "helpers/utilHelper";


const accordionItems = [
  {
    id: 1,
    // statuses for which this step is considered to be the current step
    status: [OrderNotary.STATUS_ASSIGNED, OrderNotary.STATUS_VIEWED],
    title: "Step 1 : Documents Delivery",
    icon: docsDeliveredIcon,
    successIcon: docsDeliveredIconWhite,
    content: <StepDocsDelivered />
  },
  {
    id: 2,
    status: [OrderNotary.STATUS_DOCS_RECEIVED, OrderNotary.STATUS_APPOINTMENT_SET, OrderNotary.STATUS_APPOINTMENT_DECLINED],
    title: "Step 2 : Set the Appointment",
    icon: appointmentSetIcon,
    successIcon: appointmentSetIconWhite,
    content: <StepSetAppointment />
  },
  {
    id: 3,
    status: [OrderNotary.STATUS_APPOINTMENT_ACCEPTED],
    title: "Step 3 : Before the Signing",
    icon: beforeSigningIcon,
    successIcon: beforeSigningIconWhite,
    content: <StepBeforeSigning />
  },
  {
    id: 4,
    status: [OrderNotary.STATUS_DOCS_PRINTED],
    title: "Step 4 : During & After the Signing",
    icon: duringSigningIcon,
    successIcon: duringSigningIconWhite,
    content: <StepDuringSigning />
  },
  {
    id: 5,
    status: [OrderNotary.STATUS_DOCS_UPLOADED],
    title: "Step 5 : Return docs to the dealership",
    icon: returnToDealershipIcon,
    successIcon: returnToDealershipIconWhite,
    customClass: 'return-docs-accordion',
    content: <StepReturnToDealership />
  },
];

const getCurrentStep = status => accordionItems.find(item => item.status.includes(status));

const OrderFlowAccordion = ({ order }) => {

  let { id } = useParams();
  id = parseInt(id);

  /********** STATE **********/

  const currentStep = getCurrentStep(order.orderNotaryStatus);

  const [open, setOpen] = useState(currentStep?.id?.toString());

  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);

  const allDocsReturned = inkSignDocs?.every(item => !!item.returnedByNotaryTs);

  const [fees, setFees] = useState();

  /********** HANDLERS **********/

  const toggle = id => {
    if (open == id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const getOrderFee = () => {
    getOrderFees(id)
    .then((response) => {
      setFees(response.fees)
    })
    .catch(() => {
      showError("Unable to load fees data");
    })
  }

  /********** EFFECTS **********/

  // runs every time the order notary status changes
  useEffect(() => {
    const newStep = getCurrentStep(order.orderNotaryStatus);
    setOpen(newStep?.id?.toString());
  }, [order.orderNotaryStatus]);

  // runs once on component mount
  useEffect(() => {
    getOrderFee();
  },[id])

  return (
  <>
    <Accordion className="order-flow-accordion" open={open} toggle={toggle}>
      {accordionItems.map((item, idx) => {
        const currentStep = item.status.includes(order.orderNotaryStatus);

        const stepNotReady = min(item.status) > order.orderNotaryStatus;
        const stepCompleted = (!currentStep && !stepNotReady) || allDocsReturned;

        const prevItem = accordionItems[idx - 1];
        const prevStepIsCompleted = !prevItem || (order.orderNotaryStatus > Math.max(...prevItem.status));

        return (
          <AccordionItem key={item.id}>
            <AccordionHeader
              className={classnames({ "accordion-item-success": stepCompleted })}
              targetId={item.id.toString()}
            >
              <img alt="icon" src={stepCompleted ? item.successIcon : item.icon} />
              <h6 className="accordion-title ms-3 my-0">{item.title}</h6>
            </AccordionHeader>
            <AccordionBody accordionId={item.id.toString()} className={item.customClass && item.customClass}>
              <BrowserView>
                {!prevStepIsCompleted && <InfoMessage alert description={OrderNotary.MESSAGE_MUST_CONFIRM_STEPS_ABOVE} />}
              </BrowserView>
              {item.content}
            </AccordionBody>
          </AccordionItem>
        )
      })}
    </Accordion>
    {fees && !!fees?.length && <AccountingCard fees={fees} />}
  </>
  )
};

OrderFlowAccordion.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderFlowAccordion;