import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useGoogleApi } from "context/google";

const PlacesAutocomplete = ({ onPlaceChanged, ...rest }) => {

  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const { google } = useGoogleApi();

  const options = {
    componentRestrictions: { country: "us" },
    fields: ["address_components", "geometry"],
    types: ["address"],
  };

  useEffect(() => {
    if (!google) return;

    const autocompleteInput = document.getElementById("places-autocomplete");

    // Google Maps code is overwriting the `autocomplete` attribute value
    // so address autofill will always be enabled, which we don't want
    // as a fix, we are listening for mutation on the attribute and then overwrite it
    // issue is detailed here https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3
    const observer = new MutationObserver(function () {
      observer.disconnect();
      autocompleteInput.setAttribute("autocomplete", "disabled")
    });

    observer.observe(autocompleteInput, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });

    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      onPlaceChanged && onPlaceChanged(place);
    });
  }, []);

  return <input id="places-autocomplete" autoComplete="disabled" ref={inputRef} className="form-control" {...rest} />
}

PlacesAutocomplete.propTypes = {
  onPlaceChanged: PropTypes.func,
}

export default PlacesAutocomplete;