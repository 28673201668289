import { takeEvery, put, call } from "redux-saga/effects";

import {
  DELETE_CREDENTIAL_LIST_ITEM,
  GET_CREDENTIAL_LIST,
  UPLOAD_CREDENTIAL,
} from "./actionTypes";

import {
  getCredentialListOk,
  getCredentialListErr,

  deleteCredentialListItemOk,
  deleteCredentialListItemErr,

  uploadCredentialOk,
  uploadCredentialErr,
} from "./actions";

import {
  getCredentials,
  deleteCredential,
  uploadCredential,
} from "helpers/backendHelper";

/********** LIST **********/

function* onGetCredentials({ payload: { id } }) {
  try {
    const response = yield call(getCredentials, id);
    yield put(getCredentialListOk(response));
  } catch (error) {
    yield put(getCredentialListErr(error));
  }
}

function* onDeleteCredentialListItem({ payload: { id } }) {
  try {
    const response = yield call(deleteCredential, id);
    yield put(deleteCredentialListItemOk(id, response));
  } catch (error) {
    yield put(deleteCredentialListItemErr(id, error));
  }
}

/********** FORM **********/

function* onUploadCredential({ payload: { data } }) {
  try {
    const response = yield call(uploadCredential, data);
    yield put(uploadCredentialOk(response));
  } catch (error) {
    yield put(uploadCredentialErr(error));
  }
}

function* credentialSaga() {
  yield takeEvery(GET_CREDENTIAL_LIST, onGetCredentials);
  yield takeEvery(DELETE_CREDENTIAL_LIST_ITEM, onDeleteCredentialListItem);
  yield takeEvery(UPLOAD_CREDENTIAL, onUploadCredential);
}

export default credentialSaga;