import React, { useEffect } from "react";
import PropTypes from "prop-types"
import { Col, Row, Card, CardTitle, CardBody, Button } from "reactstrap";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

const FormEditCard = ({ title, finishedHandler, children }) => {

  // consume formik instance from context
  const formik = useFormikContext();

  // isSaveInProgress - TRUE if a save request is in progress, FALSE otherwise
  // saved - TRUE if a save was attempted and was successfull, FALSE if it failed, NULL if no save was attempted yet
  const { isSaveInProgress, saved } = useSelector(state => state.Profile.Form);

  /********** EFFECTS **********/

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      // close the edit mode
      finishedHandler();
    }
  }, [saved]);

  return (<Card className="expand-v">
    <CardBody className="pt-3">
      <div className="pb-4">
        <Row>
          <Col>
            <CardTitle>{title}</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save
              </Button>
              <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
                <i className="mdi mdi-chevron-left me-1" />
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </CardBody>
  </Card>)
}

FormEditCard.propTypes = {
  title: PropTypes.string,
  finishedHandler: PropTypes.func,
  children: PropTypes.object,
}

export default FormEditCard;