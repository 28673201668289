import {getChats, getInProgressOrders, getStatistics} from "../../helpers/backendHelper";
import {
	getChatsErr,
	getChatsOk,
	getInProgressOrdersErr,
	getInProgressOrdersOk,
	getLeadsStatisticsErr,
	getLeadsStatisticsOk
} from "./actions";

import { call, takeEvery, put } from "redux-saga/effects"

import {GET_CHATS, GET_IN_PROGRESS_ORDERS, GET_LEADS_STATISTICS} from "./actionTypes";

/********** LEADS STATISTICS **********/
function* onGetStatistics({ payload: { unit } }) {
	try {
		const response = yield call(getStatistics, unit);
		yield put(getLeadsStatisticsOk(response));
	} catch (error) {
		yield put(getLeadsStatisticsErr(error));
	}
}

/********** CHATS **********/
function* onGetChats() {
	try {
		const response = yield call(getChats);
		yield put(getChatsOk(response));
	} catch (error) {
		yield put(getChatsErr(error));
	}
}

/********** IN PROGRESS ORDERS **********/
function* onGetInProgressOrders({ payload: { params } }) {
	try {
		const response = yield call(getInProgressOrders, params);
		yield put(getInProgressOrdersOk(response));
	} catch (error) {
		yield put(getInProgressOrdersErr(error));
	}
}

function* dashboardSaga() {
		yield takeEvery(GET_LEADS_STATISTICS, onGetStatistics);
		yield takeEvery(GET_CHATS, onGetChats);
		yield takeEvery(GET_IN_PROGRESS_ORDERS, onGetInProgressOrders);
}

export default dashboardSaga;