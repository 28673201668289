import React, { useEffect } from "react";
import { Col, Row, Form, Label, FormFeedback } from "reactstrap";
import Select from "react-select";
import { useFormikContext } from "formik";
import { getYesNoOptions, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "context/profile";
import { getCredentialList, resetSaveProfileActionFlag } from "store/actions";
import UsStates from "constants/usState";
import TextareaAutosize from "react-textarea-autosize";
import classnames from "classnames";

const FormContentCredentialsDetails = () => {

  const dispatch = useDispatch();
  const { refreshProfile, notary } = useProfile();

  // consume formik instance from context
  const formik = useFormikContext();

  // saved - TRUE if a save was attempted and was successful, FALSE if it failed, NULL if no save was attempted yet
  const { saved } = useSelector(state => state.Profile.Form);
  const { credentials, commissionCertificate, eoInsurance, driverLicense } = useSelector(state => state.Credential.List);

  /********** EFFECTS **********/

  useEffect(() => {
    formik.setFieldValue("commissionState", credentials[commissionCertificate]?.commissionState);
    formik.setFieldValue("commissionState2", credentials[commissionCertificate]?.commissionState2);
    formik.setFieldValue("countiesCovered", credentials[commissionCertificate]?.countiesCovered);
  }, [credentials]);

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess("Credential details info saved");
      refreshProfile();
      // reload credential list, in order to display commision updated info
      dispatch(getCredentialList(notary.id));
    } else if (saved === false) {
      showError("Unable to save credential details info");
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag("saved"));
    }
  }, [saved]);

  // keep track of the count of each credential type
  // so we can later show an error if the user did not upload the required docs
  useEffect(() => {
    // reset errors
    delete formik.errors.commissionCertificate;
    // update value
    formik.setFieldValue("commissionCertificate", commissionCertificate);
  }, [commissionCertificate]);

  useEffect(() => {
    // reset errors
    delete formik.errors.eoInsurance;
    // update value
    formik.setFieldValue("eoInsurance", eoInsurance);
  }, [eoInsurance]);

  useEffect(() => {
    // reset errors
    delete formik.errors.driverLicense;
    // update value
    formik.setFieldValue("driverLicense", driverLicense);
  }, [driverLicense]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  const usStates = toSelectOptions(UsStates);

  return <React.Fragment>
    {!!formik.errors.commissionCertificate && <div className="font-size-10 text-danger text-end">{formik.errors.commissionCertificate}</div>}
    {!!formik.errors.eoInsurance && <div className="font-size-10 text-danger text-end">{formik.errors.eoInsurance}</div>}
    {!!formik.errors.driverLicense && <div className="font-size-10 text-danger text-end">{formik.errors.driverLicense}</div>}
    <Form>
      <Row className="mb-4">
        <Col className="credential-detail-col">
          <Label className="col-form-label">Can You Print Digital Docs?*</Label>
          <Select
            classNamePrefix="select2-selection"
            name="canPrintDigitalDocs"
            options={getYesNoOptions()}
            onChange={selected => formik.setFieldValue("canPrintDigitalDocs", !!selected.value)}
            onFocus={e => onFieldFocused(e, "canPrintDigitalDocs")}
            value={getYesNoOptions().find(option => option.value == formik.values.canPrintDigitalDocs)}
            className={!!formik.errors.canPrintDigitalDocs && "is-invalid"} />
          {!!formik.errors.canPrintDigitalDocs && <FormFeedback type="invalid">{formik.errors.canPrintDigitalDocs}</FormFeedback>}
        </Col>
        <Col className="credential-detail-col">
          <Label className="col-form-label">EDocs*</Label>
          <Select
            classNamePrefix="select2-selection"
            name="canSignElectronically"
            options={getYesNoOptions()}
            onChange={selected => formik.setFieldValue("canSignElectronically", !!selected.value)}
            onFocus={e => onFieldFocused(e, "canSignElectronically")}
            value={getYesNoOptions().find(option => option.value == formik.values.canSignElectronically)}
            className={!!formik.errors.canSignElectronically && "is-invalid"} />
          {!!formik.errors.canSignElectronically && <FormFeedback type="invalid">{formik.errors.canSignElectronically}</FormFeedback>}
        </Col>
        <Col className="credential-detail-col">
          <Label className="col-form-label">Commission State</Label>
          <Select
            classNamePrefix="select2-selection"
            name="commissionState"
            options={usStates}
            onChange={selected => formik.setFieldValue("commissionState", selected.value)}
            onFocus={e => onFieldFocused(e, "commissionState")}
            value={usStates.find(option => option.value === formik.values.commissionState)}
            className={!!formik.errors.commissionState && "is-invalid"} />
          {!!formik.errors.commissionState && <FormFeedback type="invalid">{formik.errors.commissionState}</FormFeedback>}
        </Col>
        <Col xs={12} md={6}>
          <Label className="col-form-label">Commission in another state?</Label>
          <Select
            classNamePrefix="select2-selection"
            name="commissionState2"
            options={usStates}
            onChange={selected => formik.setFieldValue("commissionState2", selected.value)}
            onFocus={e => onFieldFocused(e, "commissionState2")}
            value={usStates.find(option => option.value === formik.values.commissionState2)}
            className={!!formik.errors.commissionState2 && "is-invalid"} />
          {!!formik.errors.commissionState2 && <FormFeedback type="invalid">{formik.errors.commissionState2}</FormFeedback>}
        </Col>
        <Col xs={12} md={6}>
          <Label className="col-form-label">Counties Covered</Label>
          <TextareaAutosize maxRows={15} className={classnames("form-control", { "is-invalid": !!formik.errors.countiesCovered })} name="countiesCovered" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.countiesCovered} />
          {!!formik.errors.countiesCovered && <FormFeedback type="invalid">{formik.errors.countiesCovered}</FormFeedback>}
        </Col>
      </Row>
    </Form>
  </React.Fragment>
}

export default FormContentCredentialsDetails;