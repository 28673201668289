import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import { BrowserView, MobileView } from "react-device-detect";
import OrderCardDesktop from "pages/Order/Partial/OrderCard/Desktop/GridView";
import OrderCardMobile from "pages/Order/Partial/OrderCard/Mobile/GridView";

const OrderCard = ({ order }) => {

  return <>{order && <Col xs={12} md={6} xl={4}>
    {/* Desktop Content */}
    <BrowserView className="h-100">
      <OrderCardDesktop order={order} />
    </BrowserView>

    {/* Mobile Content */}
    <MobileView>
      <OrderCardMobile order={order} />
    </MobileView>
  </Col>
  }</>
}

OrderCard.propTypes = {
  order: PropTypes.object,
};

export default OrderCard;