import React, { useState, useEffect } from "react";
import FormShipping from "../Form/Shipping";
import ViewShipping from "pages/Profile/View/Shipping";
import FormContentShipping from "../Form/Content/Shipping";
import FormEditCard from "../Form/Edit/Card";
import { useProfile } from "context/profile";

const SectionShipping = () => {

  const { isLoadInProgress, refreshProfile } = useProfile();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadInProgress) {
      setIsRefreshing(false);
    }
  }, [isLoadInProgress]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshProfile();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormShipping>
      <FormEditCard title={"Delivery Information"} finishedHandler={toggleEditMode}>
        <FormContentShipping />
      </FormEditCard>
    </FormShipping>}
    {!isEditModeActive && <ViewShipping toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

export default SectionShipping;