import React from "react";
import { Button } from "reactstrap";
import returnAllDocsIcon from "assets/images/return-all-docs-icon.svg";
import PropTypes from "prop-types";
import InfoMessage from "components/Shared/InfoMessage";
import OrderNotary from "model/orderNotary";

const StepReturnToDealershipDesktop = ({ inkSignDocs, isCurrentStep, onHandleAllShipDocs, onHandleShipDoc, onGetDocsStatus }) => {

  return (<React.Fragment>
    <InfoMessage description={OrderNotary.MESSAGE_RETURN_TO_DEALERSHIP_MESSAGE_ONE} />
    <InfoMessage description={OrderNotary.MESSAGE_RETURN_TO_DEALERSHIP_MESSAGE_TWO} />

    <div className="d-flex align-items-center mt-2 mb-2">
      <i className="bx bx-file font-size-20" />
      <h6 className="mb-0 ps-2">Contract information:</h6>
    </div>

    <div className="d-flex justify-content-end return-all-docs-btn">
      {inkSignDocs?.filter((doc) => !doc.returnedByNotaryTs).length > 0 &&
        <Button color="primary" onClick={() => onHandleAllShipDocs(inkSignDocs)} disabled={!isCurrentStep}>
          <img src={returnAllDocsIcon} className="all-docs-shipped-icon" />
          ALL DOCS SHIPPED
        </Button>
      }
    </div>
    <table className="table table-sm zebra-striped-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {inkSignDocs?.map((item, index) => <tr key={index + 1} style={{ verticalAlign: 'middle' }} >
          <td>{index + 1}</td>
          <td>{item.name}</td>
          <td>
            <span className="badge rounded-pill bg-success">
              {onGetDocsStatus(item.returnedByNotaryTs)}
            </span>
          </td>
          <td>{!item.returnedByNotaryTs && <Button color="primary" className='mt-1 mb-1' onClick={() => onHandleShipDoc(item.id)} disabled={!isCurrentStep}>Document shipped</Button>}</td>
        </tr>)}

        {!inkSignDocs?.length && (
          <tr>
            <td className="table-empty" colSpan="4">No contracts found</td>
          </tr>
        )}
      </tbody>
    </table>
    <div id="upload-docs-footer" />
  </React.Fragment>
  )
}

StepReturnToDealershipDesktop.propTypes = {
  inkSignDocs: PropTypes.array,
  isCurrentStep: PropTypes.bool,
  onHandleAllShipDocs: PropTypes.func,
  onHandleShipDoc: PropTypes.func,
  onGetDocsStatus: PropTypes.func,
};

export default StepReturnToDealershipDesktop;