import { combineReducers } from "redux";
import {
  CREATE_MESSAGE,
  CREATE_MESSAGE_ERR,
  CREATE_MESSAGE_OK,
  DO_MESSAGES_CLEANUP,
  GET_MESSAGES,
  GET_MESSAGES_ERR,
  GET_MESSAGES_OK
} from "./actionTypes";

const defaultListState = {
  messages: [],
  messagesError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 100, // we will keep a fixed pageSize at the moment as getting the totalCount can be too expensive
    page: 1,
  },
  isLoadInProgress: false,
  saved: null,
  isSaveInProgress: false,
  saveError: null,
}

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      state = {
        ...state,
        listParams: action.payload.params,
        isLoadInProgress: true,
        messagesError: null,
      };
      break
    case GET_MESSAGES_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        messages: action.payload.response.messages,
        totalCount: action.payload.response.totalCount,
      }
      break
    case GET_MESSAGES_ERR:
      state = {
        ...state,
        isLoadInProgress: false,
        messagesError: action.payload.error,
        messages: [],
        totalCount: 0,
      }
      break
    case CREATE_MESSAGE:
      state = {
        ...state,
        isSaveInProgress: true,
        saveError: null,
        saved: null,
      }
      break
    case CREATE_MESSAGE_OK:
      state = {
        ...state,
        isSaveInProgress: false,
        saved: true
      }
      break
    case CREATE_MESSAGE_ERR:
      state = {
        ...state,
        isSaveInProgress: false,
        saved: false,
        saveError: action.payload.error
      }
      break
    case DO_MESSAGES_CLEANUP:
      state = { ...defaultListState }
      break
  }
  return state;
}

export default combineReducers({ List });