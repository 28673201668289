import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useProfile } from 'context/profile';

const useProfileTermsNotCompleteCheck = () => {

  const navigate = useNavigate();

  const { notary } = useProfile();

  useEffect(() => {
    // if the user did not agree to terms
    if (!notary.termsAcceptedTs) {
      // redirect back to the agreement step
      navigate(route(routes.setup_profile_terms));
      return;
    }
  }, []);
}

export default useProfileTermsNotCompleteCheck;