import React, { useState, useEffect } from "react";
import FormCredentialsDetails from "../Form/CredentialsDetails";
import ViewCredentialsDetails from "pages/Profile/View/CredentialsDetails";
import FormContentCredentialsDetails from "../Form/Content/CredentialsDetails";
import FormEditCard from "../Form/Edit/Card";
import { useProfile } from "context/profile";

const SectionCredentialsDetails = () => {

  const { isLoadInProgress, refreshProfile } = useProfile();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadInProgress) {
      setIsRefreshing(false);
    }
  }, [isLoadInProgress]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshProfile();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormCredentialsDetails>
      <FormEditCard title={"Additional Credential Details"} finishedHandler={toggleEditMode}>
        <FormContentCredentialsDetails />
      </FormEditCard>
    </FormCredentialsDetails>}
    {!isEditModeActive && <ViewCredentialsDetails toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

export default SectionCredentialsDetails;