import {
  GET_NOTARY_BID_LIST,
  GET_NOTARY_BID_LIST_ERR,
  GET_NOTARY_BID_LIST_OK,
  SET_NOTARY_BID_FILTER_TOGGLE,
  DO_NOTARY_BID_LIST_CLEANUP,

  GET_NOTARY_BID,
  GET_NOTARY_BID_OK,
  GET_NOTARY_BID_ERR,
} from "./actionTypes";

export const getNotaryBidList = params => ({
  type: GET_NOTARY_BID_LIST,
  payload: { params },
});

export const getNotaryBidListOk = response => ({
  type: GET_NOTARY_BID_LIST_OK,
  payload: { response },
});

export const getNotaryBidListErr = error => ({
  type: GET_NOTARY_BID_LIST_ERR,
  payload: { error },
});

export const setNotaryBidFilterToggle = status => ({
  type: SET_NOTARY_BID_FILTER_TOGGLE,
  payload: { status },
});

export const doNotaryBidListCleanup = () => ({
  type: DO_NOTARY_BID_LIST_CLEANUP,
});

/********** SINGLE **********/

export const getNotaryBid = id => ({
  type: GET_NOTARY_BID,
  payload: { id },
});

export const getNotaryBidOk = response => ({
  type: GET_NOTARY_BID_OK,
  payload: { response },
});

export const getNotaryBidErr = error => ({
  type: GET_NOTARY_BID_ERR,
  payload: { error },
});