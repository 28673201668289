import React from "react";
import PropTypes from "prop-types";
import Order from "model/order";
import { useAuth } from "context/auth";

const DealerChecklistDesktop = ({ inkSignDocs, order }) => {
  const { user } = useAuth();

  return(<React.Fragment><div className="d-flex align-items-center mb-1">
      <i className="bx bx-file font-size-20" />
      <h6 className="ms-2 mb-0">Dealer Checklist:</h6>
    </div>
    <table className="table table-sm zebra-striped-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Notarization</th>
          <th>Initials</th>
          <th>Signatures</th>
          <th>No of pages</th>
        </tr>
      </thead>
      <tbody>
      {inkSignDocs?.filter(entry => entry.creatorId !== user.id).map((entry, index) => (<tr key={index}>
        <td>{index + 1}</td>
        <td>{entry.name}</td>
        <td>{(entry.name === 'AOI' || entry.name === 'Affidavit of Identity' || !!entry.isNotarizationRequired) ? 'to be notarized' : '--'}</td>
        <td>{entry.numOfInitials}</td>
        <td>{entry.numOfSignatures}</td>
        <td>{entry.numOfPages}</td>
      </tr>
      ))}
      {order?.itemsToCollect && order?.itemsToCollect.split(",").map((docId, index) => (<tr key={docId}>
        <td>{index + 1 + (inkSignDocs?.length || 0)}</td>
        <td>{Order.getItemToCollectName(docId)}</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
      </tr>
      ))}

      {!inkSignDocs?.length && !order?.itemsToCollect && (<tr>
        <td className="table-empty" colSpan="4">No contracts found</td>
      </tr>
      )}
      </tbody>
    </table>
  </React.Fragment>)
}

DealerChecklistDesktop.propTypes = {
  order: PropTypes.object,
  inkSignDocs: PropTypes.array,
};

export default DealerChecklistDesktop;
