import {
  GET_ORDER_LIST,
  GET_IN_PROGRESS_ORDER_LIST,
  GET_ORDER_LIST_OK,
  GET_ORDER_LIST_ERR,
  DO_ORDER_LIST_CLEANUP,
  SET_ORDER_FILTER_TOGGLE,
  SET_VIEW_MODE_TOGGLE,
  SET_NOTARY_ORDER_FILTER_TOGGLE,

  GET_ORDER,
  GET_ORDER_OK,
  GET_ORDER_ERR,
  DO_ORDER_SINGLE_CLEANUP,

  GET_ORDER_MESSAGES,
  GET_ORDER_MESSAGES_OK,
  GET_ORDER_MESSAGES_ERR,
  GET_LAST_ORDER_MESSAGE,
  GET_LAST_ORDER_MESSAGE_OK,
  GET_LAST_ORDER_MESSAGE_ERR,
  GET_CHAT_MEMBERS,
  GET_CHAT_MEMBERS_OK,
  GET_CHAT_MEMBERS_ERR,

  GET_ORDER_INK_SIGN_DOCS,
  GET_ORDER_INK_SIGN_DOCS_OK,
  GET_ORDER_INK_SIGN_DOCS_ERR,
  DO_ORDER_INK_SIGN_DOCS_CLEANUP,

} from "./actionTypes";

/********** LIST **********/

export const getOrderList = params => ({
  type: GET_ORDER_LIST,
  payload: { params },
});

export const getInProgressOrderList = () => ({
  type: GET_IN_PROGRESS_ORDER_LIST,
});

export const getOrderListOk = response => ({
  type: GET_ORDER_LIST_OK,
  payload: { response },
});

export const getOrderListErr = error => ({
  type: GET_ORDER_LIST_ERR,
  payload: { error },
});

export const doOrderListCleanup = () => ({
  type: DO_ORDER_LIST_CLEANUP,
});

export const setOrderFilterToggle = status => ({
  type: SET_ORDER_FILTER_TOGGLE,
  payload: { status },
});

export const setViewModeToggle = mode => ({
  type: SET_VIEW_MODE_TOGGLE,
  payload: { mode },
});

export const setNotaryOrderFilterToggle = status => ({
  type: SET_NOTARY_ORDER_FILTER_TOGGLE,
  payload: { status },
});

/********** SINGLE **********/

export const getOrder = id => ({
  type: GET_ORDER,
  payload: { id },
});

export const getOrderOk = response => ({
  type: GET_ORDER_OK,
  payload: { response },
});

export const getOrderErr = error => ({
  type: GET_ORDER_ERR,
  payload: { error },
});

export const doOrderSingleCleanup = () => ({
  type: DO_ORDER_SINGLE_CLEANUP,
});

/********** MESSAGES **********/

export const getOrderMessages = (orderId, channelId, params) => ({
  type: GET_ORDER_MESSAGES,
  payload: { orderId, channelId, params }
});

export const getOrderMessagesOk = (response, channelId) => ({
  type: GET_ORDER_MESSAGES_OK,
  payload: { response, channelId }
});

export const getOrderMessagesErr = error => ({
  type: GET_ORDER_MESSAGES_ERR,
  payload: { error }
});

export const getLastOrderMessage = orderId => ({
  type: GET_LAST_ORDER_MESSAGE,
  payload: { orderId },
});

export const getLastOrderMessageOk = response => ({
  type: GET_LAST_ORDER_MESSAGE_OK,
  payload: { response },
});

export const getLastOrderMessageErr = error => ({
  type: GET_LAST_ORDER_MESSAGE_ERR,
  payload: { error },
});

export const getChatMembers = (orderId, channelId) => ({
  type: GET_CHAT_MEMBERS,
  payload: { orderId, channelId }
});

export const getChatMembersOk = (response, channelId) => ({
  type: GET_CHAT_MEMBERS_OK,
  payload: { response, channelId }
});

export const getChatMembersErr = error => ({
  type: GET_CHAT_MEMBERS_ERR,
  payload: { error }
});

/********** INK-SIGN DOCS **************/

export const getOrderInkSignDocs = id => ({
  type: GET_ORDER_INK_SIGN_DOCS,
  payload: { id },
});

export const getOrderInkSignDocsOk = response => ({
  type: GET_ORDER_INK_SIGN_DOCS_OK,
  payload: { response },
});

export const getOrderInkSignDocsErr = error => ({
  type: GET_ORDER_INK_SIGN_DOCS_ERR,
  payload: { error },
});

export const doOrderInkSignDocsCleanup = () => ({
  type: DO_ORDER_INK_SIGN_DOCS_CLEANUP,
});