import {
  GET_STATEMENT_DT,
  GET_STATEMENT_DT_OK,
  GET_STATEMENT_DT_ERR,
  DO_STATEMENT_DT_CLEANUP,
  APPLY_STATEMENT_DT_FILTERS,
  PATCH_STATEMENT_DT_FILTERS,
  CLEAR_STATEMENT_DT_FILTERS,
  UNSET_STATEMENT_DT_FILTERS,
} from "./actionTypes";

/********** DATATABLE **********/

export const getStatementDt = params => ({
  type: GET_STATEMENT_DT,
  payload: { params },
});

export const getStatementDtOk = response => ({
  type: GET_STATEMENT_DT_OK,
  payload: { response },
});

export const getStatementDtErr = error => ({
  type: GET_STATEMENT_DT_ERR,
  payload: { error },
});

export const doStatementDtCleanup = () => ({
  type: DO_STATEMENT_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyStatementDtFilters = filters => ({
  type: APPLY_STATEMENT_DT_FILTERS,
  payload: { filters },
});

export const patchStatementDtFilters = filters => ({
  type: PATCH_STATEMENT_DT_FILTERS,
  payload: { filters },
});

export const clearStatementDtFilters = () => ({
  type: CLEAR_STATEMENT_DT_FILTERS,
});

export const unsetStatementDtFilters = () => ({
  type: UNSET_STATEMENT_DT_FILTERS,
});