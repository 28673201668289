import {

	GET_LEADS_STATISTICS,
	GET_LEADS_STATISTICS_OK,
	GET_LEADS_STATISTICS_ERR,
	DO_LEADS_STATISTICS_CLEANUP,

	GET_CHATS,
	GET_CHATS_OK,
	GET_CHATS_ERR,
	DO_CHATS_CLEANUP,

	GET_IN_PROGRESS_ORDERS,
	GET_IN_PROGRESS_ORDERS_OK,
	GET_IN_PROGRESS_ORDERS_ERR,
	DO_IN_PROGRESS_ORDERS_CLEANUP,

} from "./actionTypes";
import {combineReducers} from "redux";

/********** LEADS STATISTICS **********/
const defaultStatistics = {
	statistics: null,
	isLoadInProgress: false,
	statisticsError: null,
}

export const Statistics = (state = defaultStatistics, action) => {
	switch (action.type) {
		case GET_LEADS_STATISTICS:
			state = {
				...state,
				isLoadInProgress: true,
				statisticsError: null
			};
			break
		case GET_LEADS_STATISTICS_OK:
			state = {
				...state,
				statistics: action.payload.response,
				isLoadInProgress: false
			};
			break
		case GET_LEADS_STATISTICS_ERR:
			state = {
				...state,
				statisticsError: action.payload.error,
				isLoadInProgress: false
			};
			break
		case DO_LEADS_STATISTICS_CLEANUP:
			state = { ...defaultStatistics };
			break
	}

	return state;
}

/********** CHATS **********/

const defaultChats = {
	chats: [],
	isLoadInProgress: false,
	chatsError: null
}

export const Chats = (state = defaultChats, action) => {
	switch (action.type){
		case GET_CHATS:
			state = {
				...state,
				isLoadInProgress: true,
				chatsError: null,
			};
			break
		case GET_CHATS_OK:
			state = {
				...state,
				chats: action.payload.response.chats,
				isLoadInProgress: false,
			};
			break
		case GET_CHATS_ERR:
			state = {
				...state,
				chats: [],
				isLoadInProgress: false,
				chatsError: action.payload.error
			};
			break
		case DO_CHATS_CLEANUP:
			state = { ...defaultChats };
			break
	}

	return state;
}

/********** IN PROGRESS ORDERS **********/

const defaultInProgressOrders = {
	orders: [],
	isLoadInProgress: false,
	ordersError: null,
	listParams: {
		limit: null
	},
}

export const ProgressOrders = (state = defaultInProgressOrders, action) => {
	switch (action.type){
		case GET_IN_PROGRESS_ORDERS:
			state = {
				...state,
				isLoadInProgress: true,
				ordersError: null,
				listParams: action.payload.params,
			};
			break
		case GET_IN_PROGRESS_ORDERS_OK:
			state = {
				...state,
				orders: action.payload.response.orders,
				isLoadInProgress: false
			};
			break
		case GET_IN_PROGRESS_ORDERS_ERR:
			state = {
				...state,
				isLoadInProgress: false,
				progressOrdersError: action.payload.error,
			};
			break
		case DO_IN_PROGRESS_ORDERS_CLEANUP:
			state = { ...defaultInProgressOrders };
			break
	}

	return state;
}

export default combineReducers({
	Statistics,
	Chats,
	ProgressOrders,
});
