import UserRole from "./userRole";

export default class User {

  static ACTIVITY_STATUS_OFFLINE = 0;
  static ACTIVITY_STATUS_IDLE = 1;
  static ACTIVITY_STATUS_ACTIVE = 2;

  static EMAIL_ALERT_GENERAL = 'General';
  static EMAIL_ALERT_MAIL_BOX_FULL = 'MailboxFull';
  static EMAIL_ALERT_ON_ACCOUNT_SUPPRESION_LIST = 'OnAccountSuppresionList';

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  static getActivityStatusMap() {
    return {
      [this.ACTIVITY_STATUS_OFFLINE]: 'Offline',
      [this.ACTIVITY_STATUS_IDLE]: 'Idle',
      [this.ACTIVITY_STATUS_ACTIVE]: 'Active',
    };
  }

  static getActivityStatusName(id) {
    return this.getActivityStatusMap()[id];
  }

  /***** ROLES *****/

  isMasterAdmin() {
    return UserRole.isMasterAdmin(this.userRoleId);
  }

  isAdmin() {
    return UserRole.isAdmin(this.userRoleId);
  }

  isScheduler() {
    return UserRole.isScheduler(this.userRoleId);
  }

  isDsc() {
    return UserRole.isDsc(this.userRoleId);
  }

  isAccountManager() {
    return UserRole.isAccountManager(this.userRoleId);
  }

  isLeadOps() {
    return UserRole.isLeadOps(this.userRoleId);
  }

  isDealerGroupManager() {
    return UserRole.isDealerGroupManager(this.userRoleId);
  }

  isDealerRegionalManager() {
    return UserRole.isDealerRegionalManager(this.userRoleId);
  }

  isDealerStoreManager() {
    return UserRole.isDealerStoreManager(this.userRoleId);
  }

  isDealerFinanceManager() {
    return UserRole.isDealerFinanceManager(this.userRoleId);
  }

  isDealerSalesManager() {
    return UserRole.isDealerSalesManager(this.userRoleId);
  }

  isDealerUpperManager() {
    return UserRole.isDealerUpperManager(this.userRoleId);
  }

  isDealerLowerManager() {
    return UserRole.isDealerLowerManager(this.userRoleId);
  }

  isDealerManager() {
    return UserRole.isDealerManager(this.userRoleId);
  }

  isDealerStoreUser() {
    return UserRole.isDealerStoreUser(this.userRoleId);
  }

  isDealerStoreLimitedUser() {
    return UserRole.isDealerStoreLimitedUser(this.userRoleId);
  }

  isNotary() {
    return UserRole.isNotary(this.userRoleId);
  }

  /***** ROLE TYPES *****/

  isAdminType() {
    return UserRole.isAdminType(this.userRoleId);
  }

  isDealerType() {
    return UserRole.isDealerType(this.userRoleId);
  }

  isNotaryType() {
    return UserRole.isNotaryType(this.userRoleId);
  }
}