import React, { useState, useEffect } from "react";
import FormExperience from "../Form/Experience";
import ViewExperience from "pages/Profile/View/Experience";
import FormContentExperience from "../Form/Content/Experience";
import FormEditCard from "../Form/Edit/Card";
import { useProfile } from "context/profile";

const SectionExperience = () => {

  const { isLoadInProgress, refreshProfile } = useProfile();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadInProgress) {
      setIsRefreshing(false);
    }
  }, [isLoadInProgress]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshProfile();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormExperience>
      <FormEditCard title={"Experience Information"} finishedHandler={toggleEditMode}>
        <FormContentExperience />
      </FormEditCard>
    </FormExperience>}
    {!isEditModeActive && <ViewExperience toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

export default SectionExperience;