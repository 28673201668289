import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, Button, CardTitle, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { useProfile } from "context/profile";
import { perms, useAccess } from "context/access";
import { getYesNoOptions } from "helpers/utilHelper";

const ViewExperience = ({ isRefreshing, toggleEditMode }) => {

  const { notary } = useProfile();

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Experience</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            {iAmGranted(perms.edit_notaries) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
              <i className="mdi mdi-pencil me-1" />Edit
            </Button>}
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
          <tr>
            <td className="section-row-label">Years of being a Notary</td>
            <td className="section-row-value ps-4">{notary.yearsOfExperience || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Comments</td>
            <td className="section-row-value ps-4">{notary.comments || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Are you a Full-Time Notary?</td>
            <td className="section-row-value ps-4">{getYesNoOptions().find(o => o.value == notary.isFullTime)?.label || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">How many years of experience in the automotive industry??</td>
            <td className="section-row-value ps-4">{notary.yearsOfIndustryExperience || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">If no, where do you work? <br />
              Company or Companies Name</td>
            <td className="section-row-value ps-4">{notary.otherCompanies || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Number of Loan Signings</td>
            <td className="section-row-value ps-4">{notary.numOfLoanSignings || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label no-border">Additional Languages</td>
            <td className="section-row-value ps-4 no-border">{notary.additionalLanguages || "---"}</td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
    {isRefreshing && <CardPreloader />}
  </Card>)
}

ViewExperience.propTypes = {
  isRefreshing: PropTypes.bool,
  toggleEditMode: PropTypes.func,
};

export default ViewExperience;