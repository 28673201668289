import { route, routes } from "helpers/routeHelper";
import Dashboard from "pages/Dashboard";
import ProfileSetupTerms from "pages/Profile/Setup/Terms";
import ProfileSetupBilling from "pages/Profile/Setup/Billing";
import ProfileSetupShipping from "pages/Profile/Setup/Shipping";
import ProfileSetupContact from "pages/Profile/Setup/Contact";
import ProfileSetupCredentials from "pages/Profile/Setup/Credentials";
import ProfileSetupExperience from "pages/Profile/Setup/Experience";
import ProfileSetupArea from "pages/Profile/Setup/Area";
import ProfileViewIndex from "pages/Profile/View/index";
import NotaryBidList from "pages/NotaryBid/List";
import NotaryBidView from "pages/NotaryBid/View";
import OrderList from "pages/Order/List";
import OrderView from "pages/Order/View";
import OrderViewMessages from "pages/Order/View/Messages";
import OrderViewFlow from "pages/Order/View/Flow";
import StatementList from "pages/Statement/List";
import StatementView from "pages/Statement/View";
import NotificationList from "pages/Notification/List";
import NotificationView from "pages/Notification/View";
import MessageList from "pages/Message/List";
import Documents from "pages/Documents";
import TermsView from "pages/Terms/View";
import MeetScheduler from "pages/Meeting/Scheduler";

const publicRoutes = [

];

const protectedRoutes = [
  { path: route(routes.setup_profile_terms), component: ProfileSetupTerms, isSetupRoute: true },
  { path: route(routes.setup_profile_billing), component: ProfileSetupBilling, isSetupRoute: true },
  { path: route(routes.setup_profile_shipping), component: ProfileSetupShipping, isSetupRoute: true },
  { path: route(routes.setup_profile_contact), component: ProfileSetupContact, isSetupRoute: true },
  { path: route(routes.setup_profile_credentials), component: ProfileSetupCredentials, isSetupRoute: true },
  { path: route(routes.setup_profile_experience), component: ProfileSetupExperience, isSetupRoute: true },
  { path: route(routes.setup_profile_area), component: ProfileSetupArea, isSetupRoute: true },

  { path: route(routes.home), component: Dashboard },

  { path: route(routes.view_profile), component: ProfileViewIndex },

  { path: route(routes.list_notary_bids), component: NotaryBidList },
  { path: route(routes.view_notary_bid), component: NotaryBidView },

  { path: route(routes.list_orders), component: OrderList },
  { path: route(routes.view_order), component: OrderView },
  { path: route(routes.view_order_messages), component: OrderViewMessages },
  { path: route(routes.view_order_flow), component: OrderViewFlow },

  { path: route(routes.list_statements), component: StatementList },
  { path: route(routes.view_statement), component: StatementView },

  { path: route(routes.list_notifications), component: NotificationList },
  { path: route(routes.view_notification), component: NotificationView },

  { path: route(routes.list_messages), component: MessageList },

  { path: route(routes.view_documents), component: Documents },
  { path: route(routes.view_terms), component: TermsView },

  { path: route(routes.meet_scheduler), component: MeetScheduler },
];

export { publicRoutes, protectedRoutes }
