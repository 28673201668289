export default class orderLeads {

  static STATUS_LEADS_RECEIVED = 0;
  static STATUS_LEADS_RESPONDED = 1;
  static STATUS_ORDERS_AWARDED = 2;


  static getStatusChart() {
    return {
      [this.STATUS_LEADS_RECEIVED]: 'Leads Received',
      [this.STATUS_LEADS_RESPONDED]: 'Leads Responded',
      [this.STATUS_ORDERS_AWARDED]: 'Orders Awarded',
    };
  }
}