import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { NavItem, NavLink } from "reactstrap";
import { route, routes } from 'helpers/routeHelper';
import classnames from "classnames";

const FormSteps = props => {

  /**
   * @param {string} currentStep
   * The key of the wizard step that is currently active
   * @param {function} setNavRoutes
   * The parent component does not know anything about the wizard steps
   * Se we use this function to inform the parent component about the routes used for back/next navigation
   * @param {string} className
   * Custom class name to be added to the component
   */
  const { currentStep, setNavRoutes, className } = props;

  const navigate = useNavigate();

  useEffect(() => {
    // set the 'prev' and 'next' routes based on the current step
    setNavRoutes(routes => ({
      next: steps[currentIndex() + 1]?.route || routes.next,
      prev: steps[currentIndex() - 1]?.route || routes.prev,
    }));
  }, [currentStep]);

  /********** WIZARD CONFIG **********/

  // the list of wizard steps to display
  const steps = [];
  steps.push({
    key: 'billing',
    name: 'Payment / Billing',
    route: routes.setup_profile_billing,
  });
  steps.push({
    key: 'shipping',
    name: 'Delivery',
    route: routes.setup_profile_shipping,
  });
  steps.push({
    key: 'contact',
    name: 'Contact',
    route: routes.setup_profile_contact,
  });
  steps.push({
    key: 'credentials',
    name: 'Credentials',
    route: routes.setup_profile_credentials,
  });
  steps.push({
    key: 'experience',
    name: 'Experience',
    route: routes.setup_profile_experience,
  });
  steps.push({
    key: 'area',
    name: 'Area Covered',
    route: routes.setup_profile_area,
  });

  const goToStep = index => navigate(route(steps[index].route));

  const currentIndex = () => steps.findIndex(s => s.key == currentStep);

  return <React.Fragment>
    <div className={classnames('steps clearfix pb-4', className)}>
      <ul>
        {steps.map((step, index) => {
          return <NavItem className={classnames({ current: currentStep === step.key })} key={index}>
            <NavLink className={classnames({ active: currentStep === step.key })} onClick={() => goToStep(index)} disabled={currentIndex() <= index}>
              <span className="number">{index + 1}</span> {step.name}
            </NavLink>
          </NavItem>
        })}
      </ul>
    </div>
  </React.Fragment>
}

FormSteps.propTypes = {
  currentStep: PropTypes.string.isRequired,
  setNavRoutes: PropTypes.func,
  className: PropTypes.string,
}

export default FormSteps;