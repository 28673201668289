import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from 'lodash';
import OrderLeads from "../../../model/orderLeads";
import {doGetLeadsStatisticsCleanup, getLeadsStatistics} from "../../../store/dashboard/actions";
import ChartPlaceholder from "./ChartPlaceholder";

/********** DONUT CHART CONFIG **********/

const statusChart = OrderLeads.getStatusChart();
const labels = Object.values(statusChart);

const barChartOptions = {
	colors: ["#556ee6", "#34C38F", "#F1B44C"],
	tooltip: {
		enabled: false,
	},
	dataLabels: {
		enabled: false,
	},
	chart: {
		height: 350,
		id: "bar-chart",
		offsetX: 10,
		offsetY: 30,
		toolbar: {
			show: false
		},
	},
	plotOptions: {
		bar: {
			borderRadius: 5,
			columnWidth: 15,
			distributed: true,
		},
	},
	legend: {
		show: true,
		position: "right",
		horizontalAlign: "center",
		verticalAlign: "center",
		floating: false,
		fontSize: "13px",
	},
	xaxis: {
		show: false,
	},
	yaxis: {
		categories: ["0", "10", "20", "30", "40", "50", "1h", "2h"], // Categories on the y-axis
		position: 'left',
		axisBorder: {
			show: false,
		},
		axisTicks: {
			show: false,
		},
		labels: {
			show: true,
			style: {
				colors: "#333",
				fontSize: "12px",
				fontWeight: 500,
			},
		},
	},
	responsive: [
		{
			breakpoint: 992,
			options: {
				legend: {
					show: true,
					position: "bottom",
					horizontalAlign: "center",
					floating: false,
					fontSize: "10px",
				},
				chart: {
					offsetX: 0,
				},
				plotOptions: {
					bar: {
						columnWidth: '15px',
					},
				},
			},
		},
	],
};

const Statistics = () => {

	const dispatch = useDispatch();

	/********** STATE **********/
	const [activeButton, setActiveButton] = useState("all");
	const { statistics, isLoadInProgress } = useSelector(state => state.Dashboard.Statistics);

	const series = (!isLoadInProgress && !isEmpty(statistics)) ? Object.values(statistics) : new Array(5).fill(0);

	const hasOrders = !isLoadInProgress && !isEmpty(statistics) && Object.values(statistics).some(value => value !== 0);

	/********** CHART CONFIG **********/

	const options = {
		...barChartOptions,
		labels: labels.map((label, index) => `${label} (${series[index]})`)
	};

	/********** HANDLERS **********/
	const toggle = btn => {
		if (activeButton !== btn) {
			setActiveButton(btn);
		}
	};
	/********** EFFECTS **********/

	// runs once on component mount and whenever the unit changes
	useEffect(() => {
		dispatch(getLeadsStatistics(activeButton));
		return () => {
			dispatch(doGetLeadsStatisticsCleanup());
		}
	}, [activeButton]);

	return (
		<Card className="statistics-card">
			<Row>
				<Col xs={12} lg={6}>
						<CardTitle>Statistics</CardTitle>
				</Col>
				<Col xs={12} lg={6} className='statistics-buttons-wrapper'>
					<a className={`${activeButton === 'all' ? 'active' : ''} statistics-btn`} onClick={() => { toggle("all")}}>All</a>
					<a className={`${activeButton === '1m' ? 'active' : ''} statistics-btn`} onClick={() => { toggle("1m")}}> 1M</a>
					<a className={`${activeButton === '6m' ? 'active' : ''} statistics-btn`} onClick={() => { toggle("6m")}}>6M</a>
					<a className={`${activeButton === '1y' ? 'active' : ''} statistics-btn`} onClick={() => { toggle("1y")}}>1Y</a>
				</Col>
			</Row>
			<CardBody className="pt-0">
				<Row>
					<Col xs={12} lg={12}>
						{hasOrders ? <ReactApexChart
							options={options}
							series={[{data: series}]}
							type="bar"
							height={350}
						/> : <div className='mt-4'><ChartPlaceholder /></div>}
					</Col>
				</Row>
			</CardBody>
	</Card>)
}

export default Statistics;