import React from "react";
import PropTypes from "prop-types"
import { useCountdown } from "hooks/useCountdown";

const addLeadingZero = value => value < 10 ? `0${value}` : value;

const CountdownTimer = ({ targetDate }) => {

  const [hours, minutes, seconds] = useCountdown(targetDate);

  if (hours + minutes + seconds <= 0) {
    return <span>--</span>;
  }

  return (
    <React.Fragment>
      <p className="m-0">Lead expires in </p>
      <div className="show-counter">
        <div className="countdown">
          <p>{addLeadingZero(minutes)}</p>
        </div>
        <p>:</p>
        <div className="countdown">
          <p>{addLeadingZero(seconds)}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

CountdownTimer.propTypes = {
  targetDate: PropTypes.number,
}

export default CountdownTimer;
