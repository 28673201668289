import {
	GET_LEADS_STATISTICS,
	GET_LEADS_STATISTICS_ERR,
	GET_LEADS_STATISTICS_OK,
	DO_LEADS_STATISTICS_CLEANUP,

	GET_CHATS,
	GET_CHATS_OK,
	GET_CHATS_ERR,
	DO_CHATS_CLEANUP,

	GET_IN_PROGRESS_ORDERS,
	GET_IN_PROGRESS_ORDERS_OK,
	DO_IN_PROGRESS_ORDERS_CLEANUP,
	GET_IN_PROGRESS_ORDERS_ERR,

} from "./actionTypes";

/********** LEADS STATISTICS **********/

export const getLeadsStatistics = unit => ({
	type: GET_LEADS_STATISTICS,
	payload: { unit }
});

export const getLeadsStatisticsOk = response => ({
	type: GET_LEADS_STATISTICS_OK,
	payload: { response },
});

export const getLeadsStatisticsErr = error => ({
	type: GET_LEADS_STATISTICS_ERR,
	payload: { error },
});

export const doGetLeadsStatisticsCleanup = () => ({
	type: DO_LEADS_STATISTICS_CLEANUP,
});

/********** CHATS **********/

export const getChats = () => ({
	type: GET_CHATS,
});

export const getChatsOk = response => ({
	type: GET_CHATS_OK,
	payload: { response },
});

export const getChatsErr = error => ({
	type: GET_CHATS_ERR,
	payload: { error },
});

export const doGetChatsCleanup = () => ({
	type: DO_CHATS_CLEANUP,
});

/********** IN PROGRESS ORDERS **********/

export const getInProgressOrders = params => ({
	type: GET_IN_PROGRESS_ORDERS,
	payload: { params },
});

export const getInProgressOrdersOk = response => ({
	type: GET_IN_PROGRESS_ORDERS_OK,
	payload: { response },
});

export const getInProgressOrdersErr = error => ({
	type: GET_IN_PROGRESS_ORDERS_ERR,
	payload: { error },
});

export const doInProgressOrdersCleanup = () => ({
	type: DO_IN_PROGRESS_ORDERS_CLEANUP,
});