import React, { useEffect } from "react";
import { Col, Row, Form, Label, Input, FormFeedback } from "reactstrap";
import { useFormikContext } from "formik";
import { showError, showSuccess } from "helpers/utilHelper";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "context/profile";
import { resetSaveProfileActionFlag } from "store/actions";

const FormContentContact = () => {

  const dispatch = useDispatch();
  const { refreshProfile } = useProfile();

  // consume formik instance from context
  const formik = useFormikContext();

  // saved - TRUE if a save was attempted and was successful, FALSE if it failed, NULL if no save was attempted yet
  const { saved } = useSelector(state => state.Profile.Form);

  /********** EFFECTS **********/

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess("Contact info saved");
      refreshProfile();
    } else if (saved === false) {
      showError("Unable to save contact info");
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag("saved"));
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <Form>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Phone*</Label>
      <Col sm={8}>
        <Input type="text" className="form-control" placeholder="ex. 9094105017" name="phone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone} invalid={!!formik.errors.phone} />
        {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Email Address*</Label>
      <Col sm={8}>
        <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="email" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.email} invalid={!!formik.errors.email} />
        {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Website</Label>
      <Col sm={8}>
        <Input type="text" className="form-control" placeholder="ex. www.domain.com" name="website" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.website} invalid={!!formik.errors.website} />
        {!!formik.errors.website && <FormFeedback type="invalid">{formik.errors.website}</FormFeedback>}
      </Col>
    </Row>
  </Form>
}

export default FormContentContact;