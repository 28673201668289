import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, Button, CardTitle, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { useProfile } from "context/profile";
import { perms, useAccess } from "context/access";
import { distanceServedOptions } from "helpers/utilHelper";

const ViewShipping = ({ isRefreshing, toggleEditMode }) => {

  const { notary } = useProfile();

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Delivery Information</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            {iAmGranted(perms.edit_notaries) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
              <i className="mdi mdi-pencil me-1" />Edit
            </Button>}
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
        <tr>
          <td className="section-row-label">Document Delivery Address</td>
          <td className="section-row-value ps-4">{notary.shippingAddress || "---"}</td>
        </tr>
        <tr>
          <td className="section-row-label">Delivery State</td>
          <td className="section-row-value ps-4">{notary.shippingState || "---"}</td>
        </tr>
        <tr>
          <td className="section-row-label">Delivery City</td>
          <td className="section-row-value ps-4">{notary.shippingCity || "---"}</td>
        </tr>
        <tr>
          <td className="section-row-label no-border">Delivery ZIP</td>
          <td className="section-row-value ps-4 no-border">{notary.shippingZip || "---"}</td>
        </tr>
        <tr>
          <td className="section-row-label no-border">Distance willing to travel</td>
          <td className="section-row-value ps-4 no-border">{distanceServedOptions.find(o => o.value === notary.distanceServed)?.label || "---"}</td>
        </tr>
        <tr>
          <td className="section-row-label no-border">Cities / Counties Served</td>
          <td className="section-row-value ps-4 no-border">{notary.citiesCountiesServed || "---"}</td>
        </tr>
        </tbody>
      </Table>
    </CardBody>
    {isRefreshing && <CardPreloader />}
  </Card>)
}

ViewShipping.propTypes = {
  isRefreshing: PropTypes.bool,
  toggleEditMode: PropTypes.func,
};

export default ViewShipping;