import { combineReducers } from "redux";
import {
  GET_PROFILE,
  GET_PROFILE_OK,
  GET_PROFILE_ERR,
  AGREE_TO_PROFILE_TERMS,
  SAVE_PROFILE_BILLING,
  SAVE_PROFILE_SHIPPING,
  SAVE_PROFILE_CONTACT,
  SAVE_PROFILE_CREDENTIALS,
  SAVE_PROFILE_EXPERIENCE,
  SAVE_PROFILE_AREA,
  SAVE_PROFILE_OK,
  SAVE_PROFILE_ERR,
  DO_SAVE_PROFILE_CLEANUP,
  RESET_SAVE_PROFILE_ACTION_FLAG,
} from "./actionTypes";

/********** GET PROFILE **********/

const defaultSingleState = {
  // we use a default value of 'undefined' to distinguish between the case when:
  // 1. the notary has not been fetched yet (undefined)
  // 2. the notary has been fetched but it does not exist (null)
  notary: undefined,
  notaryError: null,
  isLoadInProgress: false,
}

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      state = {
        ...state,
        notaryError: null,
        isLoadInProgress: true,
      };
      break
    case GET_PROFILE_OK:
      state = {
        ...state,
        notary: action.payload.response.notary,
        isLoadInProgress: false,
      };
      break
    case GET_PROFILE_ERR:
      state = {
        ...state,
        notary: null,
        notaryError: action.payload.error,
        isLoadInProgress: false,
      };
      break
  }
  return state;
}

/********** SAVE PROFILE **********/

const defaultFormState = {
  saved: null,
  saveError: null,
  isSaveInProgress: false,
}

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case AGREE_TO_PROFILE_TERMS:
    case SAVE_PROFILE_BILLING:
    case SAVE_PROFILE_SHIPPING:
    case SAVE_PROFILE_CONTACT:
    case SAVE_PROFILE_CREDENTIALS:
    case SAVE_PROFILE_EXPERIENCE:
    case SAVE_PROFILE_AREA:
      state = {
        ...state,
        saveError: null,
        isSaveInProgress: true,
      };
      break
    case SAVE_PROFILE_OK:
      state = {
        ...state,
        saved: true,
        isSaveInProgress: false,
      };
      break
    case SAVE_PROFILE_ERR:
      state = {
        ...state,
        saved: false,
        saveError: action.payload.error,
        isSaveInProgress: false,
      };
      break
    case DO_SAVE_PROFILE_CLEANUP:
      state = { ...defaultFormState };
      break
    case RESET_SAVE_PROFILE_ACTION_FLAG:
      if (!state.hasOwnProperty(action.payload.flag)) {
        break;
      }
      state = {
        ...state,
        [action.payload.flag]: null,
      };
      break
  }
  return state;
}

export default combineReducers({
  Single,
  Form,
})
