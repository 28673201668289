import React, { useCallback, useEffect } from "react";
import { Card, CardBody, CardTitle, CardFooter, Button, Alert } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { route, routes } from "helpers/routeHelper";
import { doGetChatsCleanup, getChats } from "../../../../store/dashboard/actions";
import { getInitialsFromName } from "../../../../helpers/utilHelper";
import UserAvatar from "../../../../components/Shared/UserAvatar";
import { useSocketOn, useSubscribeToOrderMessages } from "../../../../hooks/socket";
import socketEvent from "../../../../constants/socketEvent";
import Message from "../../../../model/message";
import SpinnerChase from "../../../../components/Shared/SpinnerChase";

const ChatsMobile = () => {

  /********** OTHERS **********/
  const dispatch = useDispatch();

  const { chats, isLoadInProgress, chatsError } = useSelector(state => state.Dashboard.Chats);

  const ordersLimit = 10;

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = useCallback(() => {
    dispatch(getChats({ pageSize: ordersLimit }));
  }, []);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getChats(ordersLimit));
    return () => {
      dispatch(doGetChatsCleanup());
    }
  }, []);

  return <>
    <Card className="messages-card">
      <CardTitle>Chats</CardTitle>
      <CardBody className="p-0">
        {!isLoadInProgress && !chatsError && chats.map((entry, index) => <Link key={index} className="mobile-card-row" to={route(routes.view_order, entry.orderId)}>
          <div className="d-flex">
            <div className="align-self-center me-3">
              <UserAvatar ignoreImage initials={getInitialsFromName(Message.getChannelName(entry.channel))} size="sm" className="mt-n2" />
            </div>
            <div className="flex-grow-1 overflow-hidden my-auto">
              <h5 className="mobile-card-row-name text-truncate font-size-14 mb-1">
                {Message.getChannelName(entry.channel)}
              </h5>
              <div className="mobile-card-row-content text-truncate">{entry.content}</div>
            </div>
          </div>
        </Link>)}
        {!isLoadInProgress && !chatsError && chats.length === 0 && <p>No messages found</p>}
        {isLoadInProgress && <SpinnerChase />}
        {chatsError && <Alert color="danger" className="fade show text-center mb-0"><i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load messages</Alert>}
      </CardBody>
      <CardFooter className="bg-transparent d-flex justify-content-end pt-0">
        <a href={route(routes.list_messages)}>
          <Button color="light" className="text-primary">
            View All <i className="bx bx-right-arrow-alt" />
          </Button>
        </a>
      </CardFooter>
    </Card>
  </>
}

export default ChatsMobile;