// socket events
// they need to match the back-end values
export default {
  notificationReceived: 'notificationReceived',
  subscribeToOrderMessages: 'subscribeToOrderMessages',
  unsubscribeFromOrderMessages: 'unsubscribeFromOrderMessages',
  messageReceived: 'messageReceived',
  subscribeToUser: 'subscribeToUser',
  unsubscribeFromUser: 'unsubscribeFromUser',
  userActivityStatusChanged: 'userActivityStatusChanged',
  subscribeToOrderSigner: 'subscribeToOrderSigner',
  unsubscribeFromOrderSigner: 'unsubscribeFromOrderSigner',
  orderSignerActivityStatusChanged: 'orderSignerActivityStatusChanged',
  notaryBidChanged: 'notaryBidChanged',
  appointmentStatusChanged: 'appointmentStatusChanged'
}