import React from "react";
import PropTypes from "prop-types";
import dealershipIcon from "assets/images/dealership-icon.svg";
import { formatTimestamp, formats } from "helpers/dateHelper";

const Dealership = ({order}) => {

  return (<div className="order-info text-center">
    <div className="order-info-title mb-1">
      <img className="me-2" src={dealershipIcon} />
      <span>Dealership</span>
    </div>
    <div className="order-info-subtitle is-dealership mb-1">{order.dealerStoreName}</div>
    <div className="order-info-description">Date Ordered: {formatTimestamp(order.createdTs, formats.DATETIME)}</div>
    <div className="divider-faded my-3" />
  </div>)
}

Dealership.propTypes = {
  order: PropTypes.object,
};

export default Dealership;