import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Label, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { useFormik } from "formik";
import { hasNonEmpty, extractDtFiltersFromUrl } from "helpers/utilHelper";
import { applyStatementDtFilters, clearStatementDtFilters, patchStatementDtFilters, unsetStatementDtFilters } from "store/actions";
import { isEmpty, omit } from "lodash";
import DateRangePicker from "components/Shared/DateRangePicker";
import * as Yup from "yup";

const Filters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.Statement.DtFilters);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  // range state is only for helping us build the createdBetween filter
  // we won't keep it in redux state
  const formInitialValues = { ...filters, range: filters.createdBetween ? filters.createdBetween.split("-") : [] };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      range: Yup.array().required("Field is required").min(2),
    }),
    initialValues: formInitialValues,
    onSubmit: values => {
      applyFilters({ ...omit(values, "range"), createdBetween: values.range.join("-") })
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();
    // if url filters are not empty
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchStatementDtFilters(filters));
    }

    return () => {
      // switch back the '_set' flag
      dispatch(unsetStatementDtFilters());
    }
  }, []);

  /********** EVENT HANDLERS **********/

  const handleChangeRange = selected => {
    formik.setFieldValue("range", selected);
  };

  const handleClearRange = () => {
    formik.setFieldValue("range", []);
  };

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyStatementDtFilters(values));

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearStatementDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(formInitialValues);

  const hasFilters = () => hasNonEmpty(omit(filters, "_set"));

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Generated Between</Label>
            <DateRangePicker
              onChange={handleChangeRange}
              value={formik.values.range}
              onClear={handleClearRange}
            />
            {!!formik.errors.range && <FormFeedback type="invalid">{formik.errors.range}</FormFeedback>}
          </div>

          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default Filters;