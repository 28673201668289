import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doOrderInkSignDocsCleanup, getOrderInkSignDocs } from "store/order/actions";
import { returnDocToDealer, returnAllDocsToDealer } from "helpers/backendHelper";
import { showError, showSuccess } from "helpers/utilHelper";
import OrderNotary from "model/orderNotary";
import Order from "model/order";
import { BrowserView, MobileView } from "react-device-detect";
import ReturnToDealershipSectionDesktop from "./Desktop/ReturnToDealership";
import ReturnToDealershipSectionMobile from "./Mobile/ReturnToDealership";

const StepReturnToDealership = () => {

  /********** STATE **********/

  const dispatch = useDispatch();

  const { order } = useSelector(state => state.Order.Single);
  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);

  const isReadOnly = () => order.status == Order.STATUS_CANCELLED || order.orderNotaryStatus == OrderNotary.STATUS_ORDER_COMPLETE;

  const isCurrentStep = !(order.orderNotaryStatus !== OrderNotary.STATUS_DOCS_UPLOADED) && !isReadOnly();

  /********** EFFECTS **********/

  useEffect(() => {
    dispatch(getOrderInkSignDocs(order.id));
    return () => {
      dispatch(doOrderInkSignDocsCleanup());
    }
  }, []);

  /********** HANDLERS **********/
  const handleShipDoc = (docId) => {
    returnDocToDealer(order.orderNotaryId, docId).
    then(() => {
      showSuccess("Document sent");
      refreshDocs();
    }).catch(() => {
      showError("Unable to sent doc");
    })
  }

  const refreshDocs = () => dispatch(getOrderInkSignDocs(order.id));

  const getDocsStatus = (returnedNotaryTs) => {

    if(order.orderNotaryStatus === OrderNotary.STATUS_ORDER_COMPLETE) return 'Returned to dealership';

    if (order.orderNotaryStatus === OrderNotary.STATUS_DOCS_UPLOADED) {
      if (returnedNotaryTs) return 'Document Shipped';
      return 'Signed';
    }

    return 'Pending Signature';
  }

  const handleAllShipDocs = (docId) => {
    returnAllDocsToDealer(order.orderNotaryId, docId)
    .then(() => {
      showSuccess("Documents sent");
      refreshDocs();
    })
    .catch(() => {
      showError("Unable to send docs");
    });
  }

  return (<React.Fragment>
    {/* Desktop Section */}
    <BrowserView>
      <ReturnToDealershipSectionDesktop inkSignDocs={inkSignDocs}
        isCurrentStep={isCurrentStep}
        order={order}
        onHandleAllShipDocs={(docsId) => handleAllShipDocs(docsId)}
        onHandleShipDoc={(docId) => handleShipDoc(docId)}
        onGetDocsStatus = {(returnedTs) => getDocsStatus(returnedTs)}
      />
    </BrowserView>
    {/* Mobile Section */}
    <MobileView>
      <ReturnToDealershipSectionMobile inkSignDocs={inkSignDocs}
        isCurrentStep={isCurrentStep}
        order={order}
        onHandleAllShipDocs={(docsId) => handleAllShipDocs(docsId)}
        onHandleShipDoc={(docId) => handleShipDoc(docId)}
        onGetDocsStatus = {(returnedTs) => getDocsStatus(returnedTs)}
      />
    </MobileView>
  </React.Fragment>
  )
}

export default StepReturnToDealership;