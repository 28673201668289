import { perms, useAccess } from "context/access";
import Message from "model/message";

const useAllowedChannels = () => {
  const { iAmGranted } = useAccess();

  const allChannelMap = Message.getChannelMap();

  return Object.keys(allChannelMap).reduce((allowedChannelMap, channel) => {
    const channelId = parseInt(channel);
    if (iAmGranted(perms.view_message_channel, channelId)) {
      allowedChannelMap[channelId] = allChannelMap[channelId];
    }
    return allowedChannelMap;
  }, {})
};

export default useAllowedChannels;