import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useProfile } from "context/profile";

const useProfileCredentialsNotComplete = () => {

  const navigate = useNavigate();

  const { notary } = useProfile();

  useEffect(() => {
    // if the user did not complete the credentials step
    if (notary.canSignElectronically === null) {
      // redirect back to the credentials step
      navigate(route(routes.setup_profile_credentials));
      return;
    }
  }, []);
}

export default useProfileCredentialsNotComplete;