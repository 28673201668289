import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, UncontrolledTooltip } from "reactstrap";
import NoImg from "components/Shared/NoImg";
import { Item } from "react-photoswipe-gallery";
import DocPreviewModal from "./PreviewModal";
import { uploadOrderDocPage, clearOrderDocPage } from "helpers/backendHelper";
import { getBeUrl, showError, toBase64 } from "helpers/utilHelper";
import AddImg from "components/Shared/AddImg";
import classnames from "classnames";
import OrderDoc from "model/orderDoc";
import { UPLOADED_FILE_TYPE_NOT_ALLOWED, ValidationException } from "helpers/errorHelper";
import { timestamp } from "helpers/dateHelper";

const DocPageDesktop = ({ docId, pageNum, isPageUploaded, isNewPage, finishedHandler, disabled, pages, setHasInProgressUploads }) => {

  const inputRef = useRef();

  const imageUrl = getBeUrl(`order-doc/${docId}/ink-sign/page/${pageNum}.png?${timestamp()}`);

  const pageData = !!pages && pages[pageNum] ? pages[pageNum] : {};

  const canRemovePage = pageData.status != OrderDoc.PAGE_STATUS_ACCEPTED;

  const [isUploading, setIsUploading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  const addPage = async event => {
    if (event.target.files[0]) {
      const formData = new FormData();
      formData.append("pageImg", event.target.files[0]);
      setIsUploading(true);
      setHasInProgressUploads(true);
      try {
        await uploadOrderDocPage(formData, docId, pageNum);
        finishedHandler();
      } catch (err) {
        let errMessage = 'Unable to upload page';
        if (err instanceof ValidationException && err.fields?.pageImg == UPLOADED_FILE_TYPE_NOT_ALLOWED) {
          errMessage = 'Unknown format, please upload jpeg or png';
        }
        showError(errMessage);
      } finally {
        setIsUploading(false);
        setHasInProgressUploads(false);
      }
      // clear the name of the file from the upload input
      // otherwise, the browser won't allow the user to upload two images with the same name consecutively
      event.target.value = null;
    }
  };

  const clearPage = async () => {
    setIsClearing(true);
    try {
      await clearOrderDocPage(docId, pageNum);
      finishedHandler();
    } catch (err) {
      showError("Unable to delete page");
    } finally {
      setIsClearing(false);
    }
  };

  return (
    <div className="doc-page">
      {!isPageUploaded && (
        <>
          <div className={classnames("doc-page-img", { "pe-none": disabled })} onClick={() => inputRef.current.click()}>
            {!isUploading && (isNewPage ? <AddImg /> : <NoImg />)}
            {isUploading && <i className="mdi mdi-spin mdi-loading"></i>}
          </div>
          <input
            ref={inputRef}
            type="file"
            className="d-none"
            accept="image/png, image/jpeg"
            onChange={addPage}
          />
          <Button
            className="w-100"
            color={isNewPage ? "primary" : "success"}
            onClick={() => inputRef.current.click()}
            disabled={disabled}
          >
            {/* isNewPage flags that we have a brand new page to be inserted; if isNewPage is false, it means we are editing a page that already exists */}
            {isNewPage ? <>Add Page <i className="mdi mdi-plus-circle-outline" /></> : <>Add Photo <i className="mdi mdi-check-circle-outline" /></>}
          </Button>
        </>
      )}
      {isPageUploaded && (
        <>
          <div className={classnames("doc-page-img", { 'is-rejected': pageData.status == OrderDoc.PAGE_STATUS_REJECTED, 'is-accepted': pageData.status == OrderDoc.PAGE_STATUS_ACCEPTED })}>
            <Item content={<DocPreviewModal image={imageUrl} />}>
              {({ ref, open }) => (
                <img alt="ink-signed page" src={imageUrl} className="ink-sign-page-img" ref={ref} onClick={open} />
              )}
            </Item>
            {pageData.status == OrderDoc.PAGE_STATUS_REJECTED && <React.Fragment>
              <div className="badge badge-lg rounded-pill bg-danger review-badge" id={'doc-page-rejected-' + docId + '-' + pageNum} title="">
                <i className="bx bxs-error me-1"></i>Rejected
              </div>
              {!!pageData.rejectionReason && <UncontrolledTooltip placement="top" target={'doc-page-rejected-' + docId + '-' + pageNum}>{pageData.rejectionReason}</UncontrolledTooltip>}
            </React.Fragment>}
            {pageData.status == OrderDoc.PAGE_STATUS_ACCEPTED && <React.Fragment>
              <div className="badge badge-lg rounded-pill bg-success review-badge">
                <i className="bx bxs-check-circle me-1"></i>Accepted
              </div>
            </React.Fragment>}
          </div>

          {canRemovePage && <Button
            className="w-100"
            color="danger"
            onClick={clearPage}
            disabled={disabled || isClearing}
          >
            Delete Photo <i className="mdi mdi-close-circle-outline" />
          </Button>}
        </>
      )}
    </div>
  )
};

DocPageDesktop.propTypes = {
  docId: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  isPageUploaded: PropTypes.bool.isRequired,
  isNewPage: PropTypes.bool,
  finishedHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  pages: PropTypes.object,
  setHasInProgressUploads: PropTypes.func,
};

export default DocPageDesktop;