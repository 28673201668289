import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";


const InstructionsAccordeon = ({instructions}) => {

  /********** STATES **********/
  const [open, setOpen] = useState("instructions");

  /********** HANDLERS **********/
  const toggle = id => {
    if (open == id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return(<Accordion className="instructions-accordion" open={open} toggle={toggle}>
    <AccordionItem key={'instructions'}>
      <AccordionHeader targetId={'instructions'}>Instructions</AccordionHeader>
      <AccordionBody accordionId={'instructions'}>
        {instructions.map((row, index) => <div key={index} className="instructions-row mb-3">
          <img src={row.image} />
          <div className="ms-2">{row.description}</div>
        </div>)}
      </AccordionBody>
    </AccordionItem>
  </Accordion>)
}

InstructionsAccordeon.propTypes = {
  instructions: PropTypes.array,
};

export default InstructionsAccordeon;