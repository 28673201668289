import React, { useEffect } from "react";
import { Col, Row, Form, Label, Input, FormFeedback } from "reactstrap";
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";
import { useFormikContext } from "formik";
import { getYesNoOptions, showError, showSuccess } from "helpers/utilHelper";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "context/profile";
import { resetSaveProfileActionFlag } from "store/actions";
import classnames from "classnames";

const FormContentExperience = () => {

  const dispatch = useDispatch();
  const { refreshProfile } = useProfile();

  // consume formik instance from context
  const formik = useFormikContext();

  // saved - TRUE if a save was attempted and was successful, FALSE if it failed, NULL if no save was attempted yet
  const { saved } = useSelector(state => state.Profile.Form);

  /********** EFFECTS **********/

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess("Experience info saved");
      refreshProfile();
    } else if (saved === false) {
      showError("Unable to save experience info");
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag("saved"));
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <Form>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Years of being a Notary</Label>
      <Col sm={8}>
        <Input type="number" min={0} className="form-control" name="yearsOfExperience" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.yearsOfExperience} invalid={!!formik.errors.yearsOfExperience} />
        {!!formik.errors.yearsOfExperience && <FormFeedback type="invalid">{formik.errors.yearsOfExperience}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Comments</Label>
      <Col sm={8}>
        <TextareaAutosize maxRows={15} className={classnames("form-control", { "is-invalid": !!formik.errors.comments })} name="comments" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.comments} />
        {!!formik.errors.comments && <FormFeedback type="invalid">{formik.errors.comments}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Are you a Full-Time Notary?*</Label>
      <Col sm={8}>
        <Select
          classNamePrefix="select2-selection"
          name="isFullTime"
          options={getYesNoOptions()}
          onChange={selected => formik.setFieldValue("isFullTime", !!selected.value)}
          onFocus={e => onFieldFocused(e, "isFullTime")}
          value={getYesNoOptions().find(option => option.value == formik.values.isFullTime)}
          className={!!formik.errors.isFullTime && "is-invalid"} />
        {!!formik.errors.isFullTime && <FormFeedback type="invalid">{formik.errors.isFullTime}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">How many years of experience in the automotive industry?*</Label>
      <Col sm={8}>
        <Input type="number" min={0} className="form-control" name="yearsOfIndustryExperience" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.yearsOfIndustryExperience} invalid={!!formik.errors.yearsOfIndustryExperience} />
        {!!formik.errors.yearsOfIndustryExperience && <FormFeedback type="invalid">{formik.errors.yearsOfIndustryExperience}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">If no, where do you work? <br />
        Company or Companies Name</Label>
      <Col sm={8}>
        <Input type="text" className="form-control" name="otherCompanies" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.otherCompanies} invalid={!!formik.errors.otherCompanies} />
        {!!formik.errors.otherCompanies && <FormFeedback type="invalid">{formik.errors.otherCompanies}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Number of Loan Signings</Label>
      <Col sm={8}>
        <Input type="number" min={0} className="form-control" name="numOfLoanSignings" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.numOfLoanSignings} invalid={!!formik.errors.numOfLoanSignings} />
        {!!formik.errors.numOfLoanSignings && <FormFeedback type="invalid">{formik.errors.numOfLoanSignings}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Additional Languages*</Label>
      <Col sm={8}>
        <Input type="text" className="form-control" name="additionalLanguages" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.additionalLanguages} invalid={!!formik.errors.additionalLanguages} />
        {!!formik.errors.additionalLanguages && <FormFeedback type="invalid">{formik.errors.additionalLanguages}</FormFeedback>}
      </Col>
    </Row>
  </Form>
}

export default FormContentExperience;