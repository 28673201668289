import React, { useState } from "react";
import PropTypes from "prop-types";
import vehicleIcon from "assets/images/car-icon.svg";
import { Row, Col, Button } from "reactstrap";
import classnames from "classnames";


const VehicleInformation = ({order}) => {

  /********** STATE **********/
  const [isExpanded, setIsExpanded] = useState(false);

  /********** OTHER **********/

  const hasAdditionalVehicles = order?.vehicles?.length > 1;

  const canExpand = hasAdditionalVehicles && !isExpanded;

  const canCollapse = hasAdditionalVehicles && isExpanded;

  /********** HANDLERS **********/

  const toggleExpanded = () => setIsExpanded(current => !current);

  return (<div className="order-info text-center">
    <div className="order-info-title mb-1">
      <img className="me-2" src={vehicleIcon} />
      <span>Vehicle information</span>
    </div>
    <div className="order-info-subtitle mb-1">Vehicle Year, Make, Model: {order.vehicles[0].yearMakeModel || "--"}</div>
    <div className="order-info-description">VIN#: {order.vehicles[0].vin}</div>

    {canExpand && <Button type="button" color="link" size="sm" className="ps-0" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1" />View more</Button>}
    {canCollapse && <Button type="button" color="link" size="sm" className="ps-0" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1" />View less</Button>}

    {isExpanded && (
      <div className="py-2">
        <h6>Additional Vehicles</h6>
        {order.vehicles.slice(1).map((vehicle, i) => (
          <div key={i} className={classnames("p-2", { "bg-primary bg-soft": i % 2 === 0 })}>
            <h6 className="mb-2">Vehicle {i + 2}</h6>
            <Row>
              <Col xs={12} lg={4} className="mb-1">
                <span className="me-2">Vehicle Year, Make, Model:</span>
                <strong>{vehicle.yearMakeModel || "--"}</strong>
              </Col>
              <Col xs={12} lg={4} className="mb-1">
                <span className="me-2">VIN#:</span>
                <strong>{vehicle.vin}</strong>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    )}
    <div className="divider-faded my-3" />
  </div>)
}

VehicleInformation.propTypes = {
  order: PropTypes.object,
};

export default VehicleInformation;