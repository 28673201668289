import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useProfile } from 'context/profile';

const useProfileShippingNotComplete = () => {

  const navigate = useNavigate();

  const { notary } = useProfile();

  useEffect(() => {
    // if the user did not complete the shipping step
    if (!notary.shippingAddress) {
      // redirect back to the shipping step
      navigate(route(routes.setup_profile_shipping));
      return;
    }
  }, []);
}

export default useProfileShippingNotComplete;