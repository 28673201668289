/********** LEADS STATISTICS **********/

export const GET_LEADS_STATISTICS = "GET_LEADS_STATISTICS"
export const GET_LEADS_STATISTICS_OK = "GET_LEADS_STATISTICS_OK"
export const GET_LEADS_STATISTICS_ERR = "GET_LEADS_STATISTICS_ERR"
export const DO_LEADS_STATISTICS_CLEANUP = "DO_LEADS_STATISTICS_CLEANUP"

/********** CHATS **********/

export const GET_CHATS = "GET_CHATS"
export const GET_CHATS_OK = "GET_CHATS_OK"
export const GET_CHATS_ERR = "GET_CHATS_ERR"
export const DO_CHATS_CLEANUP = "DO_CHATS_CLEANUP"

/********** IN PROGRESS ORDERS **********/

export const GET_IN_PROGRESS_ORDERS = "GET_IN_PROGRESS_ORDERS"
export const GET_IN_PROGRESS_ORDERS_OK = "GET_IN_PROGRESS_ORDERS_OK"
export const GET_IN_PROGRESS_ORDERS_ERR = "GET_IN_PROGRESS_ORDERS_ERR"
export const DO_IN_PROGRESS_ORDERS_CLEANUP = "DO_IN_PROGRESS_ORDERS_CLEANUP"