import React from "react";
import { Card, CardBody, CardTitle, Button, Row, Col } from "reactstrap";
import notaryBidConfirmedIcon from "assets/images/notary-bid-confirmed-icon.svg";
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";

const NotaryBidCardConfirmed = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  return <Card className="notary-bid-submitted-card">
    <CardBody>
      <div className="mb-3">
        <img alt="confirmed lead" src={notaryBidConfirmedIcon} />
      </div>
      <CardTitle className="mav-card-title">Your confirmation was successfully submitted!</CardTitle>
      <div className="mav-card-subtitle mb-3">Our scheduling team is reviewing all candidates and will respond promptly!</div>
      <Row>
        <Col xs={6}>
          <Button className="w-100" color="secondary" onClick={() => navigate(route(routes.home))}>Dashboard <i className="bx bx-grid-alt ms-1" /></Button>
        </Col>
        <Col xs={6}>
          <Button className="w-100" color="primary" onClick={() => navigate(route(routes.list_notary_bids))}>Leads <i className="fas fa-long-arrow-alt-right ms-1" /></Button>
        </Col>
      </Row>
    </CardBody>
  </Card>
}

export default NotaryBidCardConfirmed;