import { combineReducers } from "redux";
import {
  GET_NOTARY_BID_LIST,
  GET_NOTARY_BID_LIST_OK,
  GET_NOTARY_BID_LIST_ERR,
  SET_NOTARY_BID_FILTER_TOGGLE,
  DO_NOTARY_BID_LIST_CLEANUP,

  GET_NOTARY_BID,
  GET_NOTARY_BID_OK,
  GET_NOTARY_BID_ERR,
} from "./actionTypes";
import NotaryBid from "model/notaryBid";

/********** LIST **********/

const defaultListState = {
  notaryBids: [],
  notaryBidsError: null,
  isLoadInProgress: false,
  filterToggle: NotaryBid.VIRTUAL_STATUS_ACTIVE,
  totalCount: 0,
  listParams: {
    sortBy: 'nb.id',
    sortDir: 'desc',
    pageSize: 9,
    page: 1,
    search: null,
    filters: null,
  },
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_NOTARY_BID_LIST:
      state = {
        ...state,
        notaryBids: [],
        totalCount: 0,
        listParams: action.payload.params,
        notaryBidsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_NOTARY_BID_LIST_OK:
      state = {
        ...state,
        notaryBids: action.payload.response.notaryBids,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_NOTARY_BID_LIST_ERR:
      state = {
        ...state,
        notaryBids: [],
        notaryBidsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case SET_NOTARY_BID_FILTER_TOGGLE:
      state = {
        ...defaultListState, // reset pagination params and rest of state
        filterToggle: action.payload.status,
      };
      break;
    case DO_NOTARY_BID_LIST_CLEANUP:
      state = {
        ...defaultListState,
        // persist UI toggles
        filterToggle: state.filterToggle,
      };
      break
  }
  return state;
};

/********** SINGLE **********/

const defaultSingleState = {
  notaryBid: null,
  notaryBidError: null,
  isLoadInProgress: false,
};

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    // load
    case GET_NOTARY_BID:
      state = {
        ...state,
        notaryBidError: null,
        isLoadInProgress: true,
      };
      break
    case GET_NOTARY_BID_OK:
      state = {
        ...state,
        notaryBid: action.payload.response.notaryBid,
        isLoadInProgress: false,
      };
      break
    case GET_NOTARY_BID_ERR:
      state = {
        ...state,
        notaryBid: null,
        notaryBidError: action.payload.error,
        isLoadInProgress: false,
      };
      break
  }
  return state;
}

export default combineReducers({
  List,
  Single,
});