export default class NotaryBid {

  static STATUS_SELECTED = 0;
  static STATUS_NOTIFIED = 1;
  static STATUS_DECLINED_BY_NOTARY = 2;
  static STATUS_ACCEPTED_BY_NOTARY = 3;
  static STATUS_REJECTED_BY_SCHEDULER = 4;
  static STATUS_AWARDED_BY_SCHEDULER = 5;
  static STATUS_NOT_AWARDED = 6;
  static STATUS_CLOSED_BY_AWARD = 7;

  static VIRTUAL_STATUS_ACTIVE = 1;
  static VIRTUAL_STATUS_ALL = 2;

  static AVAILABILITY_TODAY = 1
  static AVAILABILITY_TOMORROW = 2
  static AVAILABILITY_WEEKEND = 3

  static getStatusMap() {
    return {
      [this.STATUS_SELECTED]: 'Selected',
      [this.STATUS_NOTIFIED]: 'Notified',
      [this.STATUS_DECLINED_BY_NOTARY]: 'Declined by notary',
      [this.STATUS_ACCEPTED_BY_NOTARY]: 'Accepted by notary',
      [this.STATUS_REJECTED_BY_SCHEDULER]: 'Rejected by scheduler',
      [this.STATUS_AWARDED_BY_SCHEDULER]: 'Awarded by scheduler',
      [this.STATUS_NOT_AWARDED]: 'Not Awarded',
      [this.STATUS_CLOSED_BY_AWARD]: 'Expired',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }

  static getVirtualStatusMap() {
    return {
      [this.VIRTUAL_STATUS_ACTIVE]: 'Active',
      [this.VIRTUAL_STATUS_ALL]: 'All',
    };
  }

  static getVirtualStatusName(id) {
    return this.getVirtualStatusMap()[id];
  }

  static getAvailabilityMap() {
    return {
      [this.AVAILABILITY_TODAY]: 'Today',
      [this.AVAILABILITY_TOMORROW]: 'Tomorrow',
      [this.AVAILABILITY_WEEKEND]: 'Weekend',
    };
  }

  static getAvailabilityName(id) {
    return this.getAvailabilityMap()[id];
  }

  static getStatusColor(status) {
    switch (status) {
      case this.STATUS_OPEN:
        return 'warning';
      case this.STATUS_PAID:
        return 'success';
      default:
        return 'dark';
    }
  }
}