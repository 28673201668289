import React, { useState, useEffect } from "react";
import { useProfile } from "context/profile";
import CredentialDocs from "../Credential/Docs";
import CardPreloader from "components/Shared/CardPreloader";
import { Row, Col, Card, CardHeader, CardBody, CardTitle, Button } from "reactstrap";
import { perms, useAccess } from "context/access";
import FormCredential from "../Form/Credential";
import FormContentCredential from "../Form/Content/Credential";

const SectionCredentials = () => {

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const { isLoadInProgress, refreshProfile } = useProfile();

  // toggle add form on/off
  const [addFormIsOpen, setAddFormIsOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadInProgress) {
      setIsRefreshing(false);
    }
  }, [isLoadInProgress]);

  // toggles the add form on and off
  const toggleAddForm = doRefresh => {
    setAddFormIsOpen(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful upload)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshProfile();
    }
  }

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Credentials</CardTitle>
        </Col>
        {iAmGranted(perms.edit_notaries) && <Col xs="auto">
          <div className="text-end">
            {!!addFormIsOpen && <Button type="button" color="secondary" className="me-2" onClick={toggleAddForm} disabled={isRefreshing}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>}
            {!addFormIsOpen && <Button type="button" color="primary" className="mb-2" onClick={toggleAddForm} disabled={isRefreshing}>
              <i className="mdi mdi-plus me-1" />Add
            </Button>}
          </div>
        </Col>}
      </Row>
    </CardHeader>
    <CardBody>
      {addFormIsOpen && <FormCredential>
        <FormContentCredential />
      </FormCredential>}
      <Row>
        <Col>
          <CredentialDocs />
        </Col>
      </Row>
    </CardBody>
    {isRefreshing && <CardPreloader />}
  </Card>)
}

export default SectionCredentials;