import React from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import SectionBilling from "../Partial/Section/Billing";
import SectionShipping from "../Partial/Section/Shipping";
import SectionContact from "../Partial/Section/Contact";
import SectionExperience from "../Partial/Section/Experience";
import SectionCredentials from "../Partial/Section/Credentials";
import SectionCredentialsDetails from "../Partial/Section/CredentialsDetails";
import SectionAccounting from "../Partial/Section/Accounting";
import { useProfile } from "context/profile";

const ViewIndex = () => {

  const { notary } = useProfile();

  return <div className="page-content">
    {notary && <>
      <MetaTitle>My Profile</MetaTitle>
      <Container fluid className='notary-profile'>
        <Breadcrumbs breadcrumbItems={breadcrumbs} />
        <Row>
          <Col xs={12} md={6}>
            <SectionBilling />
          </Col>
          <Col xs={12} md={6}>
            <SectionShipping />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <SectionContact />
          </Col>
          <Col xs={12} md={6}>
            <SectionExperience />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <SectionCredentials />
          </Col>
          <Col xs={12} md={4}>
            <SectionCredentialsDetails />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <SectionAccounting />
          </Col>
        </Row>
      </Container>
    </>}
  </div>
}

const breadcrumbs = [{
  title: "NOTARY PROFILE",
}, {
  title: "Edit profile",
}];

export default ViewIndex;