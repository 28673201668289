/********** LIST **********/

export const GET_NOTARY_BID_LIST = "GET_NOTARY_BID_LIST";
export const GET_NOTARY_BID_LIST_OK = "GET_NOTARY_BID_LIST_OK";
export const GET_NOTARY_BID_LIST_ERR = "GET_NOTARY_BID_LIST_ERR";
export const SET_NOTARY_BID_FILTER_TOGGLE = "SET_NOTARY_BID_FILTER_TOGGLE";
export const DO_NOTARY_BID_LIST_CLEANUP = "DO_NOTARY_BID_LIST_CLEANUP";

/********** SINGLE **********/

export const GET_NOTARY_BID = "GET_NOTARY_BID"
export const GET_NOTARY_BID_OK = "GET_NOTARY_BID_OK"
export const GET_NOTARY_BID_ERR = "GET_NOTARY_BID_ERR"