export class BeException extends Error {

  constructor(message, code, prev) {
    super(message);
    if (Error.captureStackTrace != undefined) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class AuthException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Access denied', code, prev);
  }
}

export class AccessDeniedException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Access denied', code, prev);
  }
}

export class NotFoundException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Resource not found', code, prev);
  }
}

export class BadRequestException extends BeException {

  constructor(message, code, fields, prev) {
    super(message || 'Bad request', code, prev);
  }
}

export class ValidationException extends BadRequestException {

  constructor(message, fields) {
    super(message);
    this.fields = fields;
  }
}

export class ServerErrorException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Internal server error', code, prev);
  }
}

export class LocalException extends Error {

  constructor(message, code, prev) {
    super(message);
    if (Error.captureStackTrace != undefined) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class NetworkErrorException extends LocalException {

  constructor(message, code, prev) {
    super(message || 'Network error', code, prev);
  }
}

export const ACC_TOKEN_MISSING = 1000;
export const ACC_TOKEN_JWT_EXPIRED = 1002;
export const UPLOADED_FILE_TYPE_NOT_ALLOWED = 1264;
export const UNABLE_INSERT_DOC_DUE_TO_UNIQUE_KEY = 1290;