import React from "react";
import { Card, CardBody, CardTitle, Button, Row, Col } from "reactstrap";
import notaryBidDeclinedIcon from "assets/images/notary-bid-declined-icon.svg";
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";

const NotaryBidCardDeclined = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  return <Card className="notary-bid-submitted-card">
    <CardBody>
      <div className="mb-3">
        <img alt="declined lead" src={notaryBidDeclinedIcon} />
      </div>
      <CardTitle className="mav-card-title">This lead was declined!</CardTitle>
      <div className="mav-card-subtitle mb-3">See you next time for other leads!</div>
      <Row>
        <Col xs={6}>
          <Button className="w-100" color="secondary" onClick={() => navigate(route(routes.home))}>Dashboard <i className="bx bx-grid-alt ms-1" /></Button>
        </Col>
        <Col xs={6}>
          <Button className="w-100" color="primary" onClick={() => navigate(route(routes.list_notary_bids))}>Leads <i className="fas fa-long-arrow-alt-right ms-1" /></Button>
        </Col>
      </Row>
    </CardBody>
  </Card>
}

export default NotaryBidCardDeclined;