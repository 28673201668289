import React from "react";
import { Button } from "reactstrap";
import returnAllDocsIcon from "assets/images/return-all-docs-icon.svg";
import PropTypes from "prop-types";
import InfoMessage from "components/Shared/InfoMessage";
import OrderNotary from "model/orderNotary";

const StepReturnToDealershipMobile = ({ inkSignDocs, isCurrentStep, onHandleAllShipDocs, onHandleShipDoc, onGetDocsStatus }) => {

  return (<React.Fragment>
    <div className="return-docs-card-head mb-3">
      <InfoMessage description={OrderNotary.MESSAGE_RETURN_TO_DEALERSHIP_MESSAGE_ONE} />
      <InfoMessage description={OrderNotary.MESSAGE_RETURN_TO_DEALERSHIP_MESSAGE_TWO} />

      <div className="d-flex align-items-center justify-content-center">
        <i className="bx bx-file font-size-20" />
        <h6 className="mb-0 ps-2">Contract information</h6>
      </div>
    </div>

    {inkSignDocs?.map((item, index) => <div key={index} className="return-docs-card mb-3">
      <div className="return-docs-card-header d-flex">
        <div className="col-2">#</div>
        <div className="col-6">Name</div>
        <div className="col-4">Status</div>
      </div>
      <div className="return-docs-card-body d-flex mt-2 mb-3">
        <div className="col-2">{index + 1}</div>
        <div className="col-6">{item.name}</div>
        <div className="col-4"><span className="badge rounded-pill bg-success">{onGetDocsStatus(item.returnedByNotaryTs)}</span></div>
      </div>
      {!item.returnedByNotaryTs && <Button color="primary" className='mt-2 mb-2 w-100' onClick={() => onHandleShipDoc(item.id)} disabled={!isCurrentStep}>Document Shipped</Button>}
    </div>)}

    <div className="d-flex justify-content-end mt-2 mb-2">
      {inkSignDocs?.filter((doc) => !doc.returnedByNotaryTs).length > 0 &&
        <Button className="w-100" color="primary" onClick={() => onHandleAllShipDocs(inkSignDocs.id)} disabled={!isCurrentStep}>
          <img src={returnAllDocsIcon} className="all-docs-shipped-icon" />
          ALL DOCS SHIPPED
        </Button>
      }
    </div>
  </React.Fragment>
  )
}

StepReturnToDealershipMobile.propTypes = {
  inkSignDocs: PropTypes.array,
  isCurrentStep: PropTypes.bool,
  onHandleAllShipDocs: PropTypes.func,
  onHandleShipDoc: PropTypes.func,
  onGetDocsStatus: PropTypes.func,
};

export default StepReturnToDealershipMobile;