import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";

const BackButton = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => navigate(-1);

  // display the back button only if the user has been redirected from another page of the app
  return location.state?.isRedirect && (
    <Row>
      <Col xs="12">
        <div onClick={goBack} className="page-title-box d-flex align-items-center justify-content-between cursor-pointer">
          <h4 className="mb-0 font-size-18">{`< Back`}</h4>
        </div>
      </Col>
    </Row>
  )
}

export default BackButton;
