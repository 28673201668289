/********** LIST **********/

export const GET_ORDER_LIST = "GET_ORDER_LIST"
export const GET_IN_PROGRESS_ORDER_LIST = "GET_IN_PROGRESS_ORDER_LIST"
export const GET_ORDER_LIST_OK = "GET_ORDER_LIST_OK"
export const GET_ORDER_LIST_ERR = "GET_ORDER_LIST_ERR"
export const DO_ORDER_LIST_CLEANUP = "DO_ORDER_LIST_CLEANUP"
export const SET_ORDER_FILTER_TOGGLE = "SET_ORDER_FILTER_TOGGLE"
export const SET_VIEW_MODE_TOGGLE = "SET_VIEW_MODE_TOGGLE"
export const SET_NOTARY_ORDER_FILTER_TOGGLE = "SET_NOTARY_ORDER_FILTER_TOGGLE"

/********** SINGLE **********/

export const GET_ORDER = "GET_ORDER"
export const GET_ORDER_OK = "GET_ORDER_OK"
export const GET_ORDER_ERR = "GET_ORDER_ERR"
export const DO_ORDER_SINGLE_CLEANUP = "DO_ORDER_SINGLE_CLEANUP"

/********** MESSAGES **********/

export const GET_ORDER_MESSAGES = "GET_ORDER_MESSAGES"
export const GET_ORDER_MESSAGES_OK = "GET_ORDER_MESSAGES_OK"
export const GET_ORDER_MESSAGES_ERR = "GET_ORDER_MESSAGES_ERR"
export const GET_LAST_ORDER_MESSAGE = "GET_LAST_ORDER_MESSAGE"
export const GET_LAST_ORDER_MESSAGE_OK = "GET_LAST_ORDER_MESSAGE_OK"
export const GET_LAST_ORDER_MESSAGE_ERR = "GET_LAST_ORDER_MESSAGE_ERR"
export const GET_CHAT_MEMBERS = "GET_CHAT_MEMBERS"
export const GET_CHAT_MEMBERS_OK = "GET_CHAT_MEMBERS_OK"
export const GET_CHAT_MEMBERS_ERR = "GET_CHAT_MEMBERS_ERR"

/********** INK-SIGN DOCS **********/

export const GET_ORDER_INK_SIGN_DOCS = 'GET_ORDER_INK_SIGN_DOCS'
export const GET_ORDER_INK_SIGN_DOCS_OK = 'GET_ORDER_INK_SIGN_DOCS_OK'
export const GET_ORDER_INK_SIGN_DOCS_ERR = 'GET_ORDER_INK_SIGN_DOCS_ERR'
export const DO_ORDER_INK_SIGN_DOCS_CLEANUP = 'DO_ORDER_INK_SIGN_DOCS_CLEANUP'