import {
  GET_CREDENTIAL_LIST,
  GET_CREDENTIAL_LIST_OK,
  GET_CREDENTIAL_LIST_ERR,
  GET_CREDENTIAL_LIST_ITEM,
  GET_CREDENTIAL_LIST_ITEM_OK,
  GET_CREDENTIAL_LIST_ITEM_ERR,
  DELETE_CREDENTIAL_LIST_ITEM,
  DELETE_CREDENTIAL_LIST_ITEM_OK,
  DELETE_CREDENTIAL_LIST_ITEM_ERR,
  DO_CREDENTIAL_LIST_CLEANUP,

  UPLOAD_CREDENTIAL,
  UPLOAD_CREDENTIAL_OK,
  UPLOAD_CREDENTIAL_ERR,
  DO_CREDENTIAL_FORM_CLEANUP,
} from "./actionTypes";

/********** LIST **********/

export const getCredentialList = id => ({
  type: GET_CREDENTIAL_LIST,
  payload: { id },
});

export const getCredentialListOk = response => ({
  type: GET_CREDENTIAL_LIST_OK,
  payload: { response },
});

export const getCredentialListErr = error => ({
  type: GET_CREDENTIAL_LIST_ERR,
  payload: { error },
});

export const getCredentialListItem = id => ({
  type: GET_CREDENTIAL_LIST_ITEM,
  payload: { id },
});

export const getCredentialListItemOk = (id, response) => ({
  type: GET_CREDENTIAL_LIST_ITEM_OK,
  payload: { id, response },
});

export const getCredentialListItemErr = (id, error) => ({
  type: GET_CREDENTIAL_LIST_ITEM_ERR,
  payload: { id, error },
});


export const deleteCredentialListItem = id => ({
  type: DELETE_CREDENTIAL_LIST_ITEM,
  payload: { id },
});

export const deleteCredentialListItemOk = (id, response) => ({
  type: DELETE_CREDENTIAL_LIST_ITEM_OK,
  payload: { id, response },
});

export const deleteCredentialListItemErr = (id, error) => ({
  type: DELETE_CREDENTIAL_LIST_ITEM_ERR,
  payload: { id, error },
});

export const doCredentialListCleanup = () => ({
  type: DO_CREDENTIAL_LIST_CLEANUP,
});

/********** FORM **********/

export const uploadCredential = data => ({
  type: UPLOAD_CREDENTIAL,
  payload: { data },
});

export const uploadCredentialOk = response => ({
  type: UPLOAD_CREDENTIAL_OK,
  payload: { response },
});

export const uploadCredentialErr = error => ({
  type: UPLOAD_CREDENTIAL_ERR,
  payload: { error },
});

export const doCredentialFormCleanup = () => ({
  type: DO_CREDENTIAL_FORM_CLEANUP,
});
