import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Label, Input, FormFeedback } from "reactstrap";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import Select from "react-select";
import UsStates from "constants/usState";
import { useFormikContext } from "formik";
import { getAddressComponents, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "context/profile";
import { resetSaveProfileActionFlag } from "store/actions";
import { isNil } from "lodash";
import classnames from "classnames";

const FormContentBilling = ({ isSetupRoute }) => {

  const dispatch = useDispatch();
  const { refreshProfile } = useProfile();

  // consume formik instance from context
  const formik = useFormikContext();

  // saved - TRUE if a save was attempted and was successful, FALSE if it failed, NULL if no save was attempted yet
  const { saved } = useSelector(state => state.Profile.Form);
  // flags if the user opted to use the billing address for delivery or not
  const [isSameAsDeliveryAddress, setIsSameAsDeliveryAddress] = useState(false);

  /********** EFFECTS **********/

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess("Billing info saved");
      refreshProfile();
    } else if (saved === false) {
      showError("Unable to save billing info");
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag("saved"));
    }
  }, [saved]);

  // runs once on component mount
  // to check if we already have a value for the checkbox stored locally
  useEffect(() => {
    // if we are not in the profile setup (and we are in the profile edit), skip this
    if (!isSetupRoute) return;
    // otherwise, look for the value in the local storage
    const isSameAddress = JSON.parse(localStorage.getItem("isSameAddress"));
    if (!isNil(isSameAddress)) {
      setIsSameAsDeliveryAddress(isSameAddress);
    }
  }, []);

  // runs whenever the `isSameAsDeliveryAddress` flag changes
  // to sync the value in the local storage to the one from the checkbox
  useEffect(() => {
    // if we are not in the profile setup (and we are in the profile edit), skip this
    if (!isSetupRoute) return;
    // otherwise, save the value in the local storage
    // so it persists at refresh/navigation
    localStorage.setItem("isSameAddress", isSameAsDeliveryAddress);
  }, [isSameAsDeliveryAddress]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  const handleCheckIsSameAddress = event => {
    setIsSameAsDeliveryAddress(event.target.checked);
  };

  const fillInAddress = place => {
    // parse the `place` object we receive from Google Places Autocomplete
    const addressComponents = getAddressComponents(place);
    // fill in the form with the correlated address components
    formik.setFieldValue("billingZip", addressComponents.zip);
    formik.setFieldValue("billingCity", addressComponents.city);
    formik.setFieldValue("billingState", addressComponents.state);
    formik.setFieldValue("billingAddress", addressComponents.address);
    // save the coordinates of the place, even if they don't have any impact on the bidding process
    // this is because the user may opt to use the same address for shipping
    // in that case, we need to copy the billing address info to shipping, and it's important to have the coordinates as well
    formik.setFieldValue("billingLatitude", place.geometry.location.lat());
    formik.setFieldValue("billingLongitude", place.geometry.location.lng());
  };

  return <Form autoComplete="force-disable-autofill">
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Billing Address*</Label>
      <Col sm={8}>
        <PlacesAutocomplete
          type="text"
          className={classnames("form-control", { "is-invalid": !!(formik.errors.billingAddress || formik.errors.billingLatitude || formik.errors.billingLongitude) })}
          placeholder="ex. 2273 Muldrow Dr"
          name="billingAddress"
          onChange={formik.handleChange}
          onPlaceChanged={fillInAddress}
          onFocus={(e, fieldName) => { onFieldFocused(e, fieldName); onFieldFocused(e, "billingLatitude"); onFieldFocused(e, "billingLongitude"); }}
          value={formik.values.billingAddress}
        />
        {!!formik.errors.billingAddress && <FormFeedback type="invalid">{formik.errors.billingAddress}</FormFeedback>}
        {!!(formik.errors.billingLatitude || formik.errors.billingLongitude) && <FormFeedback type="invalid">Please select a valid address</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <div>
        <Input
          type="checkbox"
          id="billingDeliveryChanged"
          name="billingDeliveryChanged"
          className="form-check-input-lg me-2"
          value={formik.values.billingDeliveryChanged}
          defaultChecked={formik.values.billingDeliveryChanged}
          onChange={formik.handleChange}
        />
        <Label htmlFor="billingDeliveryChanged">Update billing delivery address as well</Label>
      </div>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Billing Zip*</Label>
      <Col sm={8}>
        <Input
          type="text" className="form-control"
          placeholder="ex. 90017"
          autoComplete="force-disable-autofill"
          onChange={event => formik.setFieldValue("billingZip", event.target.value)}
          onFocus={onFieldFocused}
          value={formik.values.billingZip}
          invalid={!!formik.errors.billingZip}
        />
        {!!formik.errors.billingZip && <FormFeedback type="invalid">{formik.errors.billingZip}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Billing City*</Label>
      <Col sm={8}>
        <Input
          type="text"
          className="form-control"
          placeholder="ex. New Jersey"
          autoComplete="force-disable-autofill"
          onChange={event => formik.setFieldValue("billingCity", event.target.value)}
          onFocus={onFieldFocused}
          value={formik.values.billingCity}
          invalid={!!formik.errors.billingCity}
        />
        {!!formik.errors.billingCity && <FormFeedback type="invalid">{formik.errors.billingCity}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Label className="col-sm-4 col-form-label">Billing State*</Label>
      <Col sm={8}>
        <Select
          classNamePrefix="select2-selection"
          name="billingState"
          autoComplete="force-disable-autofill"
          options={usStates}
          onChange={selected => formik.setFieldValue("billingState", selected.value)}
          onFocus={e => onFieldFocused(e, "billingState")}
          value={usStates.find(option => option.value === formik.values.billingState)}
          className={!!formik.errors.billingState && "is-invalid"} />
        {!!formik.errors.billingState && <FormFeedback type="invalid">{formik.errors.billingState}</FormFeedback>}
      </Col>
    </Row>

    {isSetupRoute && (
      <Row className="mb-4">
        <div>
          <Input
            type="checkbox"
            id="same-address-checkbox"
            className="form-check-input-lg me-2"
            checked={isSameAsDeliveryAddress}
            onChange={handleCheckIsSameAddress}
          />
          <Label htmlFor="same-address-checkbox">Use Billing Address for Delivery</Label>
        </div>
      </Row>
    )}
  </Form>
}

FormContentBilling.propTypes = {
  isSetupRoute: PropTypes.bool,
}

const usStates = toSelectOptions(UsStates);

export default FormContentBilling;