import { Card, CardBody, CardTitle } from "reactstrap";
import React from "react";
import { getBeUrl } from "../../../helpers/utilHelper";

const Documents = () => {
  return (
    <Card className="documents-card">
      <CardTitle>Documents</CardTitle>
      <CardBody className="p-0">
        <div className="text-start">
          <a href={getBeUrl(`notary/std-doc/aoi.pdf`)} target="_blank" rel="noreferrer" className="btn btn-light mav-card-action-btn download-link">
            <div className="d-flex justify-content-between align-items-center">
              <span>Download AOI</span>
              <i className="bx bx-download" />
            </div>
          </a>
        </div>
        <div className="text-start">
          <a href={getBeUrl(`notary/std-doc/aoi_no_fingerprint.pdf`)} target="_blank" rel="noreferrer" className="btn btn-light mav-card-action-btn download-link">
            <div className="d-flex justify-content-between align-items-center">
              <span>Download AOI - No Fingerprint</span>
              <i className="ms-1 bx bx-download" />
            </div>
          </a>
        </div>
        <div className="text-start">
          <a href={getBeUrl(`notary/std-doc/notary-checklist.pdf`)} target="_blank" rel="noreferrer" className="btn btn-light mav-card-action-btn download-link">
            <div className="d-flex justify-content-between align-items-center">
              <span>Download Notary Checklist</span>
              <i className="bx bx-download" />
            </div>
          </a>
        </div>
      </CardBody>
    </Card>
  )
}

export default Documents;